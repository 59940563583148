import type { Pinia } from 'pinia';
import type { Router } from 'vue-router';

import { wnextHTTP } from '@clovyr/pollen/http';
import { exec } from '@clovyr/pollen/remote_exec';

import { usePayments } from '@/composables/usePayments';
import { useBillingStore } from '@/stores/billing_store';
import { usePollenStore } from '@/stores/pollen_store';

import { config } from './config';

export function initDebug(pinia: Pinia, router: Router) {
  if (!import.meta.env.SSR && typeof window !== 'undefined') {
    const pollenStore = usePollenStore(pinia);
    const billingStore = useBillingStore(pinia);
    const payments = usePayments();

    window.clovyr = Object.assign({}, window.clovyr, {
      debug: {
        pinia,
        pollenStore,
        billingStore,
        payments,
        usePayments,
        router,
        config,

        /**
         * Execute a command on a remote app server
         *
         * @param appID Deployment ID
         * @param cmd Bash script to run
         * @returns
         */
        async exec(appID: string, cmd: string): Promise<void> {
          const dep = pollenStore.getDeploymentByID(appID);
          if (!dep) {
            console.warn("Couldn't find deployment with ID", appID);
            return undefined;
          }
          const inst = pollenStore.getInstanceByFQDN(dep.fqdn!);
          if (!inst) {
            console.warn('Instance not found for fqdn?');
          }

          console.log('> executing command on instance at', dep.fqdn!, inst?.ipv4 || '');

          let key: string | undefined = dep.accessList?.[0];
          if (!key) {
            key = inst?.configurekey;
          }
          if (!key) {
            console.warn('No key found for deployment');
            return undefined;
          }

          const res = await exec(dep.fqdn!, key, cmd, true, true);
          console.log(await res.text());
          return undefined;
        },

        /**
         * Expose API client for making arbitrary calls for testing
         */
        http: wnextHTTP,
      },
    });

    window.toRaw = <T>(obj: T): T | T[] => {
      if (!obj) {
        return obj;
      }
      if (Array.isArray(obj)) {
        return obj.map<T>(toRaw);
      }
      return toRaw(obj);
    };
  }
}

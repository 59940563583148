import { randomBytes } from 'tweetnacl';
import * as v from 'valibot';

import { APIError } from '../../api/APIError';
import { base64ToBytes, bytesToBase64 } from '../../util/base64';
import { type Nostr } from '../nostr';

import { loadAnonymousItems, stateAnonymous } from './state/anon';
import { baseState } from './state/base';
import { stateLocked } from './state/locked';
import { statePasswordNoSecretKey } from './state/no_secret';
import { stateNostr } from './state/nostr';
import { stateOpenRemote } from './state/remote';
import type { StateFunc, VaultEnv, VaultState } from './state/types';
import { KeychainJSONSchema, migrateLocalStorage, type StorageMigrator } from './migrations';
import {
  type LockedKeychain,
  type NoSecretKeyPasswordKeychain,
  type NostrKeychain,
  type OpenPasswordKeychain,
  type UserAPI,
  type Vault,
  type VaultAPI,
  type VaultItem,
  type WebStorage,
} from './types';
import { xorKeys } from './util';
import { getVaultItems, migrateMonolithicState } from '.';

function openAnonymousKey(storage: WebStorage): Uint8Array {
  const storedKey = storage.getItem('clovyr/anonymous-key');
  if (storedKey) {
    return base64ToBytes(storedKey);
  }

  const key = randomBytes(32);
  storage.setItem('clovyr/anonymous-key', bytesToBase64(key));
  return key;
}

export function newVault(
  storage: WebStorage,
  userAPI: UserAPI,
  vaultAPI: VaultAPI,
  nostr: Nostr,
  migrator: StorageMigrator
): Vault {
  const env: VaultEnv = {
    anonymousKey: new Uint8Array(0),
    storage,
    userAPI,
    vaultAPI,
    nostr,
    migrator,
  };

  let state: VaultState = {
    ...baseState('uninitialized'),

    async init() {
      migrateLocalStorage(storage);

      const keychainJSON = storage.getItem('clovyr/keychain');
      if (!keychainJSON) {
        env.anonymousKey = openAnonymousKey(storage);
        const items = loadAnonymousItems(storage, env.anonymousKey, migrator);
        return [stateAnonymous(), items];
      }

      return openKeychain(keychainJSON, env);
    },

    authType() {
      throw new Error('uninitialized');
    },

    authState() {
      throw new Error('uninitialized');
    },
  };

  return {
    async init() {
      const [stateFn, items] = await state.init();
      state = stateFn(env);
      return items;
    },

    async signUp(email, password, items) {
      const stateFn = await state.signUp(email, password, items);
      state = stateFn(env);
    },

    async signUpWithNostr(email: string, items) {
      const stateFn = await state.signUpWithNostr(email, items);
      state = stateFn(env);
    },

    async logIn(email, password, secretKey) {
      const [stateFn, items] = await state.logIn(email, password, secretKey);
      state = stateFn(env);
      return items;
    },

    async logInWithNostr() {
      const [stateFn, items] = await state.logInWithNostr();
      state = stateFn(env);
      return items;
    },

    async lock() {
      const stateFn = await state.lock(stateLocked);
      state = stateFn(env);
    },

    async unlock(password) {
      const [stateFn, items] = await state.unlock(password, stateOpenRemote);
      state = stateFn(env);
      return items;
    },

    async addSecretKey(secretKey) {
      const [stateFn, items] = await state.addSecretKey(secretKey);
      state = stateFn(env);
      return items;
    },

    async setKeychain(keychainJSON) {
      const [stateFn, items] = await openKeychain(keychainJSON, env);
      state = stateFn(env);
      return items;
    },

    async putItem(item) {
      await state.putItem(item);
    },

    async deleteItem(itemID) {
      await state.deleteItem(itemID);
    },

    async shareItem(itemID, extraParams) {
      return state.shareItem(itemID, extraParams);
    },

    async acceptShare(itemID, shareKey) {
      return state.acceptShare(itemID, shareKey);
    },

    async items() {
      return state.items();
    },

    secretKey() {
      return state.secretKey();
    },

    email() {
      return state.email();
    },

    authType() {
      return state.authType();
    },

    authState() {
      return state.authState();
    },
  };
}

async function openKeychain(
  keychainJSON: string,
  env: VaultEnv
): Promise<[StateFunc, VaultItem[]]> {
  const { userAPI, vaultAPI, migrator } = env;

  const keychainObj = v.parse(KeychainJSONSchema, JSON.parse(keychainJSON));
  if (keychainObj.state === 'passwordOpen') {
    const keychain: OpenPasswordKeychain = {
      state: 'passwordOpen',
      email: keychainObj.email,
      passwordKey: base64ToBytes(keychainObj.passwordKey),
      secretKey: base64ToBytes(keychainObj.secretKey),
    };
    const auk = xorKeys(keychain.passwordKey, keychain.secretKey);

    try {
      const readStateResponse = await userAPI.readState(keychain.email);
      if (readStateResponse.encryptedState) {
        const [items, itemKeys] = await migrateMonolithicState(
          readStateResponse.encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
        return [stateOpenRemote(keychain, itemKeys), items];
      }

      const [items, itemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      return [stateOpenRemote(keychain, itemKeys), items];
    } catch (e) {
      // either userAPI.readState or vaultAPI.listItems can throw from a 403
      // this will happen if the cookie suddenly expired or for some reason did not match (i.e.,
      // represented a different user than the one we know of in our keychain)
      if (e instanceof APIError && e.code === 403) {
        // throw new Error('not authorized');
        // return locked vault
        const newKeychain: LockedKeychain = {
          state: 'passwordLocked',
          secretKey: keychain.secretKey,
          email: keychain.email,
        };
        return [stateLocked(newKeychain), []];
      }
    }
  }
  if (keychainObj.state === 'nostr') {
    const keychain: NostrKeychain = {
      state: 'nostr',
      auk: base64ToBytes(keychainObj.auk),
      pubkey: keychainObj.pubkey,
      email: keychainObj.email,
    };

    // TODO: migrate from local storage

    const [items, itemKeys] = await getVaultItems(vaultAPI, keychain.auk, keychain.email);
    return [stateNostr(keychain, itemKeys), items];
  }
  if (keychainObj.state === 'passwordNoSecretKey') {
    const keychain: NoSecretKeyPasswordKeychain = {
      state: 'passwordNoSecretKey',
      email: keychainObj.email,
      passwordKey: base64ToBytes(keychainObj.passwordKey),
    };
    return [statePasswordNoSecretKey(keychain), []];
  }
  if (keychainObj.state === 'passwordLocked') {
    const keychain: LockedKeychain = {
      state: 'passwordLocked',
      email: keychainObj.email,
      secretKey: base64ToBytes(keychainObj.secretKey),
    };
    return [stateLocked(keychain), []];
  }
  throw new Error(
    `unreachable: unknown keychain state: ${keychainObj?.state}, version=${keychainObj?.version}`
  );
}

import { sleep } from './sleep';

/**
 * Retries a function a number of times before throwing an error
 *
 * @param fn
 * @param retries
 * @returns Value returned by the original function (fn)
 */
/* eslint-disable no-await-in-loop */
export async function retry<T>(fn: () => Promise<T>, retries = 3, backoff = false): Promise<T> {
  let last = 0;
  let next = 100;
  let lastErr;
  while (retries > 0) {
    try {
      return await fn();
    } catch (e) {
      lastErr = e;
      retries--;
      if (retries === 0) {
        throw e;
      }
      if (backoff) {
        await sleep(next);
        const temp = next;
        next += last;
        last = temp;
      }
    }
  }
  throw lastErr || new Error('operation failed'); // should not reach here
}
/* eslint-enable no-await-in-loop */

<script setup lang="ts">
import BaseSvg from '@clovyr/bed/components/icons/BaseSvg.vue';

type ArrowDirection = 'top' | 'right' | 'down' | 'left';

type IconProps = {
  direction?: ArrowDirection;
  stroke?: string;
};

const props = withDefaults(defineProps<IconProps>(), {
  direction: 'top',
  stroke: 'currentColor',
});

const arrowClassNames = computed(() => {
  return [props.direction && `icon-arrow-${props.direction}`];
});
</script>

<template>
  <BaseSvg
    :class="arrowClassNames"
    class="icon icon-arrow"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      :stroke="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="1.48"
      d="M13.5 10.83 8.97 6.3l-4.52 4.53"
    />
  </BaseSvg>
</template>

<style scoped lang="scss">
.icon {
  &.icon-arrow {
    width: 1.125rem;
    height: 1.125rem;

    &.icon-arrow-down {
      transform: rotate(180deg);
    }

    &.icon-arrow-left {
      transform: rotate(90deg);
    }

    &.icon-arrow-right {
      transform: rotate(270deg);
    }
  }
}
</style>

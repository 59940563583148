<script setup lang="ts">
import { storeToRefs } from 'pinia';

import type { Option } from '@clovyr/bed';
import SelectInputBlock from '@clovyr/bed/components/blocks/SelectInputBlock.vue';
import TextInputBlock from '@clovyr/bed/components/blocks/TextInputBlock.vue';

import Question from '@/components/forms/Question.vue';
import {
  findLaunchSetting,
  type LaunchConfigSettingId,
  validateAnswer,
} from '@/stores/launch_settings';
import { useLauncherStore } from '@/stores/launcher_store';

import CheckboxBlock from '../blocks/CheckboxBlock.vue';

// large component chain, make async
const EditorField = defineAsyncComponent(() => import('./EditorField.vue'));

type Props = {
  questionID: LaunchConfigSettingId;
  parentID?: LaunchConfigSettingId;
  disabled?: boolean;
};

const props = defineProps<Props>();

const launcherStore = useLauncherStore();
const { setAnswer } = launcherStore;
const { answers } = storeToRefs(launcherStore);

const question = computed(() => findLaunchSetting(props.questionID)!);
const parentQuestion = computed(() =>
  props.parentID ? findLaunchSetting(props.parentID)! : undefined,
);

const val = computed({
  get: () => question.value.answer as string,
  set: (v) => {
    setAnswer(question.value.id, v || null);
  },
});

const answer = computed(() => {
  const a = answers.value[question.value.id];
  if (a) {
    return a;
  }
  return undefined;
});

const answerValue = computed(() => answer.value?.value);

const errorMessage = computed(() => {
  if (answer.value?.errors) {
    const msg = answer.value.errors.map((e) => e.message).join(', ');
    if (msg === 'must be string') {
      return 'field is required';
    }
    return msg;
  }
  return undefined;
});

const selectOptions = computed<Option[]>(() => {
  if (question.value.type === 'enum' && question.value.options) {
    return question.value.options.map((o) => ({ name: o, value: o }));
  }
  return [];
});

const showQuestion = computed(() => {
  if (!question.value.condition) {
    return true;
  }
  const e = validateAnswer(question.value, parentQuestion.value?.answer, question.value.condition);
  // show if no errors
  return !e;
});

const fieldName = computed(() => question.value.id.replaceAll('.', '_'));

const isFile = computed(
  () =>
    question.value.schema.maxLength === 999999 &&
    (question.value.id.endsWith('_FILE') || question.value.id.endsWith('_FILE_HEX')),
);

const onDropFile = async (file: File, t: string) => {
  if (question.value.id.endsWith('_FILE')) {
    setAnswer(question.value.id, t);
  } else if (question.value.id.endsWith('_FILE_HEX')) {
    // hex encode contents of file
    const buffArray = new Uint8Array(await file.arrayBuffer());
    const hex = Array.from(buffArray, (byte) => byte.toString(16).padStart(2, '0')).join('');
    setAnswer(question.value.id, hex);
  }
};
</script>

<template>
  <div v-if="showQuestion" :style="{ marginBottom: '15px' }">
    <template v-if="question.type === 'string'">
      <EditorField
        v-if="isFile"
        :name="fieldName"
        :label="question.display_name"
        :errorMessage="errorMessage"
        :tooltip="question.display_description"
        class="textarea-block__input"
        gutter="off"
        :readonly="disabled"
        :allow-drop="!disabled"
        :allowed-file-types="[]"
        v-model="val"
        @drop="onDropFile"
      />

      <TextInputBlock
        v-else
        type="text"
        class="formfield"
        :errorMessage="errorMessage"
        :label="question.display_name"
        :subtext="question.display_description"
        :id="question.id"
        shape="oval"
        :value="answerValue as string"
        :disabled="disabled"
        @input="
          (e: InputEvent) => setAnswer(question.id, (e.target as HTMLInputElement).value.trim())
        "
      />
    </template>

    <CheckboxBlock
      v-else-if="question.type === 'boolean'"
      type="checkbox"
      shape="rounded"
      :title="question.display_name"
      :description="question.display_description"
      :id="question.id"
      :value="answerValue"
      :disabled="disabled"
      @input="(e: InputEvent) => setAnswer(question.id, (e.target as HTMLInputElement).checked)"
    />

    <SelectInputBlock
      v-else-if="question.type === 'enum'"
      :select-options="selectOptions"
      :label="question.display_name"
      :subtext="question.display_description"
      :id="question.id"
      shape="rounded"
      :value="answerValue as string"
      :disabled="disabled"
      @input="(e: InputEvent) => setAnswer(question.id, (e.target as HTMLSelectElement).value)"
    />

    <template v-if="question.subs">
      <Question
        v-for="(sub, id) in question.subs"
        :key="id"
        :questionID="sub.id"
        :parentID="questionID"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { IconPosition } from '@clovyr/bed/components/controls/types';

import Question from '@/components/forms/Question.vue';
import CloseIcon from '@/components/icons/CloseIcon.vue';
import type { AnswerFn, LaunchConfigSetting } from '@/stores/launch_settings';
import { useLauncherStore } from '@/stores/launcher_store';

type Props = {
  untoggledButtonText: string;
  toggledButtonText: string;
  saveButton?: boolean;
  reviewConfigurations?: boolean;
  showDeployConfig?: boolean;
};
const props = defineProps<Props>();

const { toggledButtonText, untoggledButtonText, saveButton, reviewConfigurations } = toRefs(props);

const launcherStore = useLauncherStore();
const { basicSettings, advancedSettings, deploySettings } = toRefs(launcherStore);

const isToggled = ref(false);
const buttonText = computed(() =>
  isToggled.value ? toggledButtonText.value : untoggledButtonText.value,
);

const isDeployToggled = ref(false);
const deployButtonText = computed(() =>
  isDeployToggled.value ? 'Hide deploy settings' : 'Show deploy settings',
);

function handleMoreOptionsClick() {
  // FIXME: cancel while reviewing doesn't actually do anything??
  isToggled.value = !isToggled.value;
}

const isReviewConfigurations = computed(() => reviewConfigurations?.value && !isToggled.value);
</script>

<template>
  <div class="app-configuration">
    <!-- Required settings always shown -->
    <Question
      v-for="setting in basicSettings"
      :key="setting.id"
      :questionID="setting.id"
      :disabled="isReviewConfigurations"
    />

    <!-- Optional settings behind toggle -->
    <div class="app-configuration__bottom" v-if="advancedSettings.length > 0">
      <div v-if="isToggled" class="advanced-options">
        <Question
          v-for="setting in advancedSettings"
          :key="setting.id"
          :questionID="setting.id"
          :disabled="isReviewConfigurations"
        />
      </div>
    </div>

    <div
      class="app-configuration__bottom"
      v-if="showDeployConfig && deploySettings && deploySettings.length > 0"
    >
      <div v-if="isDeployToggled" class="advanced-options">
        <Question
          v-for="setting in deploySettings"
          :key="setting.id"
          :questionID="setting.id"
          :disabled="isReviewConfigurations"
        />
      </div>
    </div>

    <div class="advanced-options-toggle" v-if="advancedSettings.length > 0 || reviewConfigurations">
      <Button
        :label="buttonText"
        :iconPosition="IconPosition.Right"
        @click="handleMoreOptionsClick"
      >
        <component v-if="isToggled" :is="CloseIcon" class="slide-icon--down" />
      </Button>

      <Button
        v-if="showDeployConfig && deploySettings && deploySettings.length > 0"
        :label="deployButtonText"
        :iconPosition="IconPosition.Right"
        @click="() => (isDeployToggled = !isDeployToggled)"
      >
        <component v-if="isToggled" :is="CloseIcon" class="slide-icon--down" />
      </Button>

      <Button v-if="saveButton && isToggled" label="save changes" @click="handleMoreOptionsClick" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-configuration {
  .advanced-options-toggle {
    display: flex;
    justify-content: center;
    margin-top: space(3.5);

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }

    :deep(.button) {
      flex-grow: 1;

      @include media-breakpoint-up(md) {
        flex-grow: 0;
      }
    }
  }

  .advanced-options {
    &__field {
      &:first-child {
        margin-bottom: space(4);
      }
    }
  }
}
</style>

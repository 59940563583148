import { defineStore } from 'pinia';

export enum FeatureFlag {
  Internal = 'internal',
  AppConfig = 'app_config',
  Sleep = 'sleep',
}

export const useUserFlagsStore = defineStore('user_flags', () => {
  const flags = ref<FeatureFlag[]>([]);

  function setFlags(newFlags: FeatureFlag[]) {
    flags.value = newFlags;
  }

  function addFlag(flag: FeatureFlag) {
    if (!flags.value.includes(flag)) {
      flags.value.push(flag);
    }
  }

  function userHasFeatureFlag(flag: FeatureFlag) {
    if (flags.value) {
      return flags.value.includes(flag);
    }
    return false;
  }

  const isInternal = computed(() => {
    return userHasFeatureFlag(FeatureFlag.Internal);
  });

  return {
    flags,

    isInternal,

    /**
     * Features and functionality which will only ever been available to internal users.
     * These features are not intended to be made public at any time.
     */
    canAccessInternalOnlyFeatures: isInternal,

    /**
     * Features and functionality which are not yet ready for public use but will eventually be made
     * available by removing this flag.
     */
    canAccessPreviewFeatures: isInternal,

    addFlag,
    setFlags,
    userHasFeatureFlag,
  };
});

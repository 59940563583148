<!-- eslint-disable import/no-unresolved -->
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import FoxAndClover from '@clovyr/bed/assets/images/fox-and-clover.png';
import { GridItem, GridSection } from '@clovyr/bed/components/blocks';
import Markdown from '@clovyr/bed/components/blocks/Markdown.vue';
import { ComputeProviderName } from '@clovyr/pollen/types/Host';

import AppConfiguration from '@/components/forms/AppConfiguration.vue';
import HostSelector from '@/components/forms/HostSelector.vue';
import AppLauncherHero from '@/components/tiers/AppLauncherHero.vue';
import { useLauncherStore } from '@/stores/launcher_store';

import AddonsBackup from './AddonsBackup.vue';
import BYODomain from './BYODomain.vue';

const { basicSettings, advancedSettings, metadata } = storeToRefs(useLauncherStore());
const launcherStore = useLauncherStore();
const { selectedHost } = storeToRefs(launcherStore);

const onlyOptionalSettings = 'Configuration is complete.';

const appConfigHeading = computed(() => {
  if (basicSettings.value?.length > 0) {
    // TODO: skip counting for now as we weren't looking at nested settings
    return 'These items need your input:';
  }
  if (advancedSettings.value?.length) {
    return onlyOptionalSettings;
  }
  return '';
});

const isWNextSelected = computed(() => selectedHost.value?.id === ComputeProviderName.WNext);
</script>

<template>
  <div class="launch-setup">
    <div class="launch-setup__main">
      <AppLauncherHero
        :app-logo="metadata"
        :app-title="metadata.name"
        :right-image-src="FoxAndClover"
        :heading-text="`Set up ${metadata.name}`"
      />

      <GridSection :spacing="3">
        <GridItem :md="5">
          <div class="setup-controls-heading launch-heading--secondary">Hosting On:</div>
          <div class="section-highlighted background-gradient--base selector">
            <HostSelector />
          </div>
        </GridItem>
        <!-- App config -->
        <GridItem :md="7" class="config">
          <div class="setup-controls__left grid__col" v-if="appConfigHeading">
            <div class="setup-controls-heading launch-heading--secondary">
              <template v-if="appConfigHeading !== onlyOptionalSettings">
                {{ appConfigHeading }}
              </template>
              <template v-else> &nbsp; </template>
            </div>

            <div class="section-highlighted background-gradient--base">
              <div
                v-if="appConfigHeading === onlyOptionalSettings"
                class="setup-controls-heading launch-heading--secondary"
              >
                {{ appConfigHeading }}
              </div>
              <AppConfiguration
                untoggled-button-text="show optional settings"
                toggled-button-text="hide optional settings"
                @heading="(h) => (appConfigHeading = h)"
                class="options-block"
              />
            </div>
          </div>
          <div v-else class="section-highlighted background-gradient--base alt-appconfig">
            <markdown :content="metadata.description" />
          </div>

          <BYODomain v-if="isWNextSelected" class="domain" />
        </GridItem>
      </GridSection>
      <GridSection v-if="!isWNextSelected" :spacing="3" class="addon-backup">
        <GridItem :md="5">
          <AddonsBackup v-if="!isWNextSelected" />
        </GridItem>
        <GridItem :md="7">
          <BYODomain v-if="!isWNextSelected" class="domain" />
        </GridItem>
      </GridSection>
    </div>
  </div>
</template>

<style scoped lang="scss">
.launch-setup {
  &__main {
    margin-bottom: 0;

    .alt-appconfig {
      margin-top: space(5.5);
      @include media-breakpoint-down(xs) {
        margin-top: space(1);
      }
    }
    .selector {
      padding: space(3);
    }

    :deep(.hero-masthead) {
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }

      .hero-masthead__right {
        @include media-breakpoint-up(xl) {
          margin-top: 0;
        }
      }
    }

    .config {
      @include media-breakpoint-up(md) {
        margin-top: 1.5rem;
      }
      .domain {
        @include media-breakpoint-up(sm) {
          margin-top: 2rem;
        }
      }
    }

    .addon-backup {
      margin-top: 1.5rem;

      :deep(.setup) {
        margin-top: 1rem;
        @include media-breakpoint-up(sm) {
          margin-top: 0;
        }
      }
    }
  }
}

.launch-setup,
.review-setup {
  .setup-controls {
    display: flex;

    &__left {
      flex-basis: col-width(6, modal);
    }

    &__right {
      flex-basis: col-width(4, modal);
    }
  }

  .setup-controls-heading {
    @include text--h4;

    margin: 0 0 0.5rem 0.5rem;
    color: color(white);
  }

  .setup-grid-banner {
    @include font-size(15);

    display: flex;
    align-items: center;
    padding: space(2) space(5);

    @include media-breakpoint-up(md) {
      margin-top: 2rem;
    }
  }
  .grid-section {
    @include media-breakpoint-down(sm) {
      [class*='grid__col'] {
        margin-top: 1.5rem;
      }
    }
  }
}
</style>

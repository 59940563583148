export function baseState(stateName: string) {
  const invalidOp = (op) => () => {
    throw new Error(`invalid operation: cannot ${op} in in state ${stateName}`);
  };
  return {
    init: invalidOp('init'),
    signUp: invalidOp('signUp'),
    signUpWithNostr: invalidOp('signUpWithNostr'),
    logIn: invalidOp('logIn'),
    logInWithNostr: invalidOp('logInWithNostr'),
    lock: invalidOp('lock'),
    unlock: invalidOp('unlock'),
    addSecretKey: invalidOp('addSecretKey'),
    putItem: invalidOp('putItem'),
    deleteItem: invalidOp('deleteItem'),
    shareItem: invalidOp('shareItem'),
    acceptShare: invalidOp('acceptShare'),
    items: invalidOp('items'),
    secretKey: () => undefined,
    email: () => undefined,
  };
}

<script setup lang="ts">
import FormWrapper from '@/components/forms/FormWrapper.vue';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const onSubmit = async () => {
  emit('close');
};
</script>

<template>
  <div class="no-key-message modal">
    <h2>Email verification complete</h2>
    <div class="form">
      <FormWrapper submit-label="close" @submit="onSubmit"> </FormWrapper>
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-key-message {
  @include media-query(phone) {
    margin-right: 0;
    margin-left: 0;
  }

  h2 {
    margin-bottom: 1rem;
    padding-right: 2rem;
  }

  p {
    padding-right: 5rem;
    margin-bottom: 1rem;
    color: var(--Grey-2, #ada6b7);
    font-size: 16px;
  }

  .form {
    margin-bottom: 1rem;
  }
}
</style>

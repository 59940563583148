<script setup lang="ts">
import { useMediaQueryStore } from '@/stores/media_query_store';
import { ImageMedia } from '@clovyr/bed';
import type { Logo } from '@clovyr/bed/components';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import { storeToRefs } from 'pinia';

withDefaults(
  defineProps<{
    appLogo: Logo;
    headingText: string;
    appTitle: string;
    subheadingText?: string;
    subheadingText2?: string;
    rightImageSrc?: string;
  }>(),
  {
    headingText: '',
    appTitle: '',
    subheadingText: '',
    subheadingText2: '',
    rightImageSrc: '',
  },
);

const { isMobile } = storeToRefs(useMediaQueryStore());

const extraClass = computed(() => {
  return isMobile.value ? 'container' : '';
});
</script>

<template>
  <div class="app-launcher-hero" :class="extraClass">
    <AppLogo class="app-launcher-hero__app-icon" size="small" :logo="appLogo">
      <template #title>
        <div class="app-launcher-title">{{ appTitle }}</div>
      </template>
    </AppLogo>

    <div class="hero-masthead">
      <div class="hero-masthead__left">
        <h1 v-if="headingText" class="hero-heading">
          {{ headingText }}
        </h1>

        <div v-if="subheadingText" class="hero-subheading">
          {{ subheadingText }}
        </div>

        <div
          v-if="subheadingText2"
          class="hero-subheading"
          id="subheading-2"
          v-html="subheadingText2"
        ></div>
      </div>

      <div v-if="rightImageSrc" class="hero-masthead__right">
        <ImageMedia :filename="rightImageSrc" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-launcher-hero {
  @include media-breakpoint-up(md) {
    height: space(20);
  }

  &__app-icon {
    margin-bottom: space(1);
  }

  :deep(.app-launcher-title) {
    @include font-size(14);

    font-weight: 600;
    color: color(grey, secondary);
  }

  .hero-masthead {
    display: flex;
    justify-content: space-between;

    &__right {
      :deep(.image-media) {
        height: 7rem;
        transform: translateY(-1rem);

        @include media-breakpoint-up(sm) {
          height: 10rem;
          transform: translateY(-3rem);
          margin-right: 1rem;
        }
      }
    }
  }

  .hero-heading {
    @include font-size(30);

    @include media-breakpoint-up(md) {
      @include font-size(40);
    }

    @include media-breakpoint-up(xl) {
      @include font-size(50);
    }

    padding: 20px 0 0 0;

    @include media-breakpoint-up(sm) {
      padding-left: 1rem;
    }
  }

  .hero-subheading {
    @include font-size(18);

    @include media-breakpoint-up(md) {
      @include font-size(24);
    }

    margin-bottom: space(2.4);
  }
}
</style>

export class APIResponse {
  // @ts-expect-error obj in constructor is expected to set status
  status: 'success' | 'fail' | 'error';

  data?: any;

  message?: string;

  code?: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export interface JSendSuccess {
  status: 'success';
  data: any;
}

export interface JSendFail {
  status: 'fail';
  message: string;
  code: number;
}

export interface JSendError {
  status: 'error';
  message: string;
  code: number;
}

import { defineStore } from 'pinia';

export type DeviceType = {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
};

const queries = {
  // TODO: these breakpoints *should* be the same as in
  // bed/styles/vendor/bootstrap/scss/_variables.scss
  // $grid-breakpoints. However the way the current styles are written,
  // it breaks badly when changing the values here. Oh well.
  mobile: '(max-width: 767.98px)',
  tablet: '(max-width: 991.98px)',
  desktop: '(min-width: 992px)',
};

export const useMediaQueryStore = defineStore('media_query', () => {
  const isMobile = ref(false);
  const isTablet = ref(false);
  const isDesktop = ref(false);

  if (import.meta.env.SSR) {
    // server-side: only true for 'desktop'
    // TODO: change this to 'mobile' when we are fully mobile-first
    isDesktop.value = true;
  } else {
    // client-side: watch media query
    const mobileMedia = window.matchMedia(queries.mobile);
    isMobile.value = mobileMedia.matches;
    mobileMedia.onchange = () => {
      isMobile.value = mobileMedia.matches;
    };

    const tabletMedia = window.matchMedia(queries.tablet);
    isTablet.value = tabletMedia.matches;
    tabletMedia.onchange = () => {
      isTablet.value = tabletMedia.matches;
    };

    const desktopMedia = window.matchMedia(queries.desktop);
    isDesktop.value = desktopMedia.matches;
    desktopMedia.onchange = () => {
      isDesktop.value = desktopMedia.matches;
    };
  }

  /**
   * Bootstrap values in SSR
   *
   * @param deviceType
   * @returns
   */
  function setDeviceType(deviceType?: DeviceType) {
    if (!deviceType) {
      return;
    }
    isMobile.value = deviceType.mobile;
    isTablet.value = deviceType.tablet;
    isDesktop.value = deviceType.desktop;
  }

  return {
    isMobile,
    isTablet,
    isDesktop,

    setDeviceType,
  };
});

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { AppLogo, Button, type Logo } from '@clovyr/bed';
import { APIError } from '@clovyr/pollen/api/APIError';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';
import { isClovyrCode } from '@clovyr/pollen/garden';
import type { Manifest } from '@clovyr/pollen/manifest';
import { AuthState } from '@clovyr/pollen/pollen/types';
import { type ComputeProviderInfo, type Deployment, DeploymentState } from '@clovyr/pollen/types';

import Pin from '@/components/controls/Pin.vue';
import Module from '@/components/elements/Module.vue';
import { useAppStatus } from '@/composables/useAppStatus';
import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';
import { FeatureFlag, useUserFlagsStore } from '@/stores/user_flags';

import { useAppVersion } from '../../composables/useAppVersion';

// placeholder
type HostInfo = ComputeProviderInfo & {
  name: string;
  runtime?: string;
  freespace?: string;
  url?: string;
};

type LibraryAppCard = {
  app?: Manifest;
  deployment: Deployment;
  isShared?: boolean;
  itemID?: string;
  shareSecret?: string;
};

const props = defineProps<LibraryAppCard>();

const userFlags = useUserFlagsStore();
const { userHasFeatureFlag } = userFlags;
const router = useRouter();
const { pollen } = usePollenStore();
const { authState, isUserAuthenticated } = storeToRefs(usePollenStore());
const { eventBus } = useEventBus();
const deploymentID = computed(() => props.deployment.id);
const { isUpdateAvailable } = useAppVersion(deploymentID);
const { appStatus, appStatusLabel, isRunning } = useAppStatus(deploymentID);

const host = computed((): HostInfo | undefined => {
  const found = getHostByID(props.deployment.hostingProvider);
  if (found) {
    return { ...found, name: found.title };
  }
  return found;
});

const logo = computed((): Logo => {
  return {
    logoUrl: props.app?.metadata?.logoUrl,
    logoBackgroundColor: props.app?.metadata?.logoBackgroundColor,
  };
});

const appLink = computed(() => {
  if (isClovyrCode(props.deployment.appID)) {
    return `/view/${props.deployment.instanceName}`;
  }
  return `https://${props.deployment.fqdn}`;
});

const handleAppClick = async () => {
  if (props.isShared) {
    return undefined;
  }
  return router.push({ name: 'LibraryAppDetail', params: { id: props.deployment.id } });
};

const handleTogglePin = async () => {
  // eslint-disable-next-line vue/no-mutating-props
  props.deployment.isPinned = !props.deployment.isPinned;
  return pollen.putDeployment(props.deployment);
};

const continueToAcceptShare = async () => {
  if (isUserAuthenticated.value && authState.value === AuthState.LoggedIn) {
    eventBus.off('modal:login:close', continueToAcceptShare);
    try {
      await pollen.acceptShare(props.itemID!, props.shareSecret!);
    } catch (e) {
      if (e instanceof APIError) {
        if (e.message === 'item already shared') {
          eventBus.emit('toast:show', {
            message: 'Error accepting share: you already have access to this item',
            type: 'error',
            duration: 10000,
          });
          return;
        }
      }
      eventBus.emit('toast:show', {
        message: `Error accepting share: ${(e as Error).message}`,
        type: 'error',
        duration: 10000,
      });
      return;
    }
    await router.push({ name: 'Library' });
  }
};

const handleAcceptAppShare = async () => {
  if (
    authState.value === AuthState.LoggedOut ||
    authState.value === AuthState.Locked ||
    authState.value === AuthState.LoggedInWithoutSecretKey
  ) {
    const data = { opts: { isShared: true } };
    eventBus.emit('modal:login:show', data);
    eventBus.on('modal:login:close', continueToAcceptShare);
    return undefined;
  }
  return continueToAcceptShare();
};

const handleRejectAppShare = async () => {
  return router.push({ name: 'Library' });
};

// TODO: update options theme color based on runtime and state
</script>

<template>
  <div class="shared" v-if="isShared">
    <Module @click="handleAppClick" class="module--app module--app--shared">
      <template v-slot:default>
        <div class="module--app__top">
          <AppLogo size="small" :logo="logo" />
        </div>
        <div class="module--app__body">
          <h5>You have been invited to access:</h5>
          <h2 class="module--app__instancename" v-html="props.deployment.instanceName" />

          <div class="module--app__host" v-if="host && host.id">
            <template v-if="host.runtime || host.freespace">
              <template v-if="host.runtime">
                <span class="module--app__host__runtime">{{ host.runtime }} hrs</span>
                <template v-if="host.freespace">,</template>
              </template>
              <template v-if="host.freespace"> {{ host.freespace }} remaining </template>
              <span> on {{ host.title }} </span>
            </template>
          </div>
        </div>
      </template>
      <template v-slot:ctas>
        <div>
          <Button label="reject" @click.stop="handleRejectAppShare" />
          <Button :reversed="true" label="Accept & Open" @click.stop="handleAcceptAppShare" />
        </div>
      </template>
    </Module>
  </div>
  <div class="non-shared" v-else>
    <Module
      @click="handleAppClick"
      class="module--app"
      :class="{
        'module--app--is-running': deployment.state === DeploymentState.Running,
      }"
    >
      <template v-slot:default>
        <div class="module--app__top">
          <AppLogo size="small" :logo="logo" />
          <Pin @click.stop="handleTogglePin" :pinned="deployment.isPinned"></Pin>
        </div>
        <div class="module--app__body">
          <div class="module--app__appname" v-html="props.deployment.appName" />
          <h2 class="module--app__instancename" v-html="props.deployment.instanceName" />

          <!-- TODO: figure out if used elsewhere to move to component -->
          <div class="module--app__status module__status" :class="`module__status--${appStatus}`">
            {{ appStatusLabel }}
          </div>
          <div v-if="isUpdateAvailable" class="module--app__status">Update Available</div>

          <div class="module--app__host" v-if="host && host.id">
            <template v-if="host.runtime || host.freespace">
              <template v-if="host.runtime">
                <span class="module--app__host__runtime">{{ host.runtime }} hrs</span>
                <template v-if="host.freespace">,</template>
              </template>
              <template v-if="host.freespace"> {{ host.freespace }} remaining </template>
              <span> on {{ host.title }} </span>
            </template>
            <template v-if="!host.runtime || !host.freespace">
              Hosted on {{ host.title }}
            </template>
          </div>
        </div>
      </template>

      <template v-slot:ctas>
        <div>
          <template v-if="userHasFeatureFlag(FeatureFlag.Sleep)">
            <Button v-if="props.deployment.state === DeploymentState.Running" label="Sleep" />
            <Button :reversed="true" label="Launch" />
          </template>
          <Button v-if="props.deployment.isUpgradable" :reversed="true" label="Upgrade" />
          <Button
            label="See Details"
            :reversed="true"
            :translucent="true"
            @click="handleAppClick"
          />
          <Button
            :reversed="true"
            label="Open"
            @click.stop="() => {}"
            :link="appLink"
            link-target="_blank"
            :disabled="!isRunning && !isClovyrCode"
          />
        </div>
      </template>
    </Module>
  </div>
</template>

<style lang="scss" scoped>
.module--app {
  // standard .module style overrides
  @include flex($dir: column, $justify: space-between);

  cursor: pointer;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;

  &:hover {
    background: transparentize(color(action), 0.9);
    border-color: color(action);
  }

  .module__head,
  .module__ctas {
    flex: 0;

    div {
      display: flex;
    }
  }

  .module__body {
    flex: 1;
  }

  // top-level structure
  &__top {
    flex: 0;

    @include flex($justify: space-between);

    .app-icon {
      filter: drop-shadow(0 1.2px 6px rgb(0 0 0 / 25%));
    }
  }

  &__body {
    flex: 1;
    margin-top: space(2);

    h5 {
      color: color(grey, tertiary);
    }
  }

  &__appname {
    color: color(grey, secondary);
  }

  &__instancename {
    margin-bottom: space(2);
    margin-left: -0.1em;
    word-break: break-word;
  }

  &__status {
    margin-bottom: space(1);
  }

  &__host {
    color: color(grey, secondary);
  }

  &--is-running &__host {
    &__runtime {
      color: color(yellow);
    }
  }
}
.module--app--shared {
  cursor: default;
  background: linear-gradient(180deg, rgba(252, 177, 115, 0.6) 0%, #302744 100%);
  border-color: #5a556bc9;

  :deep(.unpinned-icon) {
    circle {
      stroke: white;
    }
    path {
      fill: #ffffff;
      stroke: #ffffff;
    }
  }
}
.module--app--shared:hover {
  background: linear-gradient(180deg, rgba(252, 177, 115, 0.4) 0%, #302744 100%);
}
</style>

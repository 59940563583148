type DropFn = (file: File) => Promise<void>;

/**
 * File drag/drop helper
 *
 * @param dragEnabled Drag enabled
 * @param cb method to call when a file is dropped
 * @returns
 */
export const useDrag = (dragEnabled: Ref<boolean> | boolean, cb: DropFn) => {
  const isDragging = ref(false);
  const isEnabled = typeof dragEnabled === 'boolean' ? ref(dragEnabled) : dragEnabled;

  function onDragOver(e) {
    e.preventDefault();
  }

  function onDragEnter(e) {
    e.preventDefault();
    if (isEnabled.value) {
      isDragging.value = true;
    }
  }

  function onDragLeave(e: DragEvent) {
    e.preventDefault();
    if (!(e.currentTarget as HTMLElement).contains(e.relatedTarget as HTMLElement)) {
      isDragging.value = false;
    }
  }

  async function onDrop(e) {
    e.preventDefault();
    isDragging.value = false;
    if (isEnabled.value && e.dataTransfer?.files?.length) {
      const file = e.dataTransfer.files[0] as File;
      await cb(file);
    }
  }

  return {
    isDragging,

    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
  };
};

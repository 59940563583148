import { randomBytes, secretbox } from 'tweetnacl';

import { AuthState, AuthType } from '../../../pollen/types';
import { decodeBase32 } from '../../../util/base32';
import { base64ToBytes, bytesToBase64 } from '../../../util/base64';
import { decryptMonolithicState, type StorageMigrator } from '../migrations';
import {
  type NoSecretKeyPasswordKeychain,
  type NostrKeychain,
  type OpenPasswordKeychain,
  type VaultItem,
  type WebStorage,
} from '../types';
import { xorKeys } from '../util';
import {
  createItems,
  derivePasswordKey,
  encodeKeychain,
  getVaultItems,
  migrateMonolithicState,
} from '..';

import { baseState } from './base';
import { statePasswordNoSecretKey } from './no_secret';
import { stateNostr } from './nostr';
import { stateOpenRemote } from './remote';
import type { ItemKeys, StateFunc } from './types';

export const ANONYMOUS_ITEM_PREFIX = 'clovyr.anonymousItem/';

export function stateAnonymous(): StateFunc {
  return ({ nostr, storage, userAPI, vaultAPI, anonymousKey, migrator }) => ({
    ...baseState('anonymous'),

    async signUp(email, password, items) {
      const secretKey = randomBytes(32);
      const salt = randomBytes(32);
      const passwordKey = await derivePasswordKey(password, salt);

      await userAPI.signUp({
        authType: 'password',
        salt: bytesToBase64(salt),
        password,
        email,
      });

      const keychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        passwordKey,
        secretKey,
        email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(keychain));

      const auk = xorKeys(passwordKey, secretKey);

      const itemKeys = await createItems(vaultAPI, items, auk);

      // remove anonymous items and key (no longer needed)
      Object.keys(itemKeys).forEach((key) => {
        storage.removeItem(ANONYMOUS_ITEM_PREFIX + key);
      });
      storage.removeItem('clovyr/anonymous-key');

      return stateOpenRemote(keychain, itemKeys);
    },

    async signUpWithNostr(email: string, items: VaultItem[]) {
      // TODO: add auth challenge tag
      const authEvent = await nostr.signEvent({
        created_at: Math.floor(Date.now() / 1000),
        kind: 22242,
        tags: [],
        content: JSON.stringify({
          // TODO: scope this to the current domain
          aud: 'clovyr.app',
        }),
      });

      const auk = randomBytes(32);

      const pubkey = await nostr.getPublicKey();
      const encryptedAuk = await nostr.nip44.encrypt(pubkey, bytesToBase64(auk));

      await userAPI.signUp({
        authType: 'nostr',
        authEvent,
        encryptedAuk,
        email,
      });

      const newKeychain: NostrKeychain = {
        state: 'nostr',
        auk,
        pubkey,
        email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));

      const itemKeys = await createItems(vaultAPI, items, auk);
      return stateNostr(newKeychain, itemKeys);
    },

    async logIn(email, password, secretKey) {
      const { salt } = await userAPI.authChallenge({ email });
      if (!salt) {
        throw new Error('no salt returned by server and no salt in local keychain');
      }

      const { encryptedState } = await userAPI.signIn({
        email,
        password,
      });

      const saltBytes = base64ToBytes(salt);
      const passwordKey = await derivePasswordKey(password, saltBytes);

      if (!secretKey) {
        const keychain: NoSecretKeyPasswordKeychain = {
          state: 'passwordNoSecretKey',
          email,
          passwordKey,
        };

        storage.setItem('clovyr/keychain', encodeKeychain(keychain));
        return [statePasswordNoSecretKey(keychain), []];
      }

      const secretKeyBytes = decodeBase32(secretKey);
      const auk = xorKeys(secretKeyBytes, passwordKey);

      let items: VaultItem[];
      let itemKeys: ItemKeys;
      if (encryptedState) {
        [items, itemKeys] = await migrateMonolithicState(
          encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
      } else {
        [items, itemKeys] = await getVaultItems(vaultAPI, auk, email);
      }

      const keychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        email,
        secretKey: secretKeyBytes,
        passwordKey,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(keychain));

      return [stateOpenRemote(keychain, itemKeys), items];
    },

    async logInWithNostr() {
      const authEvent = await nostr.signEvent({
        created_at: Math.floor(Date.now() / 1000),
        kind: 22242,
        tags: [],
        content: JSON.stringify({
          aud: 'clovyr.app',
        }),
      });

      const pubkey = await nostr.getPublicKey();
      const { email, encryptedAuk } = await userAPI.signInWithNostr({
        authEvent,
      });

      const auk = base64ToBytes(await nostr.nip44.decrypt(pubkey, encryptedAuk));
      const [items, itemKeys] = await getVaultItems(vaultAPI, auk, email);

      const keychain: NostrKeychain = {
        state: 'nostr',
        pubkey,
        auk,
        email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(keychain));

      return [stateNostr(keychain, itemKeys), items];
    },

    async putItem(item) {
      putAnonymousItem(storage, anonymousKey, item);
    },

    async deleteItem(itemID) {
      storage.removeItem(ANONYMOUS_ITEM_PREFIX + itemID);
    },

    authType() {
      return AuthType.Anonymous;
    },

    authState() {
      return AuthState.LoggedOut;
    },

    async items() {
      return loadAnonymousItems(storage, anonymousKey, migrator);
    },
  });
}

function putAnonymousItem(storage: WebStorage, anonymousKey: Uint8Array, item: VaultItem) {
  const data = new TextEncoder().encode(item.data);
  const nonce = randomBytes(24);

  const itemJSON = JSON.stringify({
    data: bytesToBase64(secretbox(data, nonce, anonymousKey)),
    nonce: bytesToBase64(nonce),
  });

  storage.setItem(ANONYMOUS_ITEM_PREFIX + item.id, itemJSON);
}

export function loadAnonymousItems(
  storage: WebStorage,
  anonymousKey: Uint8Array,
  migrator: StorageMigrator
): VaultItem[] {
  const oldAnonState = storage.getItem('clovyr/anonymous-state');
  if (oldAnonState) {
    const state = decryptMonolithicState(oldAnonState, anonymousKey);
    const items = migrator.migrateState(state);
    items.forEach((item) => putAnonymousItem(storage, anonymousKey, item));
    storage.removeItem('clovyr/anonymous-state');
    return items;
  }

  const items: VaultItem[] = [];
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    if (!key) {
      throw new Error('unreachable: out of bounds');
    }
    if (!key.startsWith(ANONYMOUS_ITEM_PREFIX)) {
      continue;
    }

    const itemJSON = storage.getItem(key);
    if (!itemJSON) {
      throw new Error('unreachable: no item found');
    }

    const obj = JSON.parse(itemJSON);
    const id = key.slice(ANONYMOUS_ITEM_PREFIX.length);
    const encData = base64ToBytes(obj.data);
    const nonce = base64ToBytes(obj.nonce);

    const data = secretbox.open(encData, nonce, anonymousKey);
    if (!data) {
      throw new Error('failed to decrypt anonymous vault item');
    }
    items.push({
      id,
      data: new TextDecoder().decode(data),
    });
  }
  return items;
}

<script setup lang="ts">
import { Form } from 'vee-validate';

import Button from '@clovyr/bed/components/controls/Button.vue';

const props = defineProps({
  headingText: {
    type: String,
    default: '',
  },
  submitLabel: {
    type: String,
    default: 'submit',
  },
  theme: {
    type: String,
    default: 'base',
  },
  validationSchema: {
    type: Object,
    default: () => ({}),
  },
  initialValues: {
    type: Object,
    default: undefined,
  },
  keepValues: {
    type: Boolean,
    default: false,
  },
  reversed: {
    type: Boolean,
    default: true,
  },
  translucent: {
    type: Boolean,
    default: false,
  },
  showSubmit: {
    type: Boolean,
    default: true,
  },
});

const { headingText, submitLabel, theme } = toRefs(props);

// for accessing methods like `form.setErrors`
const form = useTemplateRef('form');
defineExpose({ form });
</script>

<template>
  <Form
    class="form"
    :class="theme"
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    :keep-values="keepValues"
    ref="form"
  >
    <div v-if="!!headingText" class="form__heading">{{ headingText }}</div>

    <slot name="fields" />
    <slot name="disclaimer" />

    <div class="form__submit" v-if="showSubmit">
      <Button
        ref="submitBtn"
        :label="submitLabel"
        type="submit"
        :reversed="reversed"
        :translucent="translucent"
      />
    </div>
  </Form>
</template>

import { AuthState, AuthType } from '../../../pollen/types';
import { base64ToBytes } from '../../../util/base64';
import { type LockedKeychain, type OpenPasswordKeychain, type VaultItem } from '../types';
import { xorKeys } from '../util';
import { derivePasswordKey, encodeKeychain, getVaultItems, migrateMonolithicState } from '..';

import { baseState } from './base';
import type { StateOpenRemoteConstructor } from './remote';
import type { ItemKeys, StateFunc } from './types';

export type StateLockedConstructor = (keychain: LockedKeychain) => StateFunc;

export function stateLocked(keychain: LockedKeychain): StateFunc {
  return ({ storage, vaultAPI, userAPI, migrator }) => ({
    ...baseState('locked'),

    async unlock(password: string, stateOpenRemoteFn: StateOpenRemoteConstructor) {
      const { salt } = await userAPI.authChallenge({ email: keychain.email });
      if (!salt) {
        throw new Error('no salt returned by server and no salt in local keychain');
      }
      const saltBytes = base64ToBytes(salt);

      const { encryptedState } = await userAPI.signIn({
        email: keychain.email,
        password,
      });

      const passwordKey = await derivePasswordKey(password, saltBytes);
      const auk = xorKeys(passwordKey, keychain.secretKey);

      let items: VaultItem[];
      let itemKeys: ItemKeys;
      if (encryptedState) {
        [items, itemKeys] = await migrateMonolithicState(
          encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
      } else {
        [items, itemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      }

      const newKeychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        secretKey: keychain.secretKey,
        email: keychain.email,
        passwordKey,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));

      return [stateOpenRemoteFn(newKeychain, itemKeys), items];
    },

    email() {
      return keychain.email;
    },

    authType() {
      return AuthType.Password;
    },

    authState() {
      return AuthState.Locked;
    },
  });
}

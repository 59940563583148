/* eslint-disable no-await-in-loop */
// Promisified version of setTimeout
export async function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * Generic wait/retry function to retry an operation until it succeeds.
 *
 * @param initialSleep in ms
 * @param maxTries
 * @param delay between retries, in ms
 * @param fn
 * @returns
 */
export async function waitFor<T>(
  initialSleep: number,
  maxTries: number,
  delay: number,
  fn: () => Promise<[success: boolean, res: T]>
): Promise<T> {
  await sleep(initialSleep);
  let success: boolean = false;
  let memo: T;
  for (let tries = maxTries; tries > 0; tries--) {
    try {
      [success, memo] = await fn();
      if (success) {
        return memo;
      }
    } catch (e) {
      // retry
      console.error('wait for err', e);
    }
    await sleep(delay || 5000);
  }
  throw new Error('timed out waiting for operation to complete');
}

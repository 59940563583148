<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';

import AlertIcon from '@/components/icons/AlertIcon.vue';
import CloseIcon from '@/components/icons/CloseIcon.vue';

type StatusTypes = 'critical' | 'info' | 'success' | 'warning';

type BannerProps = {
  title?: string;
  status?: StatusTypes;
  dismissable?: boolean;
};

const props = defineProps<BannerProps>();

const hideOnClose = ref(true);

const emit = defineEmits(['close']);

const handleClose = () => {
  hideOnClose.value = !hideOnClose.value;
  emit('close');
};

const classnames = computed(() => [props.status && `banner-alert-${props.status}`]);
</script>

<template>
  <transition>
    <div v-if="hideOnClose" :class="classnames" class="banner-alert">
      <div class="banner-alert-icon">
        <AlertIcon />
      </div>

      <div class="banner-alert-content">
        <div v-if="title" class="banner-alert-heading" v-html="title" />
        <div class="content-wrapper">
          <slot></slot>
        </div>
      </div>

      <div v-if="dismissable" class="banner-alert-close">
        <Button class="button--transparent" :shape="ButtonShape.Circle" @click="handleClose()">
          <CloseIcon />
        </Button>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.banner-alert {
  align-items: center;
  background-color: transparentize(color(offwhite, lighter), 0.85);
  border: 1px solid color(grey, medium);
  border-radius: 0.5rem;
  color: #ffffff;
  display: flex;
  margin-top: 1rem;
  padding: 0.75rem;
  position: relative;

  &.banner-alert-warning {
    background-color: transparentize(color(yellow), 0.93);
    border-color: transparentize(color(yellow), 0.5);
    color: color(yellow);
  }

  &.banner-alert-critical {
    background-color: transparentize(color(bad, secondary), 0.93);
    border-color: transparentize(color(bad, secondary), 0.5);
    color: color(bad, secondary);
  }

  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.2s ease;
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }

  .banner-alert-heading {
    @include font-size(18);
    font-weight: bold;
  }

  .banner-alert-close {
    align-self: flex-start;
    margin-left: auto;

    .button {
      color: currentColor;
      height: 1.375rem;
      min-height: 0;
      padding: 0.2rem;
      width: 1.375rem;

      svg {
        width: calc(var(--button-icon-size) / 2);
        height: calc(var(--button-icon-size) / 2);
        stroke: currentColor;
      }
    }
  }

  .banner-alert-content {
    @include font-size(16);
    margin-left: 1.125rem;
  }

  .banner-alert-icon {
    flex-shrink: 0;
    height: 2rem;
    width: 2rem;
  }
}
</style>

import { AuthState, AuthType } from '../../../pollen/types';
import { type NostrKeychain } from '../types';
import { acceptShare, getVaultItems, putVaultItem, shareItem } from '..';

import { baseState } from './base';
import type { ItemKeys, StateFunc } from './types';

export function stateNostr(keychain: NostrKeychain, itemKeys: ItemKeys): StateFunc {
  return ({ vaultAPI }) => ({
    ...baseState('nostr'),

    async putItem(item) {
      return putVaultItem(vaultAPI, itemKeys, keychain.auk, item);
    },

    async deleteItem(itemID) {
      await vaultAPI.deleteItem(itemID);
      delete itemKeys[itemID];
    },

    async items() {
      const [items, newItemKeys] = await getVaultItems(vaultAPI, keychain.auk, keychain.email);
      itemKeys = newItemKeys;
      return items;
    },

    authType() {
      return AuthType.Nostr;
    },

    async shareItem(itemID, extraParams) {
      return shareItem(vaultAPI, keychain.auk, itemID, itemKeys, extraParams);
    },

    async acceptShare(itemID: string, shareSecret: string) {
      const [items, newItemKeys] = await acceptShare(
        vaultAPI,
        keychain.auk,
        itemID,
        shareSecret,
        keychain.email
      );
      itemKeys = newItemKeys;
      return items;
    },

    authState() {
      return AuthState.LoggedIn;
    },

    email() {
      return keychain.email;
    },
  });
}

<script lang="ts" setup>
import { ClovyrCodeLogo } from '@clovyr/bed';
import Markdown from '@clovyr/bed/components/blocks/Markdown.vue';

import { usePollenStore } from '../../stores/pollen_store';

import InstantCode from './InstantCode.vue';

defineProps<{ instantLaunchApp: string }>();

const { garden } = usePollenStore();
const app = garden.getAppByID('code-chia')!;
</script>
<template>
  <InstantCode :instant-launch-app="instantLaunchApp" hide-code-desc>
    <ImageMedia filename="catalog/chia-logo.svg" class="chia" />
    <ClovyrCodeLogo class="code-logo" />

    <h2>Welcome to the {{ app.metadata.name }}!</h2>
    <Markdown :content="app.metadata.description" />
    <Markdown :content="app.metadata.huginnSays" />
  </InstantCode>
</template>

<style lang="scss" scoped>
.instant {
  .chia {
    :deep(img) {
      width: 246px;
    }
  }
  .code-logo {
    margin-top: 10px;
    width: 246px;
  }

  p,
  div.markdown {
    @include text--color-grey-light;

    margin-top: 1em;
  }

  h2 {
    margin-top: 1rem;
  }
}
</style>

import type { RouteRecordRaw } from 'vue-router';

import type { ShowLoginEvent } from '@/stores/event_bus';

import { usePollenStore } from '../stores/pollen_store';
import { ShowAuthForm } from '../views/Settings/types';

export const userRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'main',
    },
    props: () => {
      return { login: { redirOnCancel: true } }; // auto-detect appropriate login form
    },
    component: () => import('../views/Garden.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: {
      layout: 'main',
    },
    props: {
      login: {
        form: ShowAuthForm.Signup,
        redirOnCancel: true,
      } as ShowLoginEvent,
    },
    component: () => import('../views/Garden.vue'),
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    meta: {
      layout: 'main',
    },
    props: (to) => {
      return {
        login: {
          form: ShowAuthForm.Login,
          redirOnCancel: true,
          opts: {
            text: 'You must authenticate to complete the email verification.',
            token: to.query.verify_token,
            verifyEmail: true,
          },
        } as ShowLoginEvent,
      };
    },
    beforeEnter: (to) => {
      if (to.query.success === 'true') {
        return '/verification-complete';
      }
      if (to.query.error === 'missing_token' || !to.query.redir) {
        return '/resend-verification';
      }
      return true;
    },
    component: () => import('../views/Garden.vue'),
  },
  {
    path: '/verification-complete',
    name: 'VerificationComplete',
    meta: {
      layout: 'main',
    },
    props: {
      login: {
        form: ShowAuthForm.VerificationComplete,
      } as ShowLoginEvent,
    },
    component: () => import('../views/Garden.vue'),
  },
  {
    path: '/resend-verification',
    name: 'ResendVerification',
    meta: {
      layout: 'main',
    },
    props: {
      login: {
        form: ShowAuthForm.ResendVerification,
      } as ShowLoginEvent,
    },
    component: () => import('../views/Garden.vue'),
  },
  {
    // this route is currently 'hidden' - not linked anywhere. useful for debugging the print view
    path: '/recovery',
    name: 'RecoveryKitPDF',
    meta: {
      layout: 'blank',
      title: 'Recovery Kit',
      description: 'View or print your Clovyr account recovery kit.',
      canonical: 'https://clovyr.app/recovery',
      disableSSR: true,
    },
    props: () => {
      const { email, secretKey, formattedSecretKey } = usePollenStore();

      return { email, secretKey, formattedSecretKey };
    },
    component: () =>
      import(/* webpackChunkName: "recoveryKitPdf" */ '../views/RecoveryKit/RecoveryKitPDF.vue'),
  },
];

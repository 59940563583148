<script setup lang="ts">
import { ButtonShape } from '../controls/types';
import GreenCheckIcon from '../icons/GreenCheckIcon.vue';
import RedXicon from '../icons/RedXicon.vue';

export type AsyncStringFn = () => Promise<string>;
const props = withDefaults(
  defineProps<{
    text: string | AsyncStringFn;
    label?: string;
    icon?: object;
    hasModal?: boolean; // copy component will trigger modal opening on parent
    disabled?: boolean;
    translucent?: boolean;
  }>(),
  {
    translucent: true,
  }
);

const emit = defineEmits<{
  (e: 'openModal'): void;
}>();

type CopyStatus = 'idle' | 'copied' | 'failed';

const status = ref<CopyStatus>('idle');

function copyText(text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      // success
      status.value = 'copied';
      setTimeout(() => {
        status.value = 'idle';
      }, 2000);
    },
    () => {
      // failed
      status.value = 'failed';
      setTimeout(() => {
        status.value = 'idle';
      }, 4000);
    }
  );
}

async function onClickCopy() {
  if (props.hasModal) {
    emit('openModal');
  }
  if (typeof props.text === 'function') {
    try {
      copyText(await props.text());
    } catch (error) {
      console.error('Error while copying text:', error);
    }
  } else {
    copyText(props.text);
  }
}
</script>

<template>
  <Button
    class="copy-btn"
    :shape="!!label ? undefined : ButtonShape.Circle"
    :translucent="translucent"
    aria-label="Copy"
    @click="onClickCopy"
    :disabled="disabled"
  >
    <div class="label" v-if="label">{{ label }}</div>
    <component :is="icon" v-if="status === 'idle'" />
    <Transition v-if="status === 'copied'">
      <GreenCheckIcon />
    </Transition>
    <Transition v-if="status === 'failed'">
      <RedXicon />
    </Transition>
  </Button>
</template>

<style scoped lang="scss">
.copy-btn {
  .label {
    margin-right: 10px;
  }
  svg {
    stroke: none;
  }
}
.copy-btn:hover {
  :deep(.ios-share-icon) {
    path {
      stroke: #000;
      fill: #000;
    }
  }
  :deep(.android-share-icon) {
    path {
      stroke: #000;
      fill: #000;
    }
  }
  :deep(.green-check-icon) {
    path {
      stroke: #000;
    }
  }
  :deep(.red-x-icon) {
    path {
      stroke: #000;
    }
  }
}
</style>

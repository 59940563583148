import { randomBytes, secretbox } from 'tweetnacl';

import { argon2d } from '@clovyr/hash-wasm';

import { base64ToBytes, bytesToBase64 } from '../../util/base64';

import type { ItemKeys } from './state/types';
import type { EncryptedVaultItem } from './api';
import { decryptMonolithicState, type StorageMigrator } from './migrations';
import {
  type Keychain,
  LATEST_KEYCHAIN_VERSION,
  type UserAPI,
  type VaultAPI,
  type VaultItem,
} from './types';

export * from './types';

function decryptVaultItem(auk: Uint8Array, encItem: EncryptedVaultItem): DecryptItemResult {
  const encData = base64ToBytes(encItem.encData);
  const nonce = base64ToBytes(encItem.encDataNonce);
  const encKey = base64ToBytes(encItem.encItemKey);
  const keyNonce = base64ToBytes(encItem.encItemKeyNonce);

  const key = secretbox.open(encKey, keyNonce, auk);
  if (!key) {
    throw new Error('failed to decrypt vault item key');
  }
  const data = secretbox.open(encData, nonce, key);
  if (!data) {
    throw new Error('failed to decrypt vault item');
  }

  return {
    item: {
      id: encItem.id,
      data: new TextDecoder().decode(data),
    },
    key,
  };
}

interface DecryptItemResult {
  item: VaultItem;
  key: Uint8Array;
}

export async function derivePasswordKey(password: string, salt: Uint8Array): Promise<Uint8Array> {
  const normalizedPassword = password.normalize('NFKC');
  return argon2Digest(normalizedPassword, salt);
}

async function argon2Digest(password: string | Uint8Array, salt: Uint8Array): Promise<Uint8Array> {
  // TODO: switch to argon2id
  const key = argon2d({
    password,
    salt,
    parallelism: 1,
    iterations: 1, // time
    memorySize: 16, // 2 ^ 16 == 65536 kibibytes == 64 mebibytes
    hashLength: 32,
    outputType: 'binary',
  });
  return key;
}

export function encodeKeychain(keychain: Keychain): string {
  const { state } = keychain;

  const toJSON = (obj: any) =>
    JSON.stringify({
      ...obj,
      state,
      version: LATEST_KEYCHAIN_VERSION,
    });

  if (state === 'nostr') {
    return toJSON({
      pubkey: keychain.pubkey,
      auk: bytesToBase64(keychain.auk),
      email: keychain.email,
    });
  }
  if (state === 'passwordOpen') {
    return toJSON({
      passwordKey: bytesToBase64(keychain.passwordKey),
      secretKey: bytesToBase64(keychain.secretKey),
      email: keychain.email,
    });
  }
  if (state === 'passwordLocked') {
    return toJSON({
      secretKey: bytesToBase64(keychain.secretKey),
      email: keychain.email,
    });
  }
  if (state === 'passwordNoSecretKey') {
    return toJSON({
      email: keychain.email,
      passwordKey: bytesToBase64(keychain.passwordKey),
    });
  }
  throw new Error(`unreachable: unknown keychain state: ${state}`);
}

export async function putVaultItem(
  vaultAPI: VaultAPI,
  itemKeys: ItemKeys,
  auk: Uint8Array,
  item: VaultItem
): Promise<void> {
  const data = new TextEncoder().encode(item.data);

  if (itemKeys[item.id]) {
    const key = itemKeys[item.id];
    const nonce = randomBytes(24);
    const encData = secretbox(data, nonce, key);

    await vaultAPI.updateItem({
      id: item.id,
      encDataNonce: bytesToBase64(nonce),
      encData: bytesToBase64(encData),
    });
  } else {
    const key = randomBytes(32);
    const keyNonce = randomBytes(24);
    const nonce = randomBytes(24);
    const encData = secretbox(data, nonce, key);
    const encKey = secretbox(key, keyNonce, auk);

    await vaultAPI.createItem({
      id: item.id,
      encData: bytesToBase64(encData),
      encDataNonce: bytesToBase64(nonce),
      encItemKey: bytesToBase64(encKey),
      encItemKeyNonce: bytesToBase64(keyNonce),
    });
    itemKeys[item.id] = key;
  }
}

export async function getVaultItems(
  vaultAPI: VaultAPI,
  auk: Uint8Array,
  email: string
): Promise<[VaultItem[], ItemKeys]> {
  const encItems = await vaultAPI.listItems(email);
  const itemKeys = {};
  const items: VaultItem[] = [];

  for (let i = 0; i < encItems.length; i++) {
    const result = decryptVaultItem(auk, encItems[i]);
    items.push(result.item);
    itemKeys[result.item.id] = result.key;
  }

  return [items, itemKeys];
}

export async function deriveBits(shareKey: Uint8Array, info: string): Promise<Uint8Array> {
  const { subtle } = window.crypto;
  const subtleShareKey = await subtle.importKey('raw', shareKey, 'HKDF', false, ['deriveBits']);

  const shareToken = await subtle.deriveBits(
    {
      name: 'HKDF',
      hash: 'SHA-256',
      salt: new Uint8Array(32),
      info: new TextEncoder().encode(info),
    },
    subtleShareKey,
    256
  );
  return new Uint8Array(shareToken);
}

export async function createItems(
  vaultAPI: VaultAPI,
  items: VaultItem[],
  auk: Uint8Array
): Promise<ItemKeys> {
  const itemKeys = {};
  await Promise.all(
    items.map(async (item) => {
      const data = new TextEncoder().encode(item.data);
      const nonce = randomBytes(24);
      const itemKey = randomBytes(32);
      const itemKeyNonce = randomBytes(24);

      const encData = secretbox(data, nonce, itemKey);
      const encItemKey = secretbox(itemKey, itemKeyNonce, auk);

      await vaultAPI.createItem({
        id: item.id,
        encData: bytesToBase64(encData),
        encDataNonce: bytesToBase64(nonce),
        encItemKey: bytesToBase64(encItemKey),
        encItemKeyNonce: bytesToBase64(itemKeyNonce),
      });
      itemKeys[item.id] = itemKey;
    })
  );
  return itemKeys;
}

export async function migrateMonolithicState(
  encryptedState: string,
  auk: Uint8Array,
  userAPI: UserAPI,
  vaultAPI: VaultAPI,
  migrator: StorageMigrator
): Promise<[VaultItem[], ItemKeys]> {
  const state = decryptMonolithicState(encryptedState, auk);
  const items = migrator.migrateState(state);
  const itemKeys: ItemKeys = {};
  await Promise.all(items.map((item) => putVaultItem(vaultAPI, itemKeys, auk, item)));
  await userAPI.removeMonolithicState();
  return [items, itemKeys];
}

export async function shareItem(
  vaultAPI: VaultAPI,
  auk: Uint8Array,
  itemID: string,
  itemKeys: ItemKeys,
  extraParams: string
): Promise<string> {
  const itemKey = itemKeys[itemID];
  if (!itemKey) {
    throw new Error(`cannot share unknown item: ${itemID}`);
  }

  const shareSecret = randomBytes(32);
  const shareToken = await deriveBits(shareSecret, 'clovyr.share-token');
  const shareKey = await deriveBits(shareSecret, 'clovyr.share-key');

  const itemNonce = randomBytes(24);
  const encItemKey = secretbox(itemKey, itemNonce, shareKey);

  const shareSecretNonce = randomBytes(24);
  const encShareSecret = secretbox(shareSecret, shareSecretNonce, auk);

  const shareTokenBase64 = bytesToBase64(shareToken);
  const resp = await vaultAPI.shareItem({
    id: itemID,
    encShareSecret: bytesToBase64(encShareSecret),
    encShareSecretNonce: bytesToBase64(shareSecretNonce),
    shareToken: shareTokenBase64,
    encItemKey: bytesToBase64(encItemKey),
    encItemKeyNonce: bytesToBase64(itemNonce),
  });

  if (!resp) {
    return `${window.location.origin}/library/accept-share/${itemID}?${extraParams}#${bytesToBase64(shareSecret)}`;
  }

  const existingEncShareKey = base64ToBytes(resp.encShareKey);
  const existingShareNonce = base64ToBytes(resp.encShareKeyNonce);
  const existingShareKey = secretbox.open(existingEncShareKey, existingShareNonce, auk);
  if (!existingShareKey) {
    throw new Error('share link already exists but we cannot decrypt the share secret');
  }

  return `${window.location.origin}/library/accept-share/${itemID}?${extraParams}#${bytesToBase64(existingShareKey)}`;
}

export async function acceptShare(
  vaultAPI: VaultAPI,
  auk: Uint8Array,
  itemID: string,
  shareSecret: string,
  email: string
): Promise<[VaultItem[], ItemKeys]> {
  const shareSecretBytes = base64ToBytes(shareSecret);
  const shareToken = bytesToBase64(await deriveBits(shareSecretBytes, 'clovyr.share-token'));
  const shareKey = await deriveBits(shareSecretBytes, 'clovyr.share-key');

  const resp = await vaultAPI.getItemShare({ id: itemID, shareToken });
  const encItemKey = base64ToBytes(resp.encItemKey);
  const itemNonce = base64ToBytes(resp.encItemKeyNonce);
  const itemKey = secretbox.open(encItemKey, itemNonce, shareKey);
  if (!itemKey) {
    throw new Error('cannot accept share: failed to decrypt item key');
  }

  const myItemNonce = randomBytes(24);
  const myEncItemKey = secretbox(itemKey, myItemNonce, auk);

  await vaultAPI.acceptShare({
    id: itemID,
    shareToken,
    encItemKey: bytesToBase64(myEncItemKey),
    encItemKeyNonce: bytesToBase64(myItemNonce),
  });

  // return all items we now have access to (not just the new ones)
  return getVaultItems(vaultAPI, auk, email);
}

/* eslint-disable */
/* Do not change, this code is generated from Golang structs */


export class InvoiceLineItem {
    id: string;
    invoice_id: string;
    subscription_item_id: string;
    description: string;
    amount: string;
    currency: string;
    created_at: Date;
    updated_at: Date;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.invoice_id = source["invoice_id"];
        this.subscription_item_id = source["subscription_item_id"];
        this.description = source["description"];
        this.amount = source["amount"];
        this.currency = source["currency"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
    }
}
export class Invoice {
    id: string;
    subscription_id: string;
    invoice_number: string;
    invoice_date: Date;
    due_date: Date;
    amount: string;
    currency: string;
    payment_provider: string;
    status: string;
    created_at: Date;
    updated_at: Date;
    paid_at?: Date;
    line_items: InvoiceLineItem[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.subscription_id = source["subscription_id"];
        this.invoice_number = source["invoice_number"];
        this.invoice_date = source["invoice_date"] ? new Date(source["invoice_date"]) : source["invoice_date"];
        this.due_date = source["due_date"] ? new Date(source["due_date"]) : source["due_date"];
        this.amount = source["amount"];
        this.currency = source["currency"];
        this.payment_provider = source["payment_provider"];
        this.status = source["status"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
        this.paid_at = source["paid_at"] ? new Date(source["paid_at"]) : source["paid_at"];
        this.line_items = this.convertValues(source["line_items"], InvoiceLineItem);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
<script setup lang="ts">
import { Hero, ImageMedia, Tips } from '@clovyr/bed';
import FlowerBg from '@clovyr/bed/assets/images/clovyr-flower-bg.svg';
import Markdown from '@clovyr/bed/components/blocks/Markdown.vue';
import PricingBlock from '@clovyr/bed/components/blocks/PricingBlock.vue';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import { useImageAsset } from '@clovyr/bed/composables/useImageAsset';
import type { Manifest } from '@clovyr/pollen/manifest/manifest';
import { slugify } from '@clovyr/pollen/util/slugify';

import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';
import Tag from '@/components/elements/Tag.vue';
import { useAppHead } from '@/composables/useAppHead';
import { useCloudType } from '@/composables/useCloudType';
import { useLaunchButton } from '@/composables/useLaunchButton';
import { appURL } from '@/router/util';

import LaunchButton from './AppLauncher/LaunchButton.vue';

const imageAsset = useImageAsset();
const props = defineProps<{
  app: Manifest;
}>();

// FIXME: rename data to metadata
const data = toRef(props.app, 'metadata');
const { cloudName } = useCloudType();
const { handleLaunchClicked } = useLaunchButton(props.app);

const appBackground = computed(() => {
  if (!data.value.backgroundImageUrl) {
    return undefined;
  }
  const bg = `url("${imageAsset.getURL(data.value.backgroundImageUrl)}")`;
  return {
    '--bg-url': bg,
  };
});

const logoUrlExists = computed(() => {
  if (data.value.logoUrl && data.value.logoUrl !== '') {
    return true;
  }
  return false;
});

useAppHead(props.app.metadata);
</script>

<template>
  <div class="app-detail" :style="appBackground">
    <div class="app-detail__top">
      <div class="app-background-gradient"></div>
      <div class="app-background"></div>

      <div class="app-detail-masthead">
        <div class="app-detail-masthead__left">
          <Hero subheadingTextShade="light" class="app-detail__hero">
            <template v-slot:heading> {{ data.name }} </template>

            <template v-slot:subheading>
              {{ data.tagline }}
            </template>

            <template v-slot:top>
              <AppLogo
                class="app-logo-detail"
                size="large"
                :logo="app.metadata"
                v-if="logoUrlExists"
              />
              <AppLogo class="app-logo-detail" size="large" :logo="data" v-else />

              <Breadcrumbs
                :crumbs="[
                  {
                    name: 'garden',
                    routeTo: '/',
                    color: '1',
                  },
                  // TODO: remove temporarily until we have a better way to set this
                  // dynamically. i.e., if landing on detail page via a collection link, include it.
                  // otherwise, hide it? what about tag links?
                  // {
                  //   name: 'bitcoin',
                  //   routeTo: '/collection/bitcoin',
                  //   color: '2',
                  // },
                  {
                    name: data.name,
                  },
                ]"
              />
            </template>

            <template v-slot:right>
              <div class="app-controls button-group">
                <LaunchButton reversed :app="app" />

                <!-- TODO: bookmark -->
                <!-- <Bookmark /> -->
              </div>
            </template>
          </Hero>
        </div>
      </div>

      <div class="app-hero-image grid">
        <ImageMedia v-if="data.heroImageUrl" :filename="data.heroImageUrl" />
      </div>
    </div>

    <div>
      <Tips v-if="data.huginnSays">
        <template v-slot:heading> huginn says: </template>

        <template v-slot:body>
          <markdown :content="data.huginnSays" />
        </template>
      </Tips>

      <div class="app-info">
        <div class="container container-common">
          <div class="app-info-top row">
            <div class="app-info-top__left grid__col-md-6">
              <div class="app-info__copy">
                <h2 class="app-info__heading" v-if="data.description">Overview</h2>
                <markdown :content="data.description" />
              </div>

              <div class="app-tags" v-if="data.tags?.length">
                <div class="app-tags__heading">Tagged in:</div>
                <Tag
                  v-for="tag in data.tags"
                  :key="slugify(tag)"
                  :label="tag"
                  :slug="slugify(tag)"
                />
              </div>
            </div>

            <div class="app-info-top__right grid__col-md-6" v-if="data.features">
              <div class="app-info__copy">
                <h2 class="app-info__heading">Features</h2>

                <ul>
                  <li v-for="(feature, index) in data.features" :key="index">
                    {{ feature }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="app-preview" v-if="false && data.media">
            <h3>Previews</h3>
            <div class="app-preview-images">
              <div class="app-preview-image" v-for="(media, index) in data.media" :key="index">
                <ImageMedia :filename="media.url" />
              </div>
            </div>
          </div>

          <div class="app-specs copy">
            <div class="app-specs__row">
              <span class="app-specs__key" v-if="data.website?.text"
                >Website:
                <c-link
                  :to="data.website?.url"
                  :label="data.website.text"
                  target="_blank"
                  class="app-specs__value app-specs__value--link"
                />
              </span>
              <span
                v-if="
                  (data.website?.text && data.version) || (data.website?.text && data.repo?.text)
                "
                class="app-specs__spacer"
                >|</span
              >
              <span class="app-specs__key" v-if="data.repo?.text"
                >Source Code:
                <c-link
                  :to="data.repo?.url"
                  :label="data.repo?.text"
                  target="_blank"
                  class="app-specs__value app-specs__value--link"
                />
              </span>
              <span v-if="data.version && data.repo?.text" class="app-specs__spacer">|</span>
              <span class="app-specs__key" v-if="data.version"
                >Version:
                <span class="app-specs__value">{{ data.version }}</span>
              </span>
              <span v-if="data.version && data.license" class="app-specs__spacer">|</span>
              <span class="app-specs__key" v-if="data.license"
                >License:
                <span class="app-specs__value">{{ data.license }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="app-launch-bar">
        <div class="container container-common">
          <div class="app-launch-bar__left" v-if="data.name">
            <h2 class="app-launch-bar__title">
              Launch {{ data.name }} with Clovyr on {{ cloudName }}
            </h2>
          </div>

          <div class="app-launch-bar__right">
            <LaunchButton reversed :app="app" />

            <!-- <Bookmark /> -->
          </div>
        </div>
      </div>
    </div>
    <router-view :parentRoute="appURL(app)"> </router-view>
  </div>

  <PricingBlock @launch="handleLaunchClicked" :image="FlowerBg" :app="app" />
</template>

<style lang="scss" scoped>
.app-specs__value {
  padding: 0 0 0 5px;
}

.app-detail {
  :deep(.hero) {
    .hero__inner {
      align-items: flex-start;
      margin-bottom: space(5);
      padding-top: space(10);

      @include media-breakpoint-up(md) {
        align-items: flex-end;
        margin-bottom: space(9);
      }

      @include media-query(phone) {
        padding-top: space(12);
      }

      .hero__middle {
        margin-bottom: 0;

        .hero__heading {
          @include font-size(32);

          @include media-breakpoint-up(md) {
            @include font-size(42);
          }

          @include media-breakpoint-up(xl) {
            @include font-size(67);
          }

          margin-bottom: 0;
        }
      }
    }
  }

  .app-background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -4;
    width: 100%;
    height: space(60);
    background: linear-gradient(180deg, rgb(20 20 20 / 80%) 0%, color(negative) 100%);
  }

  .app-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100%;
    height: space(60);
    background: var(--bg-url);

    @include background-image;
  }

  .app-logo-detail {
    margin-bottom: space(3.7);
  }

  .app-controls {
    margin-top: space(3);

    @include media-breakpoint-up(md) {
      margin-top: 20%;
    }
  }

  .app-hero-image {
    display: flex;
    justify-content: space-around;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      padding-left: 0;
    }

    :deep(.image-media) {
      box-shadow: space(1.2) space(1.2) space(20) 0 transparentize(color(black), 0.5);
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      display: flex;
      justify-content: center;
      max-width: space(140);
      overflow: hidden;
      width: 80%;

      img {
        width: 100%;
      }
    }

    iframe {
      @include borders-rounded;

      height: space(69.4);
      padding: space(4) var(--col-gutter-outer-left) space(2) var(--col-gutter-outer-right);

      @include media-breakpoint-down(md) {
        padding: 0 calc(var(--col-gutter-outer-left) + #{col-width(0.5)}) space(11);
      }
    }
  }

  .app-info {
    color: color(grey, tertiary);
    background-color: transparentize(color(background, dark-navy), 0.5);
    width: fit-content;

    &__copy {
      @include copy;

      @include font-size(16);

      color: color(white);
      line-height: 1.4;

      li {
        list-style: disc;
        &::before {
          content: '';
        }
      }

      p {
        margin-top: space(4);
      }
    }

    &__heading {
      @include font-size(26);

      color: color(white);
      font-weight: 500;
      letter-spacing: -0.03rem;
    }
  }

  .app-info-top {
    padding-top: space(4);
    padding-bottom: space(4);
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      padding-bottom: space(8);
      flex-direction: row;
    }

    .app-info-top__right {
      margin-top: space(3);
      margin-bottom: space(5);

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  .app-tags {
    margin-top: space(3);

    &__heading {
      display: inline-block;
      margin-right: space(0.8);
    }
  }

  .app-preview-images {
    .app-preview-image {
      margin: space(2) 0 space(3) 0;
    }
  }

  .app-specs {
    &__row:not(:last-child) {
      margin-bottom: space(0.8);
    }

    &__key {
      display: inline-block;
      margin-right: space(0.8);
    }

    &__value {
      @include text--mono;

      display: inline-block;

      &--link {
        color: color(action, primary);
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    &__spacer {
      margin: 0 space(2.2);
    }
  }

  .app-launch-bar {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: transparentize(color(background, dark-navy), 0.5);

    @include media-breakpoint-up(md) {
      padding-top: space(8);
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
      }
    }

    &__right {
      display: flex;
      align-items: flex-end;
      margin-top: space(2);

      @include media-breakpoint-up(sm) {
        justify-content: flex-end;
        padding-left: 2rem;
      }
    }
    &__left {
      min-height: 6rem;
    }

    &__title {
      @include font-size(22);

      color: color(grey, tertiary);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: -0.03rem;

      @include media-breakpoint-up(md) {
        @include font-size(30);

        font-weight: 400;
      }

      @include media-breakpoint-up(xl) {
        @include font-size(36);
      }
    }
  }
}
</style>

import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import * as Sentry from '@sentry/vue';
import KSUID from 'ksuid-edge';

import type { ComputeProvider, UserSubscriptionItem } from '@clovyr/pollen';
import { getComputeProvider, isWNextProvider } from '@clovyr/pollen/compute/util';
import { newNostr } from '@clovyr/pollen/crypto/nostr';
import { VaultAPI } from '@clovyr/pollen/crypto/vault/api';
import { newVault } from '@clovyr/pollen/crypto/vault/new';
import { WNextAccessAPI } from '@clovyr/pollen/deployment/access';
import { WNextDNS } from '@clovyr/pollen/dns/wnext';
import { wNextHost } from '@clovyr/pollen/fixtures/hosts';
import type { Garden } from '@clovyr/pollen/garden';
import { newGarden } from '@clovyr/pollen/garden/impl';
import {
  CLOVYR_ACCESS_KEY,
  deploymentAppID,
  type Manifest,
  type Metadata,
} from '@clovyr/pollen/manifest';
import { AuthState, emptyState, newPollen, type PollenState } from '@clovyr/pollen/pollen';
import { newStorageMigrator } from '@clovyr/pollen/pollen/migrations';
import { MockWebStorage } from '@clovyr/pollen/tests/pollen/mock-web-storage';
import {
  type ComputeProviderConfig,
  type ComputeProviderInfo,
  type Deployment,
  type DeploymentKeys,
  DeploymentState,
  type Instance,
} from '@clovyr/pollen/types';
import { UserAPI } from '@clovyr/pollen/user';
import { retry } from '@clovyr/pollen/util/retry';
import { snakeCaseToHuman } from '@clovyr/pollen/util/string';

import { usePayments } from '@/composables/usePayments';

import { config } from '../init/config';

import { useDeploymentStore } from './deployment_store';
import { useInstanceStore } from './instance_store';
import { FeatureFlag, useUserFlagsStore } from './user_flags';
import { persist, rehydrate } from './util';

export const usePollenStore = defineStore('pollen', () => {
  const userFlagStore = useUserFlagsStore();

  const state: Ref<PollenState> = ref(emptyState());
  const computeProviderConfigs = computed(() => Object.values(state.value.computeProviderConfigs));
  const deployments = computed(() => Object.values(state.value.deployments));
  const instances = computed(() => Object.values(state.value.instances));

  const email = computed(() => state.value.authenticatedUser?.email);
  const isUserAuthenticated = computed(() => !!state.value.authenticatedUser);
  const authState = computed(() => state.value.authState);
  const authType = computed(() => state.value.authType);

  const isLocked = computed(() => authState.value === AuthState.Locked);
  const isLoggedInWithoutSecret = computed(
    () => isUserAuthenticated.value && authState.value === AuthState.LoggedInWithoutSecretKey,
  );
  const isVaultLocked = computed(() => {
    if (isUserAuthenticated.value) {
      return isLocked.value || isLoggedInWithoutSecret.value;
    }
    return false;
  });

  const registryKeys = computed(() => state.value.registryKeys);

  const publishersLoaded = computed(() => state.value.publisherState.initialLoadComplete);
  const publishers = computed(() => state.value.publisherState.publishers);
  const publisherApps = computed(() => state.value.publisherState.publisherApps);
  const publisherAppVersions = computed(() => state.value.publisherState.publisherAppVersions);
  const isPublisher = computed(() => publishers.value && publishers.value.length > 0);

  const secretKey = computed(() => state.value.secretKey);
  const formattedSecretKey = computed(() => {
    if (state.value.secretKey === undefined) {
      return undefined;
    }

    const columnSizes = [4, 5, 4];

    const lines: string[] = [];
    let offset = 0;
    while (offset < 52) {
      const line: string[] = [];
      for (let i = 0; i < columnSizes.length; i++) {
        line.push(state.value.secretKey.slice(offset, offset + columnSizes[i]));
        offset += columnSizes[i];
      }
      lines.push(line.join(' '));
    }

    return lines.join('\n');
  });

  const garden = ref<Garden>(
    newGarden(config.VITE_GARDEN_URL, userFlagStore.userHasFeatureFlag(FeatureFlag.Internal)),
  );

  const storage = import.meta.env.SSR ? new MockWebStorage() : window.localStorage;

  const randomID = () => KSUID.randomSync().string;
  const pollen = newPollen(
    () =>
      newVault(storage, new UserAPI(), new VaultAPI(), newNostr(), newStorageMigrator(randomID)),
    (newState) => {
      state.value = newState;
      if (newState.authenticatedUser?.email) {
        Sentry.setUser({ email: newState.authenticatedUser.email });
      }
      Sentry.setTag('auth_type', newState.authType);
    },
    randomID,
  );

  if (!import.meta.env.SSR) {
    window.addEventListener('storage', pollen.handleStorageEvent);
  }

  const getDeploymentByID = (id: string): Deployment | undefined => {
    return deployments.value.find((d) => d.id === id);
  };

  const getDeploymentByInstanceName = (instanceName: string): Deployment | undefined => {
    return deployments.value.find((d) => d.instanceName === instanceName);
  };

  /**
   * Get the app manifest for the given deployment
   * @param deployment
   * @returns
   */
  const getDeploymentApp = (deployment: Deployment | string): Manifest | undefined => {
    const dep = typeof deployment === 'string' ? getDeploymentByID(deployment) : deployment;
    if (!dep) {
      return undefined;
    }
    return garden.value.getAppByID(deploymentAppID(dep));
  };

  const getInstanceByFQDN = (fqdn: string): Instance | undefined => {
    return instances.value.find((i) => i.fqdn === fqdn);
  };

  const sortedDeployments = computed(() => {
    const alphaSort = deployments.value.sort((a, b) => {
      const firstInstance = a.instanceName.toUpperCase();
      const secondInstance = b.instanceName.toUpperCase();

      if (firstInstance < secondInstance) {
        return -1;
      }

      if (firstInstance > secondInstance) {
        return 1;
      }

      return 0;
    });

    // return pinned instances at the top

    return alphaSort.sort((a, b) => Number(b.isPinned) - Number(a.isPinned));
  });

  /**
   * Create a new Deployment
   *
   * ID is guaranteed to be unique within the user's library and will not change
   * due to renames.
   *
   * @param appMetadata
   * @param host
   * @returns
   */
  function createDeployment(appMetadata: Metadata, host: ComputeProviderInfo): Deployment {
    let { id } = appMetadata;
    if (getDeploymentByID(id)) {
      // already exists, incr
      let i = 1;
      while (getDeploymentByID(id)) {
        i++;
        id = `${appMetadata.id}-${i}`;
      }
    }

    return {
      id,
      accessList: [],
      addons: [],
      publisherID: appMetadata.publisher,
      appID: appMetadata.id,
      appName: appMetadata.name,
      appVersion: appMetadata.version,
      appSettings: {},
      createdAt: Date.now(),
      dnsProvider: 'wnext',
      hostingProvider: host.id,
      instanceName: appMetadata.name,
      keys: {} as DeploymentKeys,
      state: DeploymentState.Created,
      isPinned: false,
      isUpgradable: false,
      isResumable: true,
    };
  }

  /**
   * Get the stored compute provider config
   *
   * @param input config ID or deployment
   * @returns
   */
  function getComputeProviderConfig(input: string | Deployment): ComputeProviderConfig | undefined {
    let id: string | undefined;
    if (typeof input === 'string') {
      id = input;
    } else {
      id = input.computeProviderConfigID;
    }
    if (!id) {
      return undefined;
    }

    return computeProviderConfigs.value.find((c) => c.id === id);
  }

  /**
   * Get a configured compute provider instance for the given deployment
   *
   * @param deployment
   * @returns
   */
  function getComputeProviderForDeployment(deployment: Deployment): ComputeProvider {
    const provider = getComputeProvider(deployment.hostingProvider);
    const computeConfig = getComputeProviderConfig(deployment);
    if (computeConfig) {
      provider.setConfig(computeConfig);
    }
    return provider;
  }

  /**
   * Destroy the instance and DNS associated with the given deployment
   * @param deployment
   */
  async function destroyInstance(deployment: Deployment): Promise<void> {
    // delete the instance also, if avail
    try {
      await retry(async () => {
        const provider = getComputeProviderForDeployment(deployment);
        if (isWNextProvider(provider)) {
          // workaround for issue with shared deployments
          // if the user we shared the deployment with resumes it, we may not have the instance in our
          // local store. in that case, we need to destroy it via fqdn here
          await provider.destroyInstance({ id: deployment.fqdn!, region: deployment.region });
        } else {
          const instance = instances.value.find((i) => i.fqdn === deployment.fqdn);
          if (instance) {
            let id = instance.remoteinstanceid!;
            if (isWNextProvider(provider)) {
              id = instance.fqdn!;
            }
            await provider.destroyInstance({ id, region: instance.instance_region });
          }
        }
      });
      await pollen.deleteInstance(deployment.fqdn!);
    } catch (e) {
      console.warn('failed to delete instance', e);
      Sentry.captureException(e);
    }

    // delete additional hostnames
    try {
      await retry(async () => {
        const app = garden.value.getAppByID(deployment.appID);
        if (app?.deployment?.dns?.additional_hosts) {
          await Promise.all([
            new WNextDNS().destroyRecord({ name: deployment.fqdn }),
            ...Object.entries(app.deployment.dns.additional_hosts).map(async ([extraHost]) => {
              const name = `${extraHost}-${deployment.fqdn}`;
              return new WNextDNS().destroyRecord({ name });
            }),
          ]);
        } else {
          await new WNextDNS().destroyRecord({ name: deployment.fqdn });
        }
      });
    } catch (e) {
      console.warn('failed to delete dns records', e);
      Sentry.captureException(e);
    }
  }

  /**
   * Delete a deployment (destroys the underlying compute instance as well)
   *
   * @param deployment
   */
  async function deleteDeployment(deployment: Deployment): Promise<void> {
    // remove from store
    // TODO: use deletedAt field
    await pollen.deleteDeployment(deployment.id);

    await destroyInstance(deployment);

    if (typeof deployment.appSettings[CLOVYR_ACCESS_KEY] === 'string') {
      await new WNextAccessAPI().deleteAccessKey({
        accessKey: deployment.appSettings[CLOVYR_ACCESS_KEY],
      });
    }

    // also cancel subscription immediately, if exists
    try {
      await retry(async () => {
        const payments = usePayments();
        if (payments.subscription.value && deployment.subscriptionItemID) {
          // cancel immediately
          await payments.cancelSubscriptionItem(
            {
              id: deployment.subscriptionItemID,
              subscription_id: payments.subscription.value.id,
            } as UserSubscriptionItem,
            'now',
          );
          // refresh item state
          await payments.getSubscriptionItem(
            payments.subscription.value.id,
            deployment.subscriptionItemID,
            true,
          );
        }
      });
    } catch (e) {
      console.warn('failed to cancel subscription', e);
      Sentry.captureException(e);
    }
  }

  type MigrationState = {
    migrated: boolean;
  };

  async function migrateCodeDeployments(): Promise<void> {
    const codeMigrationStateKey = 'clovyr/ui/code-migration';
    const migrationState: MigrationState | null = rehydrate(codeMigrationStateKey);
    if (migrationState && migrationState.migrated) {
      // already migrated, don't try again
      return;
    }

    // load deployments from API
    const deploymentStore = useDeploymentStore();
    await deploymentStore.loadDeployments();
    if (!deploymentStore.list?.length) {
      // mark as migrated if nothing to do
      persist<MigrationState>(codeMigrationStateKey, { migrated: true });
      return;
    }

    // get code app from garden
    const app = garden.value.getAppByID('clovyr-code');
    if (!app) {
      // just warn, maybe we can try again later
      console.warn('failed to find clovyr-code app in garden');
      return;
    }

    // migrate
    const instanceStore = useInstanceStore();
    deploymentStore.list.forEach(async (d) => {
      const instance = await instanceStore.fetchByFQDN(d.lastInstanceFQDN);
      if (instance && instance.fqdn) {
        const dep = createDeployment(app.metadata, wNextHost);
        dep.fqdn = d.lastInstanceFQDN;
        dep.id = d.name;
        dep.instanceName = snakeCaseToHuman(d.name);
        dep.state = DeploymentState.Running;
        await pollen.putDeployment(dep);
        await pollen.putInstance(instance);
      }
    });

    persist(codeMigrationStateKey, { migrated: true });
  }

  function setGarden(g: Garden) {
    garden.value = g;
  }

  return {
    garden,
    computeProviderConfigs,
    deployments,
    sortedDeployments,
    instances,
    pollen,

    isUserAuthenticated,
    authState,
    authType,
    isLocked,
    isLoggedInWithoutSecret,
    isVaultLocked,
    email,
    secretKey,
    formattedSecretKey,

    registryKeys,

    publishers,
    publisherApps,
    publisherAppVersions,
    isPublisher,
    publishersLoaded,

    setGarden,

    createDeployment,
    deleteDeployment,
    destroyInstance,
    getDeploymentByID,
    getDeploymentByInstanceName,
    getDeploymentApp,
    getInstanceByFQDN,

    getComputeProvider: getComputeProviderForDeployment,
    getComputeProviderConfig,

    migrateCodeDeployments,
  };
});

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';

import CloseIcon from '@/components/icons/CloseIcon.vue';
import useBodyScrollLock from '@/composables/useBodyScrollLock';

const props = defineProps({
  theme: {
    type: String,
    default: 'base',
  },
});

const sideDrawer = ref<HTMLElement | null>(null);
const theme = toRef(props, 'theme');
const classNames = computed(() => ['side-drawer__outer', `modal--${theme.value}`]);

const emit = defineEmits(['close']);

const handleClose = () => {
  emit('close');
};

onMounted(() => {
  if (sideDrawer.value) {
    void useBodyScrollLock(sideDrawer.value as HTMLElement);
  }
});
</script>

<template>
  <div class="side-drawer" ref="sideDrawer">
    <div @click="handleClose()" :class="classNames" />

    <div class="side-drawer__inner">
      <slot />

      <div class="side-drawer__close">
        <Button
          :shape="ButtonShape.Circle"
          :transparent="true"
          @click="handleClose"
          class="button--freeform"
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$drawer-free-space: calc(100vw - var(--sidebar-width-open));
$vert-padding: space(7.2);
$horiz-padding: col-width(1);

.side-drawer {
  position: absolute;
  left: 100%;
  width: 100vw;
  height: 100%;

  @include media-breakpoint-up(md) {
    width: $drawer-free-space;
  }

  &__outer {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__inner {
    @include disable-scrollbars;

    position: relative;
    z-index: 2;
    width: 100%;
    max-width: space(102);
    height: 100%;
    padding: $vert-padding $horiz-padding;
    overflow: auto;
    background-color: color(background);

    @include media-breakpoint-up(xl) {
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }

  &__close {
    position: absolute;
    top: calc(#{$vert-padding} - #{space(1.3)});
    right: calc(#{$horiz-padding} - #{space(0.8)});

    .button {
      background-color: transparent;
      stroke: color(grey, secondary);
    }
  }
}
</style>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';

import { ImageMedia } from '@clovyr/bed';
import ClovyrIcon from '@clovyr/bed/assets/images/clovyr-icon.svg';
import GreenCheck from '@clovyr/bed/assets/images/green-check.svg';
import RedX from '@clovyr/bed/assets/images/red-x.svg';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import { DeploymentAddon } from '@clovyr/pollen/types/Deployment';

import CheckboxBlock from '@/components/blocks/CheckboxBlock.vue';
import { useLauncherStore } from '@/stores/launcher_store';

type Props = {
  isReview?: boolean;
};
const props = defineProps<Props>();

const launcherStore = useLauncherStore();
const { toggleAddon } = launcherStore;
const { deployment } = storeToRefs(launcherStore);

const isBackupsSelected = computed(() =>
  deployment.value.addons?.includes(DeploymentAddon.Backups),
);

function onBackupInput(checked: boolean) {
  toggleAddon(DeploymentAddon.Backups, checked);
}
</script>

<template>
  <div class="add-on-block">
    <div class="setup-controls-heading launch-heading--secondary">Add ons by Clovyr:</div>
    <div class="section-highlighted background-gradient--base">
      <div class="backup-block__top">
        <AppLogo :logo="{ logoUrl: ClovyrIcon }" size="medium" />
        <div class="backup-block__text text--h4">By Clovyr</div>
      </div>
      <div v-if="!props.isReview" class="check-block">
        <CheckboxBlock
          :value="isBackupsSelected"
          type="checkbox"
          shape="rounded"
          title="Pay $5/mo for Clovyr's backup service"
          @update:modelValue="onBackupInput"
        />
      </div>
      <div v-if="props.isReview">
        <div v-if="isBackupsSelected" class="completed-item">
          Pay $5/mo for Clovyr's backup service
          <ImageMedia :filename="GreenCheck" class="completed-item__check" />
        </div>
        <div v-if="!isBackupsSelected" class="completed-item">
          Pay $5/mo for Clovyr's backup service
          <ImageMedia :filename="RedX" class="completed-item__check" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.add-on-block {
  .setup-controls-heading {
    @include text--h4;

    margin: 0 0 0.5rem 0.5rem;
    color: color(white);
  }

  .backup-block {
    &__top {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-bottom: space(2);
    }

    &__text {
      margin-left: space(2);
    }
  }

  .completed-item {
    display: flex;
    justify-content: space-between;
    padding: space(1.8) space(2.4);
    border: 1px solid rgb(132 115 152 / 30%);

    @include borders-rounded;
  }
}
</style>

<script setup lang="ts">
import Amex from '@clovyr/bed/assets/images/Amex.svg';
import ClovyrHost from '@clovyr/bed/assets/images/clovyrhost-lockup.svg';
import ComingSoon from '@clovyr/bed/assets/images/coming-soon.svg';
import DinersClub from '@clovyr/bed/assets/images/DinersClub.svg';
import Mastercard from '@clovyr/bed/assets/images/Mastercard.svg';
import Opennode from '@clovyr/bed/assets/images/opennode.svg';
import Visa from '@clovyr/bed/assets/images/Visa.svg';
import { useMatomo } from '@clovyr/bed/composables/useMatomo';
import type { UserSubscription, UserSubscriptionItem } from '@clovyr/pollen';
import type { PaymentMethod } from '@clovyr/pollen/subscription';

import Modal from '@/components/elements/Modal.vue';
import { useAppResume } from '@/composables/useAppResume';
import { useAppStatus } from '@/composables/useAppStatus';
import { useUserFlagsStore } from '@/stores/user_flags';

interface AppSubscriptionProps {
  id: string;
  name: string;
  subscription?: UserSubscription;
  subscriptionItem?: UserSubscriptionItem;
}

const props = defineProps<AppSubscriptionProps>();
const deploymentID = toRef(props, 'id');

const userFlags = useUserFlagsStore();
const { isInternal } = userFlags;

// Upgrade subscription - same as clicking 'upgrade' on app detail
const handleSubscriptionAddPayment = async (paymentMethod: PaymentMethod) => {
  if (!props.id) {
    // not an app subscription trial which can be upgraded
    return;
  }
  // matomo track event Payment Initiated
  const matomo = useMatomo();
  matomo.trackEvent('Payment', 'selectPayment', paymentMethod);
  const { doUpgrade } = useAppStatus(deploymentID);
  if (
    await doUpgrade(deploymentID.value, props.subscription!, props.subscriptionItem!, paymentMethod)
  ) {
    void useAppResume(deploymentID).wakeUpInstance();
  }
};
</script>

<template>
  <Modal class="sub-modal" theme="transparent" size="narrow" @close="$emit('close')" max-on-mobile>
    <template #body>
      <div class="upgrade-heading">
        <h2>Upgrade</h2>
        <ImageMedia :filename="ClovyrHost" />
      </div>
      <div class="upgrade-body">
        To upgrade hosting for {{ name }}, select a payment method below.
        <br />
        Your account will be charged at the end of the free trial.
      </div>

      <div class="payment-options">
        <div class="stripe" @click="handleSubscriptionAddPayment('stripe')">
          <div class="stripe__images">
            <div class="stripe__images-cards">
              <ImageMedia :filename="Mastercard" />
              <ImageMedia :filename="Amex" />
            </div>
            <div class="stripe__images-cards">
              <ImageMedia :filename="DinersClub" />
              <ImageMedia :filename="Visa" />
            </div>
          </div>
          <div class="stripe__text">
            <p class="stripe__heading">Pay via Stripe</p>
            <h4 class="stripe__sub-heading">Credit Card</h4>
          </div>
        </div>
        <div
          class="open-node"
          @click="handleSubscriptionAddPayment('opennode')"
          :class="{ disabled: !isInternal }"
        >
          <div class="open-node__images"><ImageMedia :filename="Opennode" /></div>
          <div class="open-node_text">
            <p class="open-node__heading">Pay via Opennode</p>
            <h4 class="open-node__sub-heading">BTC</h4>
          </div>
          <ImageMedia v-if="!isInternal" class="coming-soon" :filename="ComingSoon" />
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.upgrade-heading {
  display: flex;
  align-items: center;
  margin-top: 25%;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    margin-top: 1rem;
  }

  h2 {
    color: #ffffff;
    margin-right: 0.75rem;
  }
}
.upgrade-body {
  font-size: 19px;
  margin-bottom: 3rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 2rem;
  }
}

.payment-options {
  width: fit-content;
  margin: auto;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}

.stripe,
.open-node {
  height: 7rem;
  width: 350px;
  display: flex;
  padding: 8px 36px;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 18px;
  border: 1px solid color(grey, secondary);
  background: color(negative, primary);

  @include media-breakpoint-up(md) {
    width: 300px;
    height: 6rem;
    justify-content: flex-start;
  }

  &__images {
    padding: 0.25rem;
  }
  &__text {
    margin-right: 0.5rem;
  }
}

.stripe {
  &__images {
    margin-right: 0.5rem;
  }
  &__images-cards {
    display: flex;

    .image-media:first-child {
      margin-right: 0.15rem;
    }
  }
  &__images-cards:first-child {
    margin-bottom: 0.25rem;
  }
  &__heading {
    font-size: 13px;
  }
}

.stripe:hover,
.open-node:hover {
  cursor: pointer;
}

.stripe:hover,
.open-node:hover:not(.disabled) {
  background-color: color(grey, primary);
  border: 1px solid color(grey, primary);
}

.open-node {
  position: relative;
  &__heading {
    font-size: 13px;
  }
  &.disabled {
    opacity: 0.4;
    background: var(--Midnight, #221a33);
  }
}

.coming-soon {
  position: absolute;
  right: -22px;
  bottom: -5px;
}
</style>

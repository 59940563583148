import { type Ref } from 'vue';

import { ComputeProviderName, DeploymentState } from '@clovyr/pollen';
import { getWNextPricing } from '@clovyr/pollen/fixtures/pricing';
import { isClovyrCode } from '@clovyr/pollen/garden';
import { type PaymentMethod, SubscriptionStatus } from '@clovyr/pollen/subscription';
import type { UserSubscription } from '@clovyr/pollen/types/UserSubscription';
import type { UserSubscriptionItem } from '@clovyr/pollen/types/UserSubscriptionItem';
import { snakeCaseToHuman } from '@clovyr/pollen/util/string';

import { usePollenStore } from '@/stores/pollen_store';
import { persist } from '@/stores/util';

import { useAppVersion } from './useAppVersion';
import { PaymentCallbackStateKey, usePayments } from './usePayments';

export enum AppStatus {
  Unknown = 'unknown',
  LaunchFailed = 'launch-failed',
  Launching = 'launching',
  TrialExpiring = 'trial-expiring',
  TrialExpired = 'trial-expired',
  Running = 'running',
  Sleeping = 'sleeping',
  Waking = 'waking',
  UpdateAvailable = 'update-available',
  Updating = 'updating',
  Restoring = 'restoring',
}

export function useAppStatus(deploymentID: Ref<string>) {
  const payments = usePayments();
  const pollenStore = usePollenStore();

  const { isUpdateAvailable } = useAppVersion(deploymentID);
  const { subscription: sub, subscriptionItems } = payments;

  const deployment = computed(() => {
    return pollenStore.getDeploymentByID(deploymentID.value)!;
  });

  const app = computed(() => {
    return pollenStore.getDeploymentApp(deployment.value)!;
  });

  const metadata = computed(() => {
    if (app.value) {
      return app.value.metadata;
    }
    return undefined;
  });

  const subItem = computed(() => {
    if (!deployment.value?.subscriptionItemID) {
      return undefined;
    }
    return subscriptionItems.value[deployment.value.subscriptionItemID];
  });

  async function setDeploymentState(state: DeploymentState) {
    deployment.value.state = state;
    await pollenStore.pollen.putDeployment(deployment.value);
  }

  /**
   * Whether or not the app is hosted on Clovyr Hosting (wNext)
   */
  const isWNext = computed(() => deployment.value?.hostingProvider === ComputeProviderName.WNext);

  /**
   * The WNext-specific price for the selected app
   */
  const wnextAppPrice = computed<string>(() => {
    if (!(deployment.value && app.value)) {
      // default to $20 if not found for whatever reason
      return '20';
    }
    return getWNextPricing(
      deployment.value.appID,
      app.value.deployment?.compute?.size,
    )[1].toString();
  });

  /**
   * Whether or not the app is a Clovyr Code instance
   */
  const isCode = computed(() => isClovyrCode(deployment.value.appID));

  /**
   * Active subscription
   */
  const isAppSubscribed = computed(() => subItem.value?.status === 'active');

  const isLaunchFailed = computed(() => deployment.value?.state === DeploymentState.LaunchFailed);

  /**
   * App is currently running
   */
  const isRunning = computed(() => deployment.value.state === DeploymentState.Running);

  /**
   * App is being updated (usually to a newer version, could also be an older one for rollback)
   */
  const isUpdating = computed(() => deployment.value.state === DeploymentState.Updating);

  /**
   * App is currently launching (computed from a more specific underlying status)
   */
  const isLaunching = computed(() => {
    const s = deployment.value?.state;
    if (!s) {
      return false;
    }
    return (
      s === DeploymentState.LaunchingInstance ||
      s === DeploymentState.CreatingDNS ||
      s === DeploymentState.WaitingInstance ||
      s === DeploymentState.LaunchedInstance ||
      s === DeploymentState.UpdatingDNS ||
      s === DeploymentState.Deploying
    );
  });
  const isSleeping = computed(() => deployment.value.state === DeploymentState.Sleeping);
  const isWaking = computed(() => deployment.value.state === DeploymentState.Waking);

  // const isSuspended = computed(() => (deployment.value.state !== DeploymentState.Running) && (deployment.value.state !== DeploymentState.Sleeping) && trialEnd);

  // placeholders
  const isRestoring = ref(false);

  /**
   * Time the trial ends at, if avail
   */
  const trialEnd = computed(() => {
    if (!subItem.value || !subItem.value.trial_end_at) {
      return undefined;
    }
    return subItem.value.trial_end_at.valueOf();
  });

  /**
   * Subscription is in trial if status is either Trial or PaymentInProgress.
   */
  const isTrial = computed(() => {
    if (!subItem.value) {
      return false;
    }
    return (
      subItem.value.status === SubscriptionStatus.Trial ||
      subItem.value.status === SubscriptionStatus.PaymentInProgress
    );
  });

  /**
   * Subscription item is expired (instance has been shutdown, pending payment)
   */
  const isExpired = computed(
    () =>
      subItem.value?.status === SubscriptionStatus.Expired ||
      (isSleeping.value && subItem.value?.status === SubscriptionStatus.PaymentInProgress),
  );
  const isSuspended = isExpired; // simple alias for now TODO: remove this?

  /**
   * Get a friendly "time remaining" string in terms of the number of days.
   * It is not precise, but gives a rough estimate.
   */
  const trialTimeRemaining = computed(() => {
    if (subItem.value) {
      if (!isTrial.value || !trialEnd.value) {
        // not in trial
        return '';
      }
      const secondsLeft = (trialEnd.value - Date.now()) / 1000;
      if (secondsLeft < 0) {
        return '0 days';
      }
      const daysLeft = secondsLeft / 86400;
      if (daysLeft >= 2) {
        return `${Math.round(daysLeft)} days`;
      }
      if (daysLeft >= 1) {
        return 'about 1 day';
      }
      return 'less than 1 day';
    }
    return '7 days';
  });

  /**
   * Returns true if the trial period has expired.
   */
  const trialExpired = computed(() => {
    return (
      (subItem.value?.trial_end_at && Date.now() > subItem.value.trial_end_at.valueOf()) || false
    );
  });

  const appStatus = computed<AppStatus>(() => {
    if (!deployment.value) {
      return AppStatus.Unknown;
    }
    if (isLaunchFailed.value) {
      return AppStatus.LaunchFailed;
    }
    if (isLaunching.value) {
      return AppStatus.Launching;
    }
    if (isRunning.value && trialExpired.value && !isAppSubscribed.value) {
      return AppStatus.TrialExpiring;
    }
    if (isExpired.value) {
      return AppStatus.TrialExpired;
    }
    if (isUpdateAvailable.value) {
      return AppStatus.UpdateAvailable;
    }
    if (isUpdating.value) {
      return AppStatus.Updating;
    }
    if (isRestoring.value) {
      return AppStatus.Restoring;
    }
    if (isSleeping.value) {
      return AppStatus.Sleeping;
    }
    if (isWaking.value) {
      return AppStatus.Waking;
    }
    if (isRunning.value) {
      return AppStatus.Running;
    }
    return AppStatus.Unknown;
  });

  const appStatusLabel = computed(() => {
    return snakeCaseToHuman(appStatus.value);
  });

  const fetchSubItem = async () => {
    if (sub.value && deployment.value?.subscriptionItemID && !subItem.value) {
      try {
        await payments.getSubscriptionItem(sub.value.id, deployment.value.subscriptionItemID, true);
      } catch (e) {
        console.warn('failed to fetch subscription info:', e);
        console.warn(
          "(this may be because you do not have permission to manage a shared app's subscription",
        );
      }
    }
  };

  /**
   * Upgrade the given subscription item and capture payment if necessary.
   *
   * @param deploymentID
   * @param sub
   * @param subItem
   * @param paymentMethod
   *
   * @returns true if the upgrade was successful, false if it was not (due to redirect)
   */
  /* eslint-disable @typescript-eslint/no-shadow */
  const doUpgrade = async (
    deploymentID: string,
    sub: UserSubscription,
    subItem: UserSubscriptionItem,
    paymentMethod: PaymentMethod,
  ): Promise<boolean> => {
    if (!(subItem && sub)) {
      return false;
    }

    if (!sub.payment_captured_at) {
      await payments.capturePayment(paymentMethod, subItem, {
        deploymentID,
        subscriptionItemID: subItem.id,
        successURL: `/library/${deploymentID}`,
        errorURL: `/library/${deploymentID}`,
        cancelURL: `/library/${deploymentID}`,
      });
      return false; // won't actually reach here due to redirect
    }

    const res = await payments.upgradeSubscriptionItem(subItem);
    if (res.invoice) {
      // send to invoice screen
      persist(PaymentCallbackStateKey, {
        deploymentID,
        subscriptionItemID: subItem.id,
        invoiceID: res.invoice.id,
      });
      window.location.hash = `#settings/invoices/${res.invoice.id}`;
      return false; // won't actually reach here due to redirect
    }

    // only reaches here when upgrading via stripe payment (which is instant)
    await payments.getSubscriptionItem(sub.id, subItem.id, true);

    // // wake up instance if needed
    // await wakeUpInstance();
    return true;
  };
  /* eslint-enable @typescript-eslint/no-shadow */

  // fetch subscription info on load, if we don't have it
  void fetchSubItem();
  watch(deploymentID, async () => {
    void fetchSubItem();
  });

  return {
    deployment,
    app,
    metadata,
    sub,
    subItem,
    isAppSubscribed,

    setDeploymentState,
    refreshSubItem: fetchSubItem,
    doUpgrade,

    isClovyrCode: isCode,

    appStatus,
    appStatusLabel,

    isLaunchFailed,
    isRunning,
    isLaunching,
    isSleeping,
    isWaking,

    isUpdating,
    isRestoring,
    isSuspended,
    isTrial,
    isExpired,

    isWNext,
    trialTimeRemaining,
    trialExpired,
    wnextAppPrice,
  };
}

<script setup lang="ts">
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import { capitalize } from '@clovyr/pollen/util/string';

import AppState from '../../components/elements/AppState.vue';
import EventBubble from '../../components/elements/EventBubble.vue';
import { useAppEvents } from '../../composables/useAppEvents';
import { useAppStatus } from '../../composables/useAppStatus';

type LibraryItemProps = {
  id: string;

  /**
   * Check if sidebar is open or closed
   */
  closed: boolean;

  /**
   * Check if library is toggled on or not
   */
  libraryToggled: boolean;
};

const props = defineProps<LibraryItemProps>();
const deploymentID = toRef(props, 'id');
const { deployment } = useAppStatus(deploymentID);
const { appEventCount } = useAppEvents(deploymentID);

const { appStatusLabel } = useAppStatus(deploymentID);

const trimmedNames = computed(() => {
  if (deployment.value.instanceName.length > 20) {
    return `${deployment.value.instanceName.slice(0, 15)}...`;
  }
  return deployment.value.instanceName;
});

const route = useRoute();
const isActive = computed(() => {
  return {
    'router-link-active': route.path.startsWith(`/library/${deployment.value.appName}`),
  };
});
</script>

<template>
  <li
    class="app-library-item"
    :class="[
      appEventCount ? 'app-library-item__display-count' : 'app-library-item__hide-count',
      isActive,
    ]"
  >
    <span :class="[closed ? 'app-library-item__status-closed' : 'app-library-item__status-open']">
      <EventBubble v-if="appEventCount && !closed" :count="appEventCount" />
    </span>

    <Tooltip :disabled="!closed" placement="right" arrow theme="narrow-fixed" offsetDistance="10">
      <c-link
        :to="'/library/' + deployment.id"
        :label="closed ? deployment.instanceName.slice(0, 3) : trimmedNames"
        class="app-library-item__title-abbreviated"
      />
      <template #content>
        <div class="app-library-item__title">
          <h4>{{ deployment.instanceName }}</h4>
        </div>

        <div class="app-library-item__app-status">
          <AppState :deployment="deployment" />

          <p>{{ appStatusLabel }}</p>
        </div>
        <div v-if="appEventCount" class="app-library-item__app-events">
          <EventBubble v-if="appEventCount" :count="appEventCount" />
          <p>New events</p>
        </div>
      </template>
    </Tooltip>
  </li>
</template>

<style scoped lang="scss">
.app-library-item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: color(grey, secondary);
  text-align: center;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    width: var(--sidebar-current-width);
  }

  &:hover {
    color: color(white);
  }

  @at-root .sidebar--open#{&} {
    width: auto;
    text-align: left;
  }

  &:not(:first-child) {
    margin-top: space(1.4);
  }

  &__status-open {
    padding-top: 5px;
    width: 30px;
    height: 30px;
  }

  &__status {
    fill: color(action, secondary);
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__app-status {
    align-items: center;
    display: flex;

    > * {
      flex-shrink: 0;
    }
  }
  &__app-events {
    align-items: center;
    display: flex;

    > * {
      flex-shrink: 0;
    }
  }

  &__status-closed {
    @include position-absolute-center-vert;

    left: -1.6rem;
    padding: 3px 0 0 3px;
    fill: color(action, secondary);
    margin-left: space(1);
  }

  // &__hide-count {
  //   transform: translateX(-15px);
  // }

  &__title-abbreviated {
    @include text--caption;

    text-overflow: ellipsis;
    line-height: 1.2;
    padding-left: space(0.75) !important;
    overflow: hidden;
    white-space: nowrap;
  }
}

:deep(.popper) {
  width: max-content;

  > * {
    margin: 0.75rem 0;

    > * {
      margin: 0 0.25rem;
    }
  }

  .event-bubble {
    &__circle {
      display: flex;
      justify-content: center;
      margin-right: 5px;
    }
  }
}
.router-link-active {
  color: color(action, primary);
}

.app-library-item__title {
  text-align: left;
}
</style>

<template>
  <BaseSvg class="arrow-forwards-icon" viewBox="0 0 20 20" fill="none">
    <path
      d="M2.5 10L9.16666 10"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 4.1665L17.5 9.99984L10 15.8332L10 4.1665Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'ArrowForwardsIcon',
  components: {
    BaseSvg,
  },
};
</script>

<style scoped lang="scss">
.arrow-forwards-icon {
  @include size(rem(20), rem(20));
}
</style>

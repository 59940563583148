<script setup lang="ts">
import { DeploymentMethod } from '@clovyr/pollen/manifest';
import { getBuilderForManifest } from '@clovyr/pollen/manifest/configure';
import { writeAllFilesBase64 } from '@clovyr/pollen/manifest/configure/util';
import { execWithOutput } from '@clovyr/pollen/remote_exec';
import { sleep } from '@clovyr/pollen/util/sleep';

import Module from '@/components/elements/Module.vue';
import AppConfiguration from '@/components/forms/AppConfiguration.vue';
import { usePollenStore } from '@/stores/pollen_store';

import {
  addAnswers,
  advancedSettings,
  answers,
  basicSettings,
  deploySettings,
  hasAllValidSettings,
  hasSettings,
  initSettings,
  type LaunchConfigSetting,
  setAnswer,
} from '../../stores/launch_settings';

interface LibraryAppDetail {
  id: string;
}

const props = defineProps<LibraryAppDetail>();

const pollenStore = usePollenStore();

const deployment = computed(() => {
  return pollenStore.getDeploymentByID(props.id)!;
});

const manifest = computed(() => {
  return pollenStore.getDeploymentApp(deployment.value)!;
});

const forceDisableUpgradeButton = ref(false);
const statusText = ref('');
let unwatch: () => void;
const settingsChanged = ref(false);

function copySettingValues(settings: LaunchConfigSetting[]) {
  settings.forEach((setting) => {
    const key = setting.id.substring(setting.id.indexOf('.') + 1);
    if (deployment.value.fqdn && key in deployment.value.appSettings) {
      setting.answer = deployment.value.appSettings[key];
    }
  });
}

function copyAllSettingValues() {
  // reset state
  if (unwatch) {
    unwatch();
  }
  settingsChanged.value = false;
  initSettings(manifest.value);
  copySettingValues(basicSettings.value);
  copySettingValues(advancedSettings.value);
  copySettingValues(deploySettings.value);
  unwatch = watch(
    [answers],
    () => {
      // copy answers to settings
      Object.entries(answers.value).forEach(([answerID, answer]) => {
        const localSetting = basicSettings.value
          .concat(advancedSettings.value, deploySettings.value)
          .find((s) => s.id === answerID);

        if (localSetting) {
          localSetting.answer = answer.value;
          localSetting.isTouched = true;
          localSetting.errors = answer.errors;
        }
      });

      settingsChanged.value = true;
    },
    { deep: true },
  );
}

onUnmounted(() => {
  if (unwatch) {
    unwatch();
  }
  unwatch = () => {};
});

watch([manifest], () => {
  copyAllSettingValues();
});

onMounted(async () => {
  copyAllSettingValues();
});

const onClickDeployChanges = async () => {
  forceDisableUpgradeButton.value = true;
  statusText.value = 'Deploying new configuration...';

  await pollenStore.garden.loadDeploymentDetails(manifest.value);
  addAnswers(manifest.value, answers.value, deployment.value.appSettings);
  const builder = getBuilderForManifest(manifest.value, DeploymentMethod.DockerCompose);
  const [, , files] = builder.prepareConfigureData(manifest.value, deployment.value.appSettings);

  const reconfigureScript = `
pushd /opt/clovyr/apps/${manifest.value.metadata.id}
${writeAllFilesBase64(files)}
docker compose stop
docker compose up --quiet-pull --detach
  `;

  await execWithOutput(deployment.value.fqdn!, deployment.value.accessList[0], reconfigureScript);

  statusText.value = 'Done!';
  await sleep(2000);
  statusText.value = '';
  forceDisableUpgradeButton.value = false;
};

const appConfigHeading = computed(() => {
  if (hasSettings.value) {
    return 'Modifying the below configuration options are for advanced users only. Proceed at your own risk!';
  }
  return 'No configuration options available';
});

const disableDeployChanges = computed(() => {
  return (
    forceDisableUpgradeButton.value ||
    !hasSettings.value ||
    !settingsChanged.value ||
    !hasAllValidSettings.value
  );
});
</script>

<template>
  <Module hed="App Configuration">
    <template #default>
      <div class="setup-controls-heading text--color-warning">
        {{ appConfigHeading }}
      </div>
      <AppConfiguration
        :basic-settings="basicSettings"
        :advanced-settings="advancedSettings"
        :deploy-settings="deploySettings"
        show-deploy-config
        untoggled-button-text="show optional settings"
        toggled-button-text="hide optional settings"
        :set-answer="setAnswer"
        class="options-block"
      />
    </template>
    <template #ctas>
      <div v-if="!!statusText" class="status">{{ statusText }}</div>
      <Button
        label="Deploy changes"
        :disabled="disableDeployChanges"
        @click="onClickDeployChanges"
      ></Button>
    </template>
  </Module>
</template>

<style scoped lang="scss">
.status {
  margin-top: 8px;
  margin-right: 20px;
}
.setup-controls-heading {
  @include text--h4;

  margin-bottom: space(2.5);
}
:deep(.module__head__right) {
  display: none;
}
</style>

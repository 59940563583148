<template>
  <div class="settings-section">
    <Accordion :defaultClosed="defaultClosed">
      <template v-slot:heading-left>
        <div class="settings-section__heading">
          {{ headingText }}
        </div>

        <div class="settings-section__line" />
      </template>

      <template v-slot:content>
        <div class="settings-section__content">
          <slot name="content" />
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/blocks/Accordion.vue';

export default {
  name: 'SettingsSection',
  components: {
    Accordion,
  },
  props: {
    headingText: {
      type: String,
      default: '',
    },
    defaultClosed: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.settings-section {
  padding-bottom: space(3.5);

  &__heading {
    @include text--h3;
  }

  &__line {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 0 space(1.4);

    &::after {
      display: block;
      width: 100%;
      height: 1.5px;
      content: '';
      background-color: color(grey);
      opacity: 0.3;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: space(2) 0 space(4);
    color: color(grey, secondary);
  }
}
</style>

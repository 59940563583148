<template>
  <div class="tips">
    <div class="tips__content">
      <div class="tips__background"></div>

      <div v-if="$slots.heading" class="tip-heading">
        <slot name="heading" />
      </div>

      <div v-if="$slots.body" class="tip-body">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tips {
  background: linear-gradient(
    270deg,
    color(background) 16.5%,
    transparentize(color(background), 1) 100%
  );
  background-image: url(../../assets/images/huginn_talking.png);
  background-size: contain;
  background-position: left bottom;
  background-repeat: no-repeat;
  position: relative;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  background-color: color(background);
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding: 6rem;
  }

  &__background {
    position: absolute;
    top: 0;
  }

  &__content {
    @include z-index(above-arbitrary);

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(xl) {
      margin-left: 25%;
      margin-right: auto;
      max-width: 768px;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: auto;
    }
  }

  .tip-heading {
    @include text--mono;
    @include font-size(15);

    font-weight: 500;
    margin-bottom: space(1.5);
    color: color(grey, secondary);
  }

  .tip-body {
    @include font-size(16);

    font-family: $font-family-work;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.02rem;
    color: color(grey, secondary);
  }
}
</style>

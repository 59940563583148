<script lang="ts" setup>
import { computed } from 'vue';

/**
 * ideally this would be in types.ts alongside ButtonSize etc
 * but currently Vue3 can't use imported types in defineProps
 * see  https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md#type-only-propsemit-declarations
 */

// TODO: Rewrite style props as a singular theme/action prop

export type ButtonProps = {
  label?: string;
  reversed?: boolean;
  translucent?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  shape?: 'pill' | 'circle';
  size?: 'small' | 'medium' | 'large';
  iconPosition?: 'right' | 'left';
  link?: string;
  linkTarget?: string;
  linkRel?: string;
  ariaLabel?: string;
};

const props = withDefaults(defineProps<ButtonProps>(), {
  shape: 'pill',
  linkTarget: '_self',
  ariaLabel: undefined,
});

const isExternalLink = computed(() => {
  return props.link && props.link.startsWith('http');
});

const CSSClasses = computed(() => [
  props.reversed && `button--reversed`,
  props.translucent && 'button--translucent',
  props.disabled && 'button--disabled',
  `button--${props.shape}`,
  props.iconPosition && `button--icon-${props.iconPosition}`,
  props.size && `button--${props.size}`,
]);
</script>

<template>
  <a
    v-if="isExternalLink"
    :class="CSSClasses"
    :href="link"
    :target="linkTarget"
    :rel="linkRel"
    class="button"
  >
    <span class="button__label">{{ label }}</span>
    <slot />
  </a>
  <router-link
    v-else-if="!!link && !isExternalLink"
    :class="CSSClasses"
    :to="link"
    :target="linkTarget"
    class="button"
  >
    <span class="button__label">{{ label }}</span>
    <slot />
  </router-link>

  <button
    v-else
    :class="CSSClasses"
    class="button"
    type="button"
    :disabled="disabled"
    :aria-label="ariaLabel"
  >
    <span v-if="label" class="button__label">{{ label }}</span>
    <slot />
  </button>
</template>

<style lang="scss">
:root {
  --button-pad-top: #{space(0.8)};
  --button-pad-bottom: #{space(1)};
  --button-pad-horiz: #{space(1.9)};
  --button-pad-top-lg: #{space(2.5)};
  --button-pad-bottom-lg: #{space(2.6)};
  --button-pad-horiz-lg: #{space(4)};
  --button-icon-size: #{rem(20)};
}

@mixin button-colors-reversed($translucent) {
  background-color: var(--collection-text-secondary);

  @if $translucent {
    color: color.adjust(color(action), $alpha: -0.1);
    stroke: color.adjust(color(action), $alpha: -0.1);
  } @else {
    color: color(negative);
    fill: transparent;
    stroke: color(negative);
  }
}

@mixin button-colors($translucent) {
  @if $translucent {
    background-color: var(--collection-text-translucent);
  } @else {
    background-color: color(negative);
  }

  color: var(--collection-text-primary);
  fill: transparent;
  stroke: var(--collection-text-primary);
}

@mixin button-color-styles($reversed: false, $translucent: false) {
  @if $reversed {
    @include button-colors-reversed($translucent);

    &:hover {
      background-color: var(--collection-text-primary);
    }
  } @else {
    @include button-colors($translucent);

    &:hover {
      @include button-colors-reversed($translucent);

      background-color: var(--collection-text-primary);
    }
  }
}

.button {
  $self: &;

  --color-text: color(negative);

  @include text--mono;

  @include button-color-styles;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 0;
  padding: space(1.1) var(--button-pad-horiz) space(1.4);

  @include media-breakpoint-up(md) {
    min-height: space(4.5);
    padding: var(--button-pad-top) var(--button-pad-horiz) var(--button-pad-bottom);
  }

  &:not(:last-child) {
    margin-right: space(1);
  }

  img,
  svg {
    @include size(var(--button-icon-size), var(--button-icon-size));
  }

  br {
    @include show-on(phone);
  }

  &#{$self}--link {
    @include font-size(16);

    display: inline-flex;
  }

  &#{$self}--reversed {
    @include button-color-styles(true);
  }

  &#{$self}--circle {
    @include borders-sphere;

    width: space(4.5);
    height: space(4.5);

    svg,
    .image-media {
      @include position-absolute-center;

      width: 50%;
    }
  }

  &#{$self}--translucent {
    @include button-color-styles(false, true);

    &:hover {
      color: color(background);

      svg {
        path {
          stroke: color(background);
        }
      }
    }
  }

  &#{$self}--transparent {
    &.button {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &#{$self}--disabled,
  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
    stroke: currentcolor;
  }

  &.button--danger {
    color: color(bad, secondary);
    background-color: color.adjust(color(bad), $alpha: -0.9);

    &:hover {
      color: color(negative);
      background-color: color(bad);
    }

    &.button--reversed {
      color: color(negative);
      background-color: color(bad);

      &:hover {
        color: color(bad, secondary);
        background-color: color.adjust(color(bad), $alpha: -0.9);
      }
    }
  }

  &#{$self}--pill {
    @include borders-convex;
  }

  &#{$self}--small {
    height: calc(var(--space-relative) * 3);
    min-height: 0;
    @include font-size(12);

    svg {
      width: calc(var(--space-relative) * 1.5);
      height: calc(var(--space-relative) * 1.5);
    }
  }

  &#{$self}--large {
    padding: var(--button-pad-top-lg) var(--button-pad-horiz-lg) var(--button-pad-bottom-lg);

    @include media-query(phone) {
      padding: space(2) var(--button-pad-horiz-lg) space(2.2);
    }
  }

  &#{$self}--icon-left {
    @at-root .button--icon-left .button__label {
      order: 2;
      margin-left: space(0.6);
    }
  }

  &#{$self}--icon-right {
    @at-root .button--icon-right .button__label {
      margin-right: space(0.6);
    }
  }

  &#{$self}__label {
    display: flex;
    align-items: center;
    padding-top: 1px;
    text-align: center;
  }
}

.button-group {
  display: flex;
  align-items: flex-start;

  &.button-group-row {
    flex-direction: column;

    .button {
      &:not(:first-child) {
        margin-top: 0.75rem;
      }
    }
  }

  &.button-group-separator {
    padding-top: 1rem;
    border-top: 1px solid color(background);
  }
}

.button-inline {
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  &.button-primary {
    color: color(good, primary);
  }
  &.button-secondary {
    color: color(active, primary);
  }
}
</style>

export enum ShowAuthForm {
  Login = 'showLogin',
  Signup = 'showSignup',
  RecoveryKitDownload = 'showRecoveryKitDownload',
  SecretKeyForm = 'showSecretKeyForm',
  Locked = 'showLockedForm',
  ResendVerification = 'showResendVerification',
  VerificationComplete = 'showVerificationComplete',
  NostrEmail = 'showNostrEmailForm',

  // unused
  // LockedAccount = 'showLockedAccount',
  // ResetPassword = 'showResetPassword',
  // LoginNewDevice = 'showLoginNewDevice',
}

interface Account {
  secretKey: string;
  appsInstalled: number;
}

export interface User {
  id: number;
  name: string;
  email: string;
  account: Account;
}

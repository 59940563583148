<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    id?: string;
    type?: string;
    placeholder?: string;
    shape?: 'square' | 'rounded' | 'cubed' | 'oval';
    value?: string;
    flavor?: string;
    label?: string;
    name?: string;
    subtext?: string;
    errorMessage?: string;
    focus?: boolean;
    disabled?: boolean;
  }>(),
  {
    type: 'text',
    shape: 'square',
  }
);

const input = ref<HTMLInputElement | null>(null);

const CSSClasses = computed(() => {
  return [
    props.flavor && `textinput-block--${props.flavor}`,
    props.shape && `textinput-block--${props.shape}`,
    props.type && `textinput-block--${props.type}`,
    props.disabled && 'textinput-block--disabled',
    props.errorMessage && 'textinput-block--has-error',
  ];
});

onMounted(() => {
  if (props.focus && input.value) {
    input.value.focus();
  }
});
</script>

<template>
  <div :class="CSSClasses" class="textinput-block">
    <div class="textinput-block__label-holder" v-if="label">
      <label :for="id" class="textinput-block__label">{{ label }}</label>
    </div>

    <div class="textinput-block__input-holder">
      <div class="radio-controls">
        <input
          v-if="type !== 'secret-key'"
          ref="input"
          :id="id"
          :name="name"
          :value="value"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          class="textinput-block__input"
        />
        <textarea
          v-else-if="type === 'secret-key'"
          ref="input"
          :id="id"
          :name="name"
          :value="value"
          :placeholder="placeholder"
          :disabled="disabled"
          class="textinput-block__input"
          :style="{ height: '6.9rem', resize: 'none', 'font-family': 'monospace' }"
        />

        <slot />
      </div>

      <div v-if="subtext" class="textinput-block__subtext">{{ subtext }}</div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>

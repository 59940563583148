import type { VaultItem } from '../crypto/vault/index';
import type { StorageMigrator } from '../crypto/vault/migrations';

export function newStorageMigrator(randomID: () => string): StorageMigrator {
  return {
    migrateState(state) {
      const items: VaultItem[] = [];
      const deployments = Object.values(state.deployments);
      for (let i = 0; i < deployments.length; i++) {
        items.push({
          id: randomID(),
          data: JSON.stringify({
            kind: 'deployment',
            value: deployments[i],
          }),
        });
      }
      delete state.deployments;

      items.push({
        id: randomID(),
        data: JSON.stringify({
          kind: 'userState',
          value: state,
        }),
      });

      return items;
    },
  };
}

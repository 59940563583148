import type { JSONSchema6 } from 'json-schema';

export enum Location {
  values = 'values.yaml',
  env = 'env',
  args = 'args',
  file = 'file',
}

export enum SettingType {
  enum = 'enum',
  string = 'string',
  number = 'number',
  boolean = 'boolean',
}

/**
 * A single configuration variable.
 */
export interface Setting {
  /**
   * Setting ID. Defaults to the key name to which it is mapped in the Config
   * object, if not given.
   */
  id?: string;

  /**
   * Name of the setting. This is generally inferred from the key to which it's
   * mapped in the Config object.
   * TODO: should this be used for display and the id of the setting is _only_ inferred
   *       from the key?
   *       e.g. name is "Allow user registration only from allowed domain?"
   *            key in parent is "allowed_registration_domain"
   */
  name?: string;

  /**
   * Location where setting is applied.
   */
  location?: Location;

  /**
   * When true, this setting is included in version management.
   */
  version?: boolean;

  /**
   * Setting description
   */
  description?: string;

  /**
   * additional details shown when hovering over (i) on launch page
   */
  tooltip?: string;

  /**
   * validation rules for the setting.
   * TODO: is JSONSchema6 too permissive?
   *       could people put meaningless stuff here (e.g. type: 'object') & do
   *       we care about preventing that vs simply skipping properties we
   *       don't know how to handle?
   */
  value: JSONSchema6;

  /**
   * optional sub-settings.
   *
   * 1 level of sub-settings is allowed, i.e. a subsetting cannot itself have subsettings
   */
  sub_settings?: Settings;

  /**
   * validation rule to determine if this setting should be visible
   */
  condition?: JSONSchema6; // TODO: too permissive?

  /**
   * When true, this field must have a value in order to deploy the application.
   *
   * In general, this should only be used for "deployment" or "basic" configs,
   * as "advanced" settings are hidden by default.
   *
   * Additionally, sensible defaults are preferred over required settings to
   * ease set up.
   */
  required?: boolean;

  /**
   * Value should be randomly generated using a secure RNG.
   * TODO: move to `value` type?
   */
  random?: boolean;

  /**
   * When true, value should only be exposed to services which require it.
   *
   * Currently only used for Docker Compose. If the service explicitly declares
   * a matching environment variable, its value will be updated with this one.
   */
  secure?: boolean;
}

export type SubSettings = Record<string, Omit<Setting, 'sub_settings'>>;
export type Settings = { [k: string]: Setting };

/**
 * Config describes how the application can be configured.
 */
export interface Config {
  /**
   * Settings related to application deployment
   */
  deployment?: Settings | null;

  /**
   * Required application configuration. These are non-deployment configuration options which are
   * required in order for the application to function.
   *
   * In general, we prefer *not* to require settings unless absolutely necessarily, in order to
   * offer a streamlined launch process.
   *
   * TODO: rename to `required`
   */
  basic?: Settings | null;

  /**
   * Optional application configuration.
   *
   * This includes all other configuration parameters. We need not include every possible parameter,
   * however those which are likely to be used by our users should be included, if possible. As a
   * fallback, users should be able to directly access any configuration files (think wp-config.php)
   * or set env vars and CLI args directly.
   *
   * TODO: rename to `optional`
   */
  advanced?: Settings | null;
}

import type { Deployment } from 'kubernetes-types/apps/v1';
import type { Pod } from 'kubernetes-types/core/v1';

import { retry } from '../util/retry';

import { getLatestTag } from './docker';
import { addScheme, execWithOutput } from '.';

export async function getPods(host: string, accessKey: string): Promise<Pod[]> {
  const res = await (
    await execWithOutput(
      host,
      accessKey,
      `#!/bin/bash
set -eo pipefail
kubectl get pods -o json
      `
    )
  ).json();

  if (!res || !res.items) {
    throw new Error('No pods found');
  }

  return res.items as Pod[];
}

export async function getDeployments(host: string, accessKey: string): Promise<Deployment[]> {
  const res = await (
    await execWithOutput(
      host,
      accessKey,
      `#!/bin/bash
set -eo pipefail
kubectl get deployments -o json
      `
    )
  ).json();

  if (!res || !res.items) {
    throw new Error('No deployments found');
  }

  return res.items as Deployment[];
}

const APP_LABEL = 'app.kubernetes.io/name';

export async function upgradeClovyrServicesK8S(host: string, accessKey: string) {
  const pods = (await retry(() => getPods(host, accessKey), 5, true)).filter(
    (j) => j.metadata?.name && j.metadata.name.startsWith('clovyr-')
  );
  if (!pods.length) {
    return;
  }

  // check each pod for a newer image
  pods.forEach((pod) => {
    if (!pod.status?.containerStatuses) {
      return;
    }
    pod.status.containerStatuses.forEach(async (container) => {
      const podName = pod.metadata?.labels?.[APP_LABEL];
      if (!podName) {
        return;
      }

      const currentSHA = container.imageID.split(':').pop();

      // get latest sha
      const imageURL = new URL(addScheme(container.image));
      let registry = '';
      if (imageURL.hostname !== 'docker.io') {
        registry = `${imageURL.protocol}//${imageURL.hostname}`;
      }
      let image = imageURL.pathname.substring(1);
      let tag = 'latest';
      if (image.includes(':')) {
        [image, tag] = image.split(':');
      }
      const imageTag = await getLatestTag(host, accessKey, image, tag, registry);
      if (!imageTag?.digest) {
        console.warn(`No digest found for ${image}:${tag}`);
        return;
      }
      const latestSHA = imageTag.digest.split(':').pop();

      // compare
      if (currentSHA === latestSHA) {
        // eslint-disable-next-line no-console
        console.log(`pod ${podName} is already up to date`);
        return;
      }

      // update it
      void execWithOutput(
        host,
        accessKey,
        `#!/bin/bash
set -eo pipefail
kubectl rollout restart deployment/${podName}
`
      );
    });
  });
}

/**
 * The remote_exec package contains methods for securely communicating with a
 * compute instance. All communication happens client-side (in the browser)
 * between the client and the compute instance over HTTPS.
 */
/* eslint-disable no-await-in-loop */
import type { Emitter } from 'mitt';

import { unwrapRes } from '../api/wnext-base';
import { createNonce, decodeKey, encodeBase64Url, encrypt } from '../crypto';
import { WNextDNS } from '../dns/wnext';
import { type ApiRequest, type ApiResponse, FetchWrapper } from '../http';
import { HTTPError } from '../http/error';
import type { DNSRecord, Request } from '../types';
import { sleep, waitFor } from '../util/sleep';

/**
 * Events emitted when launching (configuring) an instance
 */
export type LaunchEvents = {
  'launch:start': void;
  'launch:wait': void;
  'launch:instance_up': void;
  'launch:app_deploying': void;
  'launch:app_deployed': void;
  'launch:ready': void;
  'launch:err': Error;
};

/**
 * Local http client for talking to the app server
 *
 * TODO: each app server should actually have a fresh client, as we use different keys, etc.
 */
export const httpClient = new FetchWrapper();

// configure

export interface ConfigureInstanceRequest extends Request {
  fqdn: string;
  configureKey: string;
  configureScript: string;
  checkPath?: string;
  /**
   * When true, indicates that this is the second time calling configure for this instance.
   */
  reconfigure?: boolean;
  emitter?: Emitter<LaunchEvents>;
  isPrimaryProxied?: boolean;
}

// sometimes a no-op, based on app type
// instant apps: no-op
// others, need to be configured
export async function configureInstance(req: ConfigureInstanceRequest): Promise<void> {
  // wait for dns to propagate first
  const dnsStatus = await waitForDNS(req.fqdn, req.reconfigure ? 0 : 5000, 100);
  if (dnsStatus !== 'propagated') {
    throw new Error('timeout waiting for dns to propagate');
  }

  const configureDomain = req.isPrimaryProxied ? req.fqdn : `cfproxy-${req.fqdn}`;
  // wait for instance-configure service
  const configureBaseURL = addScheme(configureDomain);
  const appURL = addScheme(req.fqdn);
  // TODO: do we really need a 60sec delay if we've already validated that DNS has propagated?
  const delay = req.reconfigure ? 100 : 60000;
  let status = await waitForInstance(
    `${configureBaseURL}/_leaf/ready`,
    req.configureKey,
    delay,
    100
  );
  if (!(status >= 200 && status < 300)) {
    // timed out
    throw new Error('timeout waiting for instance ready');
  }

  if (req.emitter) {
    req.emitter.emit('launch:instance_up');
  }

  // configure it
  await exec(configureBaseURL, req.configureKey, req.configureScript, req.reconfigure);

  if (req.emitter) {
    req.emitter.emit('launch:app_deploying');
  }

  // wait for app to come up
  const checkPath = req.checkPath ?? '/';
  status = await waitForInstance(appURL + checkPath, req.configureKey, 10000, 200);
  if (!(status >= 200 && status < 300)) {
    // timed out or other error
    console.warn('status was: ', status);
    throw new Error('timeout waiting for instance configure');
  }

  if (req.emitter) {
    req.emitter.emit('launch:app_deployed');
  }
}

/**
 * Generic remote execution on the remote server. Payload is encrypted using configureKey.
 *
 * @param baseUrl
 * @param configureKey
 * @param script
 * @param generic When true, uses the generic exec endpoint (always want this after initial configure)
 * @param verbose When true, returns stdout/stderr result from the command.
 * @param async When true, returns immediately after sending the command.
 * @returns
 */
export async function exec(
  baseUrl: string,
  configureKey: string,
  script: string,
  generic?: boolean,
  verbose?: boolean,
  async?: boolean
): Promise<ApiResponse> {
  baseUrl = addScheme(baseUrl); // in case we just got the fqdn directly

  const nonce = createNonce();
  const encrypted = encrypt(new TextEncoder().encode(script), nonce, decodeKey(configureKey));
  // console.log('encrypted with key', req.configureKey);

  let url = `${baseUrl}/`;
  if (generic) {
    url += '_leaf/api/exec/bash';
  }

  let verboseOutput = verbose ? '1' : '0';
  if (async) {
    verboseOutput = '2';
  }

  // don't need response. will throw on error
  const res = await httpClient.post(url, encrypted, {
    headers: {
      Authorization: `Bearer: ${configureKey}`,
      'content-type': 'application/octet-stream',
      'X-NaCl-Nonce': encodeBase64Url(nonce),
      'X-Verbose': verboseOutput,
    },
  });
  if (!(res instanceof Response)) {
    throw res;
  } else if (res.status !== 524 && res.status !== 200) {
    // 524 (timeout) occurs because the configure actually takes a long time
    // and often times out. We probably just just tell instance-configure to
    // respond immediately instead of keeping the connection open until the
    // command completes.
    // console.warn('exec call failed with status:', res.status);
    throw new HTTPError('failed to exec command', res.status, await res.text());
  }

  return res;
}

interface ExecResult {
  id: string;
  status: string;
  exitCode: number;
  output: string;
}

/**
 * Fully asynchronous exec with output.
 *
 * Runs the given script and then polls for the result. Waits up to 10min.
 *
 * @param baseUrl
 * @param configureKey
 * @param script
 * @returns
 */
export async function execWithAsyncOutput(
  baseUrl: string,
  configureKey: string,
  script: string
): Promise<ExecResult> {
  const res = await unwrapRes(exec(baseUrl, configureKey, script, true, true, true));
  let result = res.data.result as ExecResult;
  if (result.status !== 'running') {
    return result;
  }

  // wait for up to 10min with 500ms interval
  result = await waitFor(200, 1200, 500, async () => {
    const r = await unwrapRes(
      httpClient.post(
        `${addScheme(baseUrl)}/_leaf/api/exec/bash/${result.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer: ${configureKey}`,
            'X-Verbose': '2',
          },
        }
      )
    );
    const nr = r.data.result as ExecResult; // new result
    return [nr.status !== 'running', nr];
  });

  return result;
}

export async function execWithOutput(
  baseUrl: string,
  configureKey: string,
  script: string
): Promise<ApiResponse> {
  return exec(baseUrl, configureKey, script, true, true);
}

/**
 * Rotate secret key on a given instance host
 *
 * @param host Instance host to rotate key on
 * @param oldKey Existing key
 * @param newKey New key
 * @returns void
 */
export async function rotateKey(host: string, oldKey: string, newKey: string): Promise<void> {
  await httpClient.post(
    `${addScheme(host)}/_leaf/api/auth/rotate`,
    { key: newKey },
    authHeader(oldKey)
  );
  httpClient.setAuthorization(newKey);
}

export interface ClaimInstanceRequest {
  host: string;
  oldKey: string;
  newKey: string;
  resticConfig: ClaimInstanceResticConfig;
}

export interface ClaimInstanceResticConfig {
  awsAccessKeyID: string;
  awsSecretAccessKey: string;
  password: string;
  repository: string;
}

/**
 * Claims an idle instant launch instance.
 *
 * i.e., logs in to the actual server with the old key, and rotates the key to the new key.
 */
export async function claimInstance(req: ClaimInstanceRequest): Promise<void> {
  const res = await httpClient.post(
    `${addScheme(req.host)}/_leaf/api/claim`,
    {
      rotateAuth: {
        key: req.newKey,
      },
      resticConfig: req.resticConfig,
    },
    authHeader(req.oldKey)
  );
  if (!(res instanceof Response)) {
    throw res as Error;
  }
  if (res.status !== 200) {
    throw new Error(`unexpected status code: ${res.status}`);
  }

  httpClient.setAuthorization(req.newKey);
}

export interface ValidateAuthRequest {
  host: string;
  key: string;
}

export async function validateAuth(req: ValidateAuthRequest): Promise<boolean> {
  const res = await httpClient.get(
    `${addScheme(req.host)}/_leaf/api/auth/validate`,
    authHeader(req.key)
  );
  if (!(res instanceof Response)) {
    return false;
  }
  if (res.status !== 200) {
    return false;
  }
  return true;
}

/**
 * Give access to a new user key
 *
 * @param host Instance host
 * @param secretKey Key to use for authenticating API request
 * @param key New key to add
 * @param access Access type
 * @returns void
 */
export async function addKey(
  host: string,
  secretKey: string,
  key: string,
  access: string
): Promise<void> {
  await httpClient.post(
    `${addScheme(host)}/_leaf/api/auth`,
    { key, access },
    authHeader(secretKey)
  );
}

/**
 * Remove access from a given user key
 *
 * @param host Instance host
 * @param secretKey Key to use for authenticating API request
 * @param key Key to remove
 * @returns void
 */
export async function removeKey(host: string, secretKey: string, key: string): Promise<void> {
  await httpClient.delete(`${addScheme(host)}/_leaf/api/auth`, { key }, authHeader(secretKey));
}

/**
 * Authenticate with the the given host. On success, an auth cookie is returned.
 *
 * @param host
 * @param secretKey
 */
export async function authenticateAppHost(host: string, secretKey: string): Promise<void> {
  await httpClient.post(`${addScheme(host)}/_leaf/api/auth/signin`, null, authHeader(secretKey));
}

/**
 * Create request object with authorization header
 *
 * @param secretKey
 * @returns
 */
function authHeader(secretKey: string): ApiRequest {
  return {
    headers: {
      Authorization: `Bearer: ${secretKey}`,
    },
  };
}

/**
 * Prepend input with https scheme, if needed.
 *
 * @param host
 * @returns
 */
export function addScheme(host: string): string {
  if (host && host.match(/^https?:\/\//)) {
    return host;
  }
  return `https://${host}`;
}

async function waitForDNS(host: string, initialSleep: number, maxTries: number) {
  const dns = new WNextDNS();
  return waitFor(initialSleep, maxTries, 5000, async () => {
    let status: string = 'NXDOMAIN';
    const res: DNSRecord = await dns.getRecord({ name: host });
    status = res.status;
    return [status === 'propagated', status];
  });
}

/**
 * waitForInstance to come up by polling the giving URL
 *
 * @param url
 * @param initialSleep
 * @param maxTries
 * @returns
 */
export async function waitForInstance(
  url: string,
  configureKey: string,
  initialSleep: number,
  maxTries: number
): Promise<number> {
  return waitFor(initialSleep, maxTries, 5000, async () => {
    let status = 0;
    const res = await httpClient.get(url, {
      headers: {
        // use both headers. I don't think cookie is valid in browser context Fetch API.
        // we must rely on the browser itself to send cookies via 'credential: include'
        Authorization: `Bearer: ${configureKey}`,
        cookie: `user/auth=${configureKey};`,
        // some apps may respond with a json/api route if we don't use the correct headers
        // e.g., mastodon does this
        accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8',
        'content-type': 'text/html',
      },
    });
    if (res instanceof Response) {
      status = res.status;
      if (status === 403) {
        // 403 is coming back from traefik because we don't have a valid cookie
        // try to request a cookie since
      }
      return [status >= 200 && status < 300, status];
    }
    return [false, status];
  });
}

interface InstanceConfigureVersion {
  IC_BIN: string;
  IC_SHA: string;
}

function binEnv(appEnv: string): string {
  let e = '';
  switch (appEnv) {
    case 'local':
      e = 'local/';
      break;
    case 'staging':
      e = 'staging/';
      break;
    default:
      e = '';
  }
  return e;
}

export async function getLatestInstanceConfigureVersion(
  env: string
): Promise<InstanceConfigureVersion> {
  return (
    await fetch(`https://cstatic.app/leaf/dist/ic/${binEnv(env)}latest.json?${Date.now()}`)
  ).json();
}

export async function getRunningInstanceConfigureVersion(
  host: string,
  configureKey: string
): Promise<string> {
  const shaOutput = await (
    await execWithOutput(host, configureKey, 'sha256sum /opt/clovyr/leaf/instance-configure')
  ).text();

  return shaOutput.split(' ')[0].trim();
}

export async function upgradeInstanceConfigure(
  host: string,
  configureKey: string,
  env: string
): Promise<void> {
  let latest: InstanceConfigureVersion | undefined;
  try {
    latest = await getLatestInstanceConfigureVersion(env);
    const runningSHA = await getRunningInstanceConfigureVersion(host, configureKey);
    if (runningSHA === latest.IC_SHA) {
      // eslint-disable-next-line no-console
      console.log('instance-configure is already up to date');
      return;
    }

    // eslint-disable-next-line no-console
    console.log(
      'instance-configure is out of date. Upgrading...',
      runningSHA.slice(0, 8),
      '->',
      latest.IC_SHA.slice(0, 8)
    );

    const upgrade = `
set -eo pipefail
cd /opt/clovyr/leaf
wget -nv https://cstatic.app/leaf/dist/ic/${binEnv(env)}${latest.IC_BIN} -O instance-configure.new
chmod +x instance-configure.new
mv instance-configure.new instance-configure
systemctl restart instance-configure
      `;

    await execWithOutput(host, configureKey, upgrade);
  } catch (e) {
    if (e instanceof HTTPError) {
      if (e.status === 602) {
        // likely during process restart, just check version again for success after a few seconds
        // console.log('result:\n', e.body);
        await sleep(5000);
        // check version on server again
        const newRunningSHA = await getRunningInstanceConfigureVersion(host, configureKey);
        if (latest && newRunningSHA === latest.IC_SHA) {
          // eslint-disable-next-line no-console
          console.log('instance-configure upgrade successful');
        }
      } else {
        console.warn('failed to upgrade instance-configure', e, e.body);
      }
    }
  }
}

import { AuthState, AuthType } from '../../../pollen/types';
import { decodeBase32 } from '../../../util/base32';
import {
  type NoSecretKeyPasswordKeychain,
  type OpenPasswordKeychain,
  type VaultItem,
} from '../types';
import { xorKeys } from '../util';
import { encodeKeychain, getVaultItems, migrateMonolithicState } from '..';

import { baseState } from './base';
import { stateOpenRemote } from './remote';
import type { ItemKeys, StateFunc } from './types';

export function statePasswordNoSecretKey(keychain: NoSecretKeyPasswordKeychain): StateFunc {
  return ({ storage, vaultAPI, userAPI, migrator }) => ({
    ...baseState('passwordNoSecretKey'),

    async addSecretKey(secretKey) {
      const secretKeyBytes = decodeBase32(secretKey);
      const auk = xorKeys(keychain.passwordKey, secretKeyBytes);

      const readStateResponse = await userAPI.readState(keychain.email);
      // @ts-expect-error TODO: fix when we have better API err typing
      if (readStateResponse.code === 403) {
        throw new Error('not authorized');
      }
      let items: VaultItem[];
      let itemKeys: ItemKeys;
      if (readStateResponse.encryptedState) {
        [items, itemKeys] = await migrateMonolithicState(
          readStateResponse.encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
      } else {
        [items, itemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      }

      const newKeychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        email: keychain.email,
        secretKey: secretKeyBytes,
        passwordKey: keychain.passwordKey,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));

      return [stateOpenRemote(newKeychain, itemKeys), items];
    },

    email() {
      return keychain.email;
    },

    authType() {
      return AuthType.Password;
    },

    authState() {
      return AuthState.LoggedInWithoutSecretKey;
    },
  });
}

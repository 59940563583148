e
<template>
  <div class="help-controls">
    <div
      v-if="previousPage"
      class="help-controls__left"
      @click="handlePageClick(previousPage.page)"
    >
      <div class="control-label">Previous</div>
      <div class="control-button">{{ previousPage.name }}</div>
    </div>

    <div v-if="nextPage" class="help-controls__right" @click="handlePageClick(nextPage.page)">
      <div class="control-label">Next</div>
      <div class="control-button">{{ nextPage.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpControls',
  props: {
    nextPage: {
      type: Object,
      default: () => {},
    },
    previousPage: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handlePageClick(page) {
      this.$emit('navigate', page);
    },
  },
};
</script>
<style scoped lang="scss">
.help-controls {
  display: flex;
  justify-content: space-between;
  margin-top: space(4);

  &__left,
  &__right {
    cursor: pointer;
  }

  &__left {
    margin-right: auto;
  }

  &__right {
    margin-left: auto;
    text-align: right;
  }

  .control-label {
    @include text--mono;

    margin-bottom: space(1.2);
    color: color(grey);
  }

  .control-button {
    @include text--h4;

    color: color(action, secondary);
  }
}
</style>

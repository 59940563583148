import { AuthState, AuthType } from '../../../pollen/types';
import { encodeBase32 } from '../../../util/base32';
import { type LockedKeychain, type OpenPasswordKeychain } from '../types';
import { xorKeys } from '../util';
import { acceptShare, encodeKeychain, getVaultItems, putVaultItem, shareItem } from '..';

import { baseState } from './base';
import type { StateLockedConstructor } from './locked';
import type { ItemKeys, StateFunc } from './types';

export type StateOpenRemoteConstructor = (
  keychain: OpenPasswordKeychain,
  itemKeys: ItemKeys
) => StateFunc;

export function stateOpenRemote(keychain: OpenPasswordKeychain, itemKeys: ItemKeys): StateFunc {
  const auk = xorKeys(keychain.secretKey, keychain.passwordKey);
  return ({ storage, vaultAPI, userAPI }) => ({
    ...baseState('openRemote'),

    async putItem(item) {
      return putVaultItem(vaultAPI, itemKeys, auk, item);
    },

    async deleteItem(itemID) {
      await vaultAPI.deleteItem(itemID);
      delete itemKeys[itemID];
    },

    async items() {
      const [items, newItemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      itemKeys = newItemKeys;
      return items;
    },

    async lock(stateLockedFn: StateLockedConstructor) {
      await userAPI.signOut();
      const newKeychain: LockedKeychain = {
        state: 'passwordLocked',
        secretKey: keychain.secretKey,
        email: keychain.email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));
      return stateLockedFn(newKeychain);
    },

    async shareItem(itemID, extraParams) {
      return shareItem(vaultAPI, auk, itemID, itemKeys, extraParams);
    },

    async acceptShare(itemID: string, shareSecret: string) {
      const [items, newItemKeys] = await acceptShare(
        vaultAPI,
        auk,
        itemID,
        shareSecret,
        keychain.email
      );
      itemKeys = newItemKeys;
      return items;
    },

    secretKey() {
      return encodeBase32(keychain.secretKey);
    },

    authType() {
      return AuthType.Password;
    },

    authState() {
      return AuthState.LoggedIn;
    },

    email() {
      return keychain.email;
    },
  });
}

import type { App } from 'vue';
import type { Router } from 'vue-router';
import * as SentryBrowser from '@sentry/browser';
import type { Breadcrumb, BreadcrumbHint } from '@sentry/vue';
import * as Sentry from '@sentry/vue';

import { config } from './config';

function getCookie(name: string): string | null {
  if (typeof document === 'undefined') {
    return null;
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() || null;
  }
  return null;
}

const ignoreErrors = [
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: TypeError: Load failed',
  'TypeError: Load failed',
  'Error: unexpected status code from getInstance endpoint: 502',
];

function filterBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb | null {
  if (
    breadcrumb.category === 'console' &&
    breadcrumb.message &&
    breadcrumb.message.includes('Hydration completed but contains mismatches')
  ) {
    return null;
  }
  return breadcrumb;
}

function beforeSend(event: Sentry.ErrorEvent, hint?: Sentry.EventHint) {
  if (hint && hint.originalException instanceof Error) {
    if (
      hint.originalException.stack &&
      hint.originalException.stack.includes('monaco-editor/esm/vs/base/common/errors')
    ) {
      return null;
    }
  }
  return event;
}

export function initSentry(app: App<Element>, router: Router) {
  if (import.meta.env.SSR) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const integrations: any[] = [];
  if (!import.meta.env.SSR) {
    // don't enable browser tracing in SSR
    integrations.push(Sentry.browserTracingIntegration({ router }));
  }
  Sentry.init({
    app,
    environment: config.VITE_APP_ENV || 'local',
    dsn: config.VITE_SENTRY_DSN || '',
    ignoreErrors,
    beforeBreadcrumb: filterBreadcrumb,
    beforeSend,
    logErrors: false,
    integrations,
    tracePropagationTargets: [/^\/api/],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number.parseFloat(config.VITE_SENTRY_SAMPLE_RATE) || 1.0,
  });
  SentryBrowser.init({
    environment: config.VITE_APP_ENV || 'local',
    dsn: config.VITE_SENTRY_DSN || '',
    ignoreErrors,
    beforeBreadcrumb: filterBreadcrumb,
    beforeSend,
    integrations,
    tracePropagationTargets: [/^\/api/],
  });

  const sessionID = getCookie('user/auth');
  if (sessionID) {
    Sentry.setContext('session', { id: sessionID });
    SentryBrowser.setContext('session', { id: sessionID });
  }
}

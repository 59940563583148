import { storeToRefs } from 'pinia';
import type {
  NavigationGuardReturn,
  RouteLocationGeneric,
  RouteLocationNormalized,
} from 'vue-router';
import * as Sentry from '@sentry/core';

import { isClovyrCode } from '@clovyr/pollen/garden';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { trace } from '@clovyr/pollen/log';
import type { Manifest } from '@clovyr/pollen/manifest';
import type { Bundle } from '@clovyr/pollen/types';
import { strToBase64 } from '@clovyr/pollen/util/base64';

import { claimClovyrCode } from '../composables/claimClovyrCode';
import { useLauncherStore } from '../stores/launcher_store';
import { usePollenStore } from '../stores/pollen_store';
import { FeatureFlag, useUserFlagsStore } from '../stores/user_flags';

/**
 * 'internal' feature flag
 */
const internalFlagToken = 'aishaveewai3shee2thaeH5ooc2go9ee';

const appConfigToken = 'xeejioceiliuh4diujia8He1eequ6aef';

const yearInSec = 60 * 60 * 24 * 365;

/**
 * continue if garden contains collection with indicated ID
 *
 * else 404
 */
export const collectionExists = (to: RouteLocationNormalized): NavigationGuardReturn => {
  const { garden } = usePollenStore();
  const collectionId = Array.isArray(to.params.id) ? to.params.id[0] : to.params.id;
  const collection: Bundle | undefined = garden.getBundles().find((cat) => cat.id === collectionId);
  return collection ? true : '/';
};

/**
 * continue if tag is `all` or at least one app has the tag
 *
 * else 404
 */
export const tagExists = (to: RouteLocationNormalized): NavigationGuardReturn => {
  const { garden } = usePollenStore();
  const tag = Array.isArray(to.params.name) ? to.params.name[0] : to.params.name;
  const apps = tag === 'all' ? garden.getApps() : garden.getAppsByTag(tag);
  return apps.length > 0 ? true : '/';
};

export const extractAppParams = (to: RouteLocationNormalized): NavigationGuardReturn => {
  const pubId = Array.isArray(to.params.publisher) ? to.params.publisher[0] : to.params.publisher;
  const appId = Array.isArray(to.params.id) ? to.params.id[0] : to.params.id;
  let id = appId;
  if (pubId) {
    id = `${pubId}/${appId}`;
  }
  to.meta = Object.assign({}, to.meta, { pubId, appId, id });
  return true;
};

export const appFromRoute = (to: RouteLocationNormalized): Manifest | undefined => {
  if (!to.meta.appId) {
    extractAppParams(to);
  }
  const { id } = to.meta;
  const { garden } = usePollenStore();
  return garden.getAppByID(id as string);
};

/**
 * App must exist in the garden to continue
 *
 * @param to
 * @returns
 */
export const appExists = async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
  const { garden } = usePollenStore();
  await garden.isFullyLoaded();
  const app = appFromRoute(to);
  if (!app) {
    return '/';
  }
  if (isClovyrCode(app.metadata.id)) {
    if (to.fullPath.includes('/apps/')) {
      return to.fullPath.replace('/apps/', '/instant/');
    }
    return `/instant${to.fullPath}`;
  }
  return true;
};

/**
 * Called from the PathNotFound handler, redirects to the app detail if the path matches an app
 * (with or without publisher)
 *
 * @param to
 * @returns
 */
export const redirectToApp = async (
  to: RouteLocationNormalized,
): Promise<NavigationGuardReturn> => {
  if (Array.isArray(to.params.pathMatch) && to.params.pathMatch.length > 0) {
    if (to.params.pathMatch.length === 1) {
      // path without publisher, e.g.: kanboard
      to.params.publisher = '';
      to.params.id = to.params.pathMatch[0].toLowerCase();
    } else if (to.params.pathMatch.length === 2) {
      // path with publisher, e.g.: clovyr/kanboard
      to.params.publisher = to.params.pathMatch[0].toLowerCase();
      to.params.id = to.params.pathMatch[1].toLowerCase();
    }
    if ((await appExists(to)) !== '/') {
      if (to.params.id === 'clovyr-code') {
        return '/apps/clovyr-code';
      }
      const prefix = isClovyrCode(to.params.id as string) ? '/instant' : '/apps';
      if (to.params.publisher) {
        return `${prefix}/${to.params.publisher}/${to.params.id}`;
      }
      return `${prefix}/${to.params.id}`;
    }
  }
  return true;
};

export const launchSettingsProvided = (to: RouteLocationNormalized) => {
  const { hasValidSettings } = useLauncherStore();
  const appId = Array.isArray(to.params.id) ? to.params.id[0] : to.params.id;

  return hasValidSettings ? true : `/apps/${appId}/launch/setup`;
};

/**
 * Validate the deployment exists
 *
 * @param to
 * @returns
 */
export function validDeployment(to: RouteLocationNormalized): NavigationGuardReturn {
  const { getDeploymentApp } = usePollenStore();
  const { id } = to.params;
  if (!(id && !Array.isArray(id))) {
    return { name: 'Library' };
  }
  const app = getDeploymentApp(id);
  if (!app) {
    return { name: 'Library' };
  }
  return true;
}

export function loginRedir(to: RouteLocationNormalized, path: string): NavigationGuardReturn {
  if (import.meta.env.SSR) {
    return true;
  }
  const { isUserAuthenticated } = storeToRefs(usePollenStore());
  if (!isUserAuthenticated.value) {
    trace('guard[loginRedir] user not authenticated');
    return { path, query: { redir: to.fullPath } };
  }
  return true;
}

/**
 * Protects the route by requiring that user is logged in to continue.

 * @param to
 * @returns
 */
export function mustBeLoggedIn(to: RouteLocationNormalized): NavigationGuardReturn {
  return loginRedir(to, '/login');
}

export function mustSignUp(to: RouteLocationNormalized): NavigationGuardReturn {
  return loginRedir(to, '/signup');
}

/**
 * User must be associated with a publisher to continue. If not, sent to publisher signup.
 *
 * @returns
 */
export async function isPublisher(): Promise<NavigationGuardReturn> {
  if (import.meta.env.SSR) {
    // short-circuit and always render
    return true;
  }
  const pollenStore = usePollenStore();
  const { pollen } = pollenStore;
  const { publishersLoaded, isPublisher: isPub } = storeToRefs(pollenStore);
  if (!publishersLoaded.value) {
    await pollen.loadPublisherData();
  }
  if (!isPub.value) {
    return { path: '/publishers/new' };
  }
  return true;
}

/**
 * If user only belongs to a single publisher, just go there directly
 *
 * @returns
 */
export async function onePublisher(): Promise<NavigationGuardReturn> {
  if (import.meta.env.SSR) {
    return true;
  }
  const pollenStore = usePollenStore();
  const { pollen } = pollenStore;
  const { publishersLoaded, publishers } = storeToRefs(pollenStore);
  if (!publishersLoaded.value) {
    await pollen.loadPublisherData();
  }
  const { canAccessInternalOnlyFeatures } = storeToRefs(useUserFlagsStore());

  if (publishers.value?.length === 1 && !canAccessInternalOnlyFeatures.value) {
    return { path: `/publishers/${publishers.value[0].slug}` };
  }
  return true;
}

/**
 * Guard which extracts user flag tokens in the query string. Runs on all routes.
 *
 * @param to
 * @returns
 */
export function extractUserFlagTokens(to: RouteLocationGeneric): NavigationGuardReturn {
  const userFlags = useUserFlagsStore();
  const { token } = to.query;
  if (!token) {
    return true;
  }
  if (token === internalFlagToken) {
    userFlags.addFlag(FeatureFlag.Internal);
  }
  if (token === appConfigToken) {
    userFlags.addFlag(FeatureFlag.AppConfig);
  }
  if (typeof window !== 'undefined') {
    if (userFlags.flags.length) {
      const flagsStr = JSON.stringify(userFlags.flags);
      const flagsEncoded = strToBase64(flagsStr);
      localStorage.setItem('clovyr_flags', flagsStr);
      document.cookie = `clovyr/feature_flags=${flagsEncoded};path=/;secure;samesite=strict;max-age=${yearInSec};`;
    }

    // remove token from url
    delete to.query.token;
    return to;
  }
  return true;
}

/**
 * Restrict access to routes which require a feature flag
 */
export function requireUserFlag(to: RouteLocationNormalized): NavigationGuardReturn {
  const routeFlags = to.meta.flags as FeatureFlag[];
  if (!(routeFlags && routeFlags.length)) {
    return true;
  }
  const userFlags = useUserFlagsStore();
  const check = routeFlags.map((req) => userFlags.userHasFeatureFlag(req));
  if (check.includes(false)) {
    trace('guard[featureFlag]', 'route requires feature flag', routeFlags);
    return { path: '/' };
  }
  return true;
}

/**
 * Handle instant launch requests instead of opening the wizard
 *
 * @param to
 * @returns
 */
export async function instantLaunchApp(
  to: RouteLocationNormalized,
): Promise<NavigationGuardReturn> {
  // FIXME: add publisher support for instant launch
  let appID = to.params.id as string;
  const params = getAllQueryParams();
  const fqdn = params.get('fqdn');
  const deploymentID = params.get('deployment_id');
  if (!(appID && fqdn && params.has('instant'))) {
    // not an instant launch request
    return true;
  }

  const router = useRouter();
  const { garden } = usePollenStore();
  if (isClovyrCode(appID)) {
    if (!garden.getAppByID(appID)) {
      // use generic clovyr-code if app not found
      // this is here to support the case where we may add test apps directly to the backend db &
      // not create manifests for them.
      appID = 'clovyr-code';
    }
    try {
      return await claimClovyrCode(appID, deploymentID!, router, fqdn, true);
    } catch (e) {
      Sentry.captureException(e);
    }
  } else {
    // all other apps
    try {
      return await claimClovyrCode(appID, deploymentID!, router, fqdn, false);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  return true;
}

export function addSentryContext(to: RouteLocationNormalized): void {
  if (Sentry.isInitialized()) {
    Sentry.setContext('route', {
      name: to.name,
      path: to.fullPath,
      params: to.params,
      query: to.query,
    });
  }
}

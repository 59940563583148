<template>
  <div class="help-page">
    <h2 v-if="headingText" class="help-page__heading">{{ headingText }}</h2>

    <div class="help-page__content">
      <slot />
    </div>

    <HelpControls
      :previousPage="previousPage"
      :nextPage="nextPage"
      @navigate="(page) => $emit('navigate', page)"
    />
  </div>
</template>

<script>
import HelpControls from '@/views/Help/HelpControls.vue';

export default {
  name: 'HelpPage',
  props: {
    headingText: {
      type: String,
      default: '',
    },
    nextPage: {
      type: Object,
      default: () => {},
    },
    previousPage: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    HelpControls,
  },
};
</script>
<style scoped lang="scss">
.help-page {
  color: color(grey, tertiary);

  &__heading {
    @include text--h2;

    @include font-size(36);

    margin-bottom: space(3);
  }

  &__content {
    @include help-copy;
  }
}
</style>

import type { Manifest } from '../manifest';
import type { Bundle, Catalog, Tag } from '../types';
import { slugify } from '../util/slugify';

/**
 * Garden is the primary interface for interacting with the Clovyr Application
 * Catalog.
 *
 * The Garden is organized as a set of collections, each containing lists of
 * applications.
 *
 * An Index is a subset of of applications and collections. It may be local to
 * the library or available at some public or private URL. The Clovyr Index
 * representing the Clovyr Application Catalog is always included by default.
 * Additional indexes may be added as necessary.
 */
export interface Garden {
  /**
   * Add an additional Index (application catalog) to the Garden.
   *
   * May be loaded via file:// or http://, depending on the context.
   *
   * @param location of index to add
   */
  addIndex(location: string | Catalog): Promise<void>;

  isFullyLoaded(): Promise<void>;

  /**
   * Get list of bundles (collections)
   */
  getBundles: () => Bundle[];

  /**
   * Get list of tags
   */
  getTags: () => Tag[];

  /**
   * Get list of all known applications
   */
  getApps: () => Manifest[];

  /**
   * Get list of applications in a specific bundle (collection)
   *
   * @param bundleId Bundle ID
   */
  getAppsByBundle(bundleId: string): Manifest[];

  /**
   * Get list of applications matching the given tag name. Tag may be a slug.
   *
   * @param tag
   */
  getAppsByTag(tag: string): Manifest[];

  /**
   * Get app by ID
   *
   * @param id
   */
  getAppByID(id: string): Manifest | undefined;

  /**
   * Load all deployment details, including files such as docker-compose.yml or
   * helm chart yaml files.
   *
   * These details must be loaded in order to launch an application, i.e., before a call to `remote_exec.instanceConfigure`
   * @param app
   */
  loadDeploymentDetails(app: Manifest): Promise<Manifest>;

  /**
   * Fetch a specific version of an application (i.e., something other than the latest)
   *
   * @param app Application to fetch
   * @param appVersion Version string to fetch
   */
  fetchAppVersion(app: Manifest, appVersion: string): Promise<Manifest>;
}

export class BaseGarden implements Garden {
  bundles: Bundle[];

  tags: Tag[];

  apps: Manifest[];

  constructor(bundles: Bundle[], tags: Tag[], apps: Manifest[]) {
    this.bundles = bundles;
    this.tags = tags;
    this.apps = apps;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addIndex(location: string | Catalog): Promise<void> {
    throw new Error('Method not implemented');
  }

  async isFullyLoaded(): Promise<void> {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadDeploymentDetails(app: Manifest): Promise<Manifest> {
    throw new Error('Method not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchAppVersion(app: Manifest, appVersion: string): Promise<Manifest> {
    throw new Error('Method not implemented');
  }

  getBundles(): Bundle[] {
    return this.bundles;
  }

  getTags(): Tag[] {
    return this.tags;
  }

  getApps(): Manifest[] {
    return this.apps;
  }

  getAppsByBundle(bundleId: string): Manifest[] {
    const apps: Manifest[] = [];
    const bundles = this.bundles.filter((b) => b.id === bundleId);
    if (bundles) {
      bundles.forEach((bundle) => {
        if (!bundle.apps) {
          return;
        }
        bundle.apps.forEach((appID) => {
          const app = this.apps.find((a) => a.metadata.id === appID);
          if (app) {
            apps.push(app);
          }
        });
      });
    }
    return apps;
  }

  getAppsByTag(tag: string): Manifest[] {
    return this.apps.filter(
      (manifest) =>
        !!manifest.metadata.tags?.find(
          (t) => t.toLowerCase() === tag.toLowerCase() || slugify(t) === tag
        )
    );
  }

  getAppByID(id: string): Manifest | undefined {
    if (!id) {
      return undefined;
    }
    let publisher: string;
    let appID: string;
    if (id.includes('/')) {
      [publisher, appID] = id.split('/');
    } else {
      [publisher, appID] = ['', id];
    }
    if (publisher === 'clovyr') {
      // for now, until we have a proper publisher id for all our apps
      publisher = '';
    }
    return this.apps.find((manifest) => {
      if (!publisher) {
        // Look for apps in clovyr catalog and/or published by clovyr.
        // Use specific IDs here so we *don't* match against clovyr-platform (the dynamic catalog
        // served via API)
        return (
          (manifest.catalog_id === 'clovyr' ||
            manifest.catalog_id === 'clovyr-internal' ||
            manifest.metadata.publisher === 'clovyr') &&
          manifest.metadata.id === appID
        );
      }
      return manifest.metadata.publisher === publisher && manifest.metadata.id === appID;
    });
  }
}

export * from './util';

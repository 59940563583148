<script lang="ts" setup>
import { ClovyrCodeLogo } from '@clovyr/bed';
import FediLogo from '@clovyr/bed/assets/images/fedimint.png';
import SproutIcon from '@clovyr/bed/components/icons/SproutIcon.vue';

import { claimClovyrCode } from '@/composables/claimClovyrCode';
import { useClovyrCodeHead } from '@/composables/useClovyrCodeHead';
import { useDeploymentStore } from '@/stores/deployment_store';

import FediSocialImage from '../../../assets/images/fedimint-social.webp';
import InstanceList from '../../components/blocks/InstanceList.vue';
import Modal from '../../components/elements/Modal.vue';

const props = defineProps<{ instantLaunchApp: string }>();

const router = useRouter();
const deploymentStore = useDeploymentStore();

const showModal = ref(false);
const errorMessage = ref('');

onMounted(() => {
  void deploymentStore.loadDeployments();
});

async function onClickLaunch() {
  try {
    const newRoute = await claimClovyrCode(
      'clovyr-code',
      props.instantLaunchApp,
      router,
      undefined,
      true,
    );
    void router.push(newRoute);
  } catch (error) {
    if (error instanceof Error) {
      errorMessage.value = error.message;
      showModal.value = true;
    }
  }
}

useClovyrCodeHead('Fedimint', FediSocialImage);
</script>
<template>
  <div class="instant">
    <div class="inner">
      <slot>
        <div>
          <div class="logo">
            <ImageMedia :filename="FediLogo" />
            <div class="plus">&nbsp;&nbsp;+&nbsp;&nbsp;</div>
            <ClovyrCodeLogo />
          </div>
        </div>
      </slot>

      <p>
        Fedimint + Clovyr Code is a complete, one-click developer environment, so you can start
        building for the billions now.
      </p>

      <div class="cta">
        <Button label="Launch now" reversed class="launch" @click="onClickLaunch">
          <SproutIcon />
        </Button>
        <Button label="Learn more" link="https://clovyr.io/code" class="learn" translucent />
      </div>
      <div class="instance-list">
        <InstanceList appID="code-fedimint" />
      </div>
    </div>
  </div>

  <Modal v-if="showModal" @close="showModal = false">
    <template v-slot:body>
      <div class="instance-error">
        <h4>{{ errorMessage }}</h4>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.instant {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    width: 246px;
  }

  p {
    @include text--color-grey-light;

    margin-top: 1em;
  }

  .inner {
    .logo {
      div {
        height: 50px;
        display: inline-block;
      }
      .plus {
        font-size: 3em;
        color: #e0dde4;
        line-height: 50px;
        height: 50px;
        vertical-align: top;
      }
      svg {
        max-width: 290px;
        display: inline-block;
        margin-bottom: 3px;
      }
    }

    padding: 4em;
    width: 790px;

    p {
      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }
      padding-top: 30px;
      padding-bottom: 30px;
      color: white;
    }
  }

  .cta {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1.5em;
    height: 45px;

    .learn {
      :deep(.button__label) {
        color: white;
        text-transform: none;
      }
    }

    .launch {
      :deep(.button__label) {
        text-transform: none;
      }

      :deep(svg) {
        margin-left: 0.5rem;
      }
    }
  }

  .instance-list {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    margin-top: 2.5em;
  }
}
</style>

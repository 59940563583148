<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';

import { UserAPI } from '@clovyr/pollen/user';

import FormField from '@/components/blocks/FormField.vue';
import FormWrapper from '@/components/forms/FormWrapper.vue';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const sent = ref(false);

const onSubmit = async (values) => {
  if (sent.value) {
    emit('close');
    return;
  }
  if (!values.email) {
    return;
  }
  await new UserAPI().resendEmailVerification(values.email);
  sent.value = true;
};

const submitLabel = computed(() => (sent.value ? 'Close' : 'Resend'));

const schema = computed(() => {
  if (sent.value) {
    return {};
  }
  return toTypedSchema(
    z.object({
      email: z.string().email({ message: 'Enter a valid email address' }),
    }),
  );
});
</script>

<template>
  <div class="no-key-message modal">
    <h2>Invalid or missing verification token</h2>
    <p v-if="!sent">Enter your email address below to resend the verification token</p>
    <p v-else>Verification email sent. Please check your inbox.</p>
    <div class="form">
      <FormWrapper :submit-label="submitLabel" @submit="onSubmit" :validation-schema="schema">
        <template #fields>
          <FormField v-if="!sent" name="email" label="Email" shape="square" />
        </template>
      </FormWrapper>
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-key-message {
  @include media-query(phone) {
    margin-right: 0;
    margin-left: 0;
  }

  h2 {
    margin-bottom: 1rem;
    padding-right: 2rem;
  }

  p {
    padding-right: 5rem;
    margin-bottom: 1rem;
    color: var(--Grey-2, #ada6b7);
    font-size: 16px;
  }

  .form {
    margin-bottom: 1rem;
  }

  .error-message {
    color: color(bad, secondary);
  }
}
</style>

<script lang="ts" setup>
import PathNotFound from '@/views/PathNotFound.vue';

const route = useRoute();

const notFound = computed(() => !!route.meta.notFound);

onMounted(() => {
  const app = document.getElementById('app');
  if (app) {
    app.classList.add('app-maxed');
  }
});
onUnmounted(() => {
  const app = document.getElementById('app');
  if (app) {
    app.classList.remove('app-maxed');
  }
});
</script>

<template>
  <PathNotFound v-if="notFound" />
  <RouterView v-else />
  <!-- <Feedback pmf /> -->
</template>

<style lang="scss">
#app.app-maxed,
#app.app-maxed > div {
  width: 100%;
  height: 100%;
}
</style>

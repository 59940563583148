<script setup lang="ts">
const props = defineProps({
  subheadingTextShade: {
    type: String,
    default: 'dark',
  },
});

const subheadingCSSClasses = computed(() => {
  return [`hero__subheading--${props.subheadingTextShade}`];
});
</script>

<template>
  <div :class="{ 'hero--has-background': $slots.background }" class="hero">
    <slot v-if="$slots.background" name="background" />

    <div class="hero__inner container">
      <div class="hero__left">
        <div v-if="$slots.top" class="hero__top">
          <slot name="top" />
        </div>

        <div v-if="$slots.heading || $slots.subheading" class="hero__middle">
          <h1 v-if="$slots.heading" class="hero__heading">
            <slot name="heading" />
          </h1>

          <div v-if="$slots.subheading" :class="subheadingCSSClasses" class="hero__subheading">
            <slot name="subheading" />
          </div>
        </div>

        <div v-if="$slots.bottom" class="hero__bottom">
          <slot name="bottom" />
        </div>
      </div>

      <div v-if="$slots.right" class="hero__right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hero {
  position: relative;
  display: flex;
  flex-direction: column;

  :deep(.hero-background) {
    + .hero__inner {
      padding-top: 0;

      @include media-breakpoint-up(md) {
        bottom: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__inner {
    @include outer-gutters;
    @include flex(row, space-between);

    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: space(15);
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      padding-top: space(30);
    }

    .hero__left {
      &:only-child {
        width: 100%;
      }
    }
  }

  &__top {
    margin-bottom: space(2.5);
  }

  &__middle {
    margin-bottom: space(4, fixed);

    @include media-breakpoint-up(md) {
      margin-bottom: space(7, fixed);
      max-width: space(100);
    }

    .hero__subheading {
      @include media-breakpoint-up(md) {
        @include make-col(10);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(8);
      }
    }
  }

  &__heading {
    @include text--h1;
    @include font-size(32);

    @include media-breakpoint-up(md) {
      @include font-size(42);
    }

    @include media-breakpoint-up(xl) {
      @include font-size(67);
    }

    margin-bottom: space(2.5);
    letter-spacing: -0.03rem;
  }

  &__subheading {
    @include text--subheading;
    @include font-size(16);

    @include media-breakpoint-up(md) {
      @include font-size(20);
    }

    @include media-breakpoint-up(xl) {
      @include font-size(24);
    }

    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.02rem;
    padding-top: space(1);

    &--dark {
      color: color(grey, secondary);
    }
  }

  .background {
    height: space(12, fixed);

    @include media-breakpoint-up(md) {
      height: space(42, fixed);
    }
  }
}
</style>

<script setup lang="ts">
const props = defineProps({
  spacing: {
    type: Number,
    default: 2,
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
});

const classNames = computed(() => {
  return [props.highlighted && 'grid-section__highlighted'];
});

const getSpacing = computed(() => {
  return `${props.spacing}rem`;
});
</script>

<template>
  <div :class="classNames" class="grid-section container">
    <div v-if="highlighted" class="grid__background" />

    <div class="grid row">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.grid-section {
  padding: 0;
  position: relative;

  &.grid-section__highlighted {
    padding: space(2);
  }

  .grid__background {
    @include borders-rounded;

    background-color: transparentize(color(negative), 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
  }

  :deep(.grid) {
    --cl-gutter-x: v-bind(getSpacing);
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

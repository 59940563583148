<script lang="ts" setup>
type Crumb = {
  name: string;
  routeTo?: string;
  color?: string;
};

const router = useRouter();
defineProps<{ crumbs: Crumb[] }>();

function getCSSClasses(idx: number, color?: string) {
  return [color && `collection-color--${color}`, idx === 0 && 'breadcrumb--root'];
}

function handleCrumbClick(routePath) {
  if (routePath) {
    router.push(routePath);
  }
}
</script>

<template>
  <div class="breadcrumbs">
    <span v-for="(crumb, idx) in crumbs" :key="idx" class="breadcrumbs_crumb">
      <router-link
        v-if="crumb.routeTo"
        :to="crumb.routeTo"
        :class="getCSSClasses(idx, crumb.color)"
        class="breadcrumb"
      >
        {{ crumb.name }}
      </router-link>
      <span v-else class="breadcrumb" :class="getCSSClasses(idx, crumb.color)">{{
        crumb.name
      }}</span>

      <span v-if="idx < crumbs.length - 1" class="breadcrumb-separator"> / </span>
    </span>
  </div>
</template>

<style scoped lang="scss">
.breadcrumbs {
  @include text--mono;

  color: color(grey, tertiary);

  a {
    &.breadcrumb {
      color: color(yellow);

      &.breadcrumb--root {
        color: color(action);
      }
    }
  }
}
</style>

<script setup lang="ts">
import { Hero } from '@clovyr/bed';
import heroGraphic from '@clovyr/bed/assets/images/clovyr-island-mascots.webp';
import gardenThicketBg from '@clovyr/bed/assets/images/clovyr-island-mascots-fg.svg';
import HeroBackground from '@clovyr/bed/components/elements/HeroBackground.vue';
import type { Bundle } from '@clovyr/pollen/types';
import { slugify } from '@clovyr/pollen/util/slugify';

import Tag from '@/components/elements/Tag.vue';
import AppBrowser from '@/components/tiers/AppBrowser.vue';
import { useRouteHead } from '@/composables/useRouteHead';
import { type ShowLoginEvent, useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';

const { login } = defineProps<{
  login?: ShowLoginEvent;
}>();

useRouteHead();

const { eventBus } = useEventBus();
const { garden } = usePollenStore();
const bundles = garden.getBundles().filter((b) => b.id !== 'fedimint');
const tags = garden.getTags();
const getCollectionApps = (bundle: Bundle) =>
  garden.getAppsByBundle(bundle.id).map((manifest) => manifest.metadata);

function showLoginForm(l: ShowLoginEvent | undefined) {
  if (!l) {
    eventBus.emit('modal:login:force_close');
    return;
  }
  void nextTick(() => {
    // show the modal only after loading, just a brief delay
    // if we don't the teleport component somehow breaks and cannot be cleanly removed from the page
    // but the problem only presents itself in the production build.
    eventBus.emit('modal:login:show', l);
  });
}

onMounted(async () => {
  showLoginForm(login);
});

watch(
  () => login,
  (newLogin) => {
    showLoginForm(newLogin);
  },
);
</script>

<template>
  <div class="garden">
    <Hero :subheadingTextShade="'light'">
      <template #heading>Welcome to<br />the Garden</template>
      <template #subheading>Fresh apps ready for the picking</template>
      <template #background>
        <HeroBackground
          :background-image="heroGraphic"
          :overlayImage="gardenThicketBg"
          :gradient="15"
        />
      </template>
    </Hero>

    <div class="garden-middle container">
      <template v-for="bundle in bundles" :key="bundle.id">
        <AppBrowser
          v-if="getCollectionApps(bundle).length > 0"
          :bundle="bundle"
          :app-data="getCollectionApps(bundle)"
        />
      </template>
    </div>

    <div class="garden-bottom container">
      <div class="garden-tags row">
        <h2 class="garden-tags__left grid__col-md-3">Explore By Tag</h2>
        <div class="garden-tags__right grid__col-md-9">
          <template v-for="tag in tags" :key="slugify(tag)">
            <Tag v-if="tag" :label="tag" :slug="slugify(tag)" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.garden {
  position: relative;
  padding-bottom: space(11);
  overflow: hidden;

  :deep(.hero) {
    .hero__inner {
      padding-top: space(15);

      @include media-breakpoint-up(sm) {
        padding-top: space(30);
      }

      @include media-breakpoint-up(md) {
        padding-left: var(--col-gutter-outer-left);
      }

      .hero__middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: none;
        width: auto;

        @include media-breakpoint-up(md) {
          align-items: flex-start;
        }

        .hero__heading {
          text-align: center;

          @include font-size(32);

          @include media-breakpoint-up(md) {
            text-align: left;
          }

          @include media-breakpoint-up(md) {
            @include font-size(42);
          }

          @include media-breakpoint-up(xl) {
            @include font-size(67);
          }
        }

        .hero__subheading {
          @include font-size(16);

          text-align: center;

          @include media-breakpoint-up(md) {
            text-align: left;
          }
        }
      }
    }

    .hero-background {
      position: absolute;

      @include media-breakpoint-up(md) {
        position: relative;
      }

      .background__overlay-image {
        background-position: center bottom;
        margin-top: 1px;
      }

      img {
        aspect-ratio: 2/1.5;
        object-position: top center;

        @include media-breakpoint-up(md) {
          aspect-ratio: 2/1;
          object-position: center center;
        }

        @include media-breakpoint-up(lg) {
          aspect-ratio: 2.5/1;
          object-position: center center;
        }
      }
    }

    .hero__left {
      @include media-breakpoint-up(md) {
        padding-left: 1rem;
      }
      @include media-breakpoint-only(md) {
        transform: translateY(5rem);
      }
    }
  }

  .garden-top {
    padding-top: space(35.5);
    margin-bottom: space(6);
  }

  .garden-bottom {
    justify-content: center;
  }

  .garden-tags {
    justify-content: flex-start;
    padding-left: calc(var(--col-gutter-outer-left) + 0.9rem);

    &__left {
      padding-left: 0;
      line-height: 1.2;
      color: color(grey, primary);
    }

    &__right {
      margin-top: 1rem;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }
}
</style>

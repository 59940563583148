/* eslint-disable */
/* Do not change, this code is generated from Golang structs */


export class UserSubscriptionItem {
    id: string;
    subscription_id: string;
    resource_id: string;
    status: string;
    trial_start_at?: Date;
    trial_end_at?: Date;
    subscription_start_at?: Date;
    created_at: Date;
    updated_at?: Date;
    cancel_at?: Date;
    canceled_at?: Date;
    price: string;
    price_currency: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.subscription_id = source["subscription_id"];
        this.resource_id = source["resource_id"];
        this.status = source["status"];
        this.trial_start_at = source["trial_start_at"] ? new Date(source["trial_start_at"]) : source["trial_start_at"];
        this.trial_end_at = source["trial_end_at"] ? new Date(source["trial_end_at"]) : source["trial_end_at"];
        this.subscription_start_at = source["subscription_start_at"] ? new Date(source["subscription_start_at"]) : source["subscription_start_at"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
        this.cancel_at = source["cancel_at"] ? new Date(source["cancel_at"]) : source["cancel_at"];
        this.canceled_at = source["canceled_at"] ? new Date(source["canceled_at"]) : source["canceled_at"];
        this.price = source["price"];
        this.price_currency = source["price_currency"];
    }
}
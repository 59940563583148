<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { ClovyrLogo } from '@clovyr/bed';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import HamburgerIcon from '@clovyr/bed/components/icons/HamburgerIcon.vue';
import XIcon from '@clovyr/bed/components/icons/XIcon.vue';

import GardenIcon from '@/components/icons/GardenIcon.vue';
import LibraryIcon from '@/components/icons/LibraryIcon.vue';
import PublisherIcon from '@/components/icons/PublisherIcon.vue';
import Help from '@/views/Help/Help.vue';
import Settings from '@/views/Settings/Settings.vue';

import { usePollenStore } from '../../stores/pollen_store';

const options = {
  Settings,
  Help,
};

defineProps({
  /**
   * Check if mobile navbar is open
   */
  mobileNavBarOpen: {
    type: Boolean,
    default: false,
  },
});

const { isPublisher } = storeToRefs(usePollenStore());
const router = useRouter();

const toggleBodyLockClass = () => {
  if (document.body.classList.contains('body-locked')) {
    document.body.classList.remove('body-locked');
  } else {
    document.body.classList.add('body-locked');
  }
};

const emit = defineEmits(['toggleMobileNavbar', 'toggleDrawer']);

const toggleMobileNavbar = () => {
  emit('toggleMobileNavbar');

  toggleBodyLockClass();
};

const handleRouteToGarden = async () => {
  toggleMobileNavbar();
  return router.push({ name: 'Garden' });
};

const handleRouteToLibrary = async () => {
  toggleMobileNavbar();
  return router.push({ name: 'Library' });
};
const handleRouteToPublisher = async () => {
  toggleMobileNavbar();
  return router.push({ name: 'Publishers' });
};

const handleDrawerToggle = (option) => {
  emit('toggleDrawer', option);
};
</script>

<template>
  <div v-if="mobileNavBarOpen" class="navbar-backdrop" @click="toggleMobileNavbar" />

  <div class="navbar navbar-mobile" :class="{ open: mobileNavBarOpen }">
    <div class="navbar-header">
      <div class="navbar-logo">
        <router-link to="/"><ClovyrLogo /></router-link>
      </div>

      <button class="button--action button-navbar" @click="toggleMobileNavbar">
        <HamburgerIcon v-if="!mobileNavBarOpen" />
        <XIcon v-if="mobileNavBarOpen" />
      </button>
    </div>

    <div v-if="mobileNavBarOpen" class="navbar-options">
      <ul class="navbar-menu navbar-menu-links">
        <li>
          <button class="button--action" @click="handleRouteToLibrary">
            <span class="button-icon">
              <LibraryIcon />
            </span>

            <span class="button-label">Library</span>
          </button>
        </li>

        <li>
          <button class="button--action" @click="handleRouteToGarden">
            <span class="button-icon">
              <GardenIcon />
            </span>

            <span class="button-label">Garden</span>
          </button>
        </li>
        <li>
          <button class="button--action publisher" @click="handleRouteToPublisher">
            <span class="button-icon">
              <PublisherIcon />
            </span>

            <span class="button-label">Publishers</span>
          </button>
        </li>
      </ul>

      <div class="navbar-menu-options">
        <ul class="navbar-menu">
          <li v-for="(_, option) in options" :key="option">
            <button class="button--action" @click="handleDrawerToggle(option)">{{ option }}</button>
          </li>
          <li><a class="button--action" href="https://clovyr.io">Clovyr.io</a></li>
          <li>
            <button class="button--action" id="feedback-button">Feedback</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.body-locked {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}
</style>

<style scoped lang="scss">
@import '@clovyr/bed/styles/components/_mobile_navbar';

.publisher:hover {
  .button-icon {
    :deep(.publisher-icon) {
      path {
        fill: color(action, secondary);
      }
    }
  }
}
</style>

<script setup lang="ts">
import { Field, useField } from 'vee-validate';
import type { Option } from './SelectInputBlock';

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  selectOptions: {
    type: Array<Option>,
    required: true,
  },
  subtext: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { name, label } = toRefs(props);

const { errorMessage } = useField(name.value);

const CSSClasses = computed(() => {
  return [errorMessage.value && 'selectinput-block--has-error'];
});
</script>

<template>
  <!--Use This as Dropdown-->
  <Field type="text" :name="name" v-slot="{ field }" :value="value" :model-value="value">
    <div class="selectinput-block" :class="CSSClasses">
      <div class="selectinput-block__label-holder" v-if="label">
        <label :for="name" class="selectinput-block__label">{{ label }}</label>
      </div>

      <select :id="name" class="selectinput-block__input" v-bind="field" :name="name" :disabled="disabled">
        <option v-for="(option, idx) in selectOptions" :key="idx" :value="option.value">
          {{ option.name }}
        </option>
      </select>

      <div v-if="!!subtext" class="selectinput-block__subtext">
        {{ subtext }}
      </div>

      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </div>
  </Field>
</template>

<style scoped lang="scss">
.selectinput-block__subtext {
  // TODO: this should be `@include .text--caption` after merging css cleanup branch
  @include font(lato, regular, 12, 1);
  padding-left: space(1.1, fixed);
  color: color(grey, primary);
  margin-top: space(0.7);
}
</style>

import CatalogJSON from '@clovyr/garden/dist/garden/catalog.json?raw';

import type { Deployment, Manifest, Setting, Settings } from '../manifest';
import type { Catalog } from '../types/Catalog';
import { retry } from '../util/retry';
import { snakeCaseToHuman } from '../util/string';

import { BaseGarden, type Garden } from '.';

function qualifyURL(baseURL: string): string {
  if (!baseURL.endsWith('/catalog')) {
    if (!baseURL.endsWith('/')) {
      baseURL += '/';
    }
    baseURL += 'catalog';
  }
  return baseURL;
}

function setSettingDefaults(settings: Settings) {
  Object.entries(settings).forEach(([key, setting]) => {
    setting.id ||= key;
    setting.name ||= snakeCaseToHuman(setting.id);
    if (setting.sub_settings) {
      setSettingDefaults(setting.sub_settings);
    }
  });
}

const CONFIG_SECTIONS = ['deployment', 'basic', 'advanced'];

/**
 * Post-processes a manifest to ensure it has all required fields
 *
 * @param app
 * @param catalogID
 */
export function postProcessManifest(app: Manifest, catalogID: string) {
  app.catalog_id ||= catalogID;
  app.metadata.title ||= app.metadata.name;
  // set setting names from key
  CONFIG_SECTIONS.forEach((configType) => {
    if (app.config?.[configType]) {
      setSettingDefaults(app.config[configType]);
    }
  });
}

/**
 * Create and process a catalog from a JSON string or object
 *
 * @param json
 * @param includeInternal
 * @returns
 */
export function parseCatalog(json: string | Catalog, includeInternal: boolean): Catalog {
  const catalog: Catalog = typeof json === 'string' ? (JSON.parse(json) as Catalog) : json;

  catalog.apps ||= [];
  catalog.bundles ||= [];
  catalog.tags ||= [];

  if (!includeInternal) {
    // hide internal apps
    catalog.apps = catalog.apps.filter((app) => !app.metadata.tags?.includes('internal'));
    if (catalog.bundles) {
      // hide internal collections
      catalog.bundles = catalog.bundles.filter(
        (collection) => !collection.tags.includes('internal')
      );
    }
  }

  // clean up catalog entries, if needed
  catalog.apps.forEach((app) => postProcessManifest(app, catalog.id));

  return catalog;
}

export class GardenImpl extends BaseGarden implements Garden {
  catalogs: { [id: string]: Catalog };

  baseURL: string;

  loading: { [location: string]: Promise<void> };

  constructor(baseURL: string, catalog: Catalog) {
    super(catalog.bundles, catalog.tags, catalog.apps);
    this.catalogs = {};
    this.catalogs[catalog.id] = catalog;
    this.baseURL = baseURL;
    this.loading = {};
  }

  async addIndex(location: string | Catalog): Promise<void> {
    const promise = this.tryAddIndex(location);
    const key = typeof location === 'string' ? location : location.id;
    this.loading[key] = promise;
    return promise.finally(() => {
      delete this.loading[key];
    });
  }

  async tryAddIndex(location: string | Catalog): Promise<void> {
    let newCatalog: Catalog;
    if (typeof location === 'string') {
      const url = `${qualifyURL(location)}`;
      const res = await retry(
        async () => {
          const r = await fetch(`${url}.json?${Date.now()}`);
          if (!r.ok) {
            throw new Error(`failed to load catalog: ${r.status} ${r.statusText}`);
          }
          return r;
        },
        5,
        true
      );
      if (!res.ok) {
        throw new Error(`failed to load catalog: ${res.status} ${res.statusText}`);
      }
      newCatalog = parseCatalog((await res.json()) as Catalog, true);
      newCatalog.url = url;
    } else {
      newCatalog = location;
    }
    this.catalogs[newCatalog.id] = newCatalog;

    if (newCatalog.apps) {
      this.apps = this.apps.concat(newCatalog.apps);
    }

    if (newCatalog.id === 'clovyr-platform') {
      // don't add tags & bundles
      return;
    }

    if (newCatalog.bundles) {
      this.bundles = this.bundles.concat(newCatalog.bundles);
    }

    if (newCatalog.tags) {
      // merge tags
      this.tags = this.tags.concat(newCatalog.tags);
      const tags: { [k: string]: number } = {};
      this.tags.forEach((tag) => {
        tags[tag] = 1;
      });
      this.tags = Object.keys(tags).sort();
    }
  }

  async isFullyLoaded(): Promise<void> {
    return Promise.allSettled(Object.values(this.loading)).then(() => undefined);
  }

  loadDeploymentDetails(app: Manifest): Promise<Manifest> {
    // load app details from web
    // https://cstatic.app/staging/garden/catalog/nextcloud.json
    return new Promise((resolve, reject) => {
      const baseURL = qualifyURL(this.catalogs[app.catalog_id].url || this.baseURL);
      fetch(`${baseURL}/${app.metadata.id}.json?${Date.now()}`)
        .then(async (res) => {
          if (!res.ok) {
            reject(res);
          }
          const remoteManifest: Manifest = await res.json();
          if (remoteManifest?.deployment?.contents) {
            app.deployment ||= {} as Deployment;
            app.deployment.contents = remoteManifest.deployment.contents;
          }
          resolve(app);
        })
        .catch((err) => {
          if (err.status) {
            reject(new Error(`failed to load deployment details: ${err.status} ${err.statusText}`));
          }
          reject(err);
        });
    });
  }

  async fetchAppVersion(app: Manifest, appVersion: string): Promise<Manifest> {
    const baseURL = qualifyURL(this.catalogs[app.catalog_id].url || this.baseURL);
    const appID = app.metadata.id;
    const appVersionURL = `${baseURL}/${appID}/${appVersion}/${appID}.json?${Date.now()}`;
    const res = await fetch(appVersionURL);
    if (!res.ok) {
      throw new Error(`failed to fetch app version: ${res.status} ${res.statusText}`);
    }
    const data = await res.json();
    if (data && (data.status === 'fail' || data.status === 'error')) {
      throw new Error(data.message);
    }
    return data as Manifest;
  }
}

type SettingTuple = [string, Setting];

/**
 * Crates a new Garden with the built-in stable catalog

 * @param gardenURL env-specific catalog url
 * @param includeInternal
 * @returns
 */
export function newGarden(gardenURL: string, includeInternal: boolean): Garden {
  const catalog = parseCatalog(CatalogJSON, includeInternal);
  catalog.url = gardenURL; // replace built-in catalog url with the env-specific one provided
  return new GardenImpl(gardenURL, catalog);
}

import { randomBytes, secretbox } from 'tweetnacl';
import * as v from 'valibot';

import { base64ToBytes, bytesToBase64 } from '../../util/base64';

import { LATEST_KEYCHAIN_VERSION, type VaultItem, type WebStorage } from './types';
import { xorKeys } from './util';

export interface StorageMigrator {
  migrateState(state: any): VaultItem[];
}

export function decryptMonolithicState(encryptedState: string, auk: Uint8Array): any {
  const { id, nonce, data } = JSON.parse(encryptedState);
  if (id !== 'secretbox') {
    throw new Error(`unsupported state encryption algorithm: ${id}`);
  }

  const bdata = base64ToBytes(data);
  const bnonce = base64ToBytes(nonce);
  const stateJSON = secretbox.open(bdata, bnonce, auk);
  if (!stateJSON) {
    // include some stats to help diagnose the error
    throw new Error(
      `error decrypting state (len: data=${data?.length}/${bdata?.length}b, nonce=${nonce?.length}/${bnonce?.length}b, auk=${auk?.length}b)`
    );
  }

  return JSON.parse(new TextDecoder().decode(stateJSON));
}

const migrations = [
  // v0 -> v1
  (storage: WebStorage) => {
    const keychainJSON = storage.getItem('clovyr/keychain');
    if (!keychainJSON) {
      return;
    }

    const prev = v.parse(KeychainV0Schema, JSON.parse(keychainJSON));

    const next: KeychainV1 = {
      version: 1,
      salt: prev.salt,
      secretKey: prev.secretKey,
    };
    if (prev.auk) {
      const auk = base64ToBytes(prev.auk);
      const secretKey = base64ToBytes(prev.secretKey);
      next.passwordKey = bytesToBase64(xorKeys(auk, secretKey));
    }

    storage.setItem('clovyr/keychain', JSON.stringify(next));
  },

  // v1 -> v2
  (storage: WebStorage) => {
    const keychainJSON = storage.getItem('clovyr/keychain');
    if (!keychainJSON) {
      return;
    }

    const encryptedState = storage.getItem('clovyr/state');
    const prev = v.parse(KeychainV1Schema, JSON.parse(keychainJSON));

    interface StateWithAuthenticatedUser {
      authenticatedUser?: {
        email: string;
      };
    }

    const next: KeychainV2 = {
      version: 2,
      salt: prev.salt,
      passwordKey: prev.passwordKey,
      secretKey: prev.secretKey,
      email: prev.email,
    };

    if (!prev.email && encryptedState && prev.secretKey && prev.passwordKey) {
      const auk = xorKeys(base64ToBytes(prev.secretKey), base64ToBytes(prev.passwordKey));
      const state: StateWithAuthenticatedUser = decryptOldState(encryptedState, auk);
      if (!state.authenticatedUser?.email) {
        throw new Error('migrating keychain failed: authenticatedUser.email not found in state');
      }

      next.email = state.authenticatedUser.email;
      delete state.authenticatedUser;
      storage.setItem('clovyr/state', encryptState(state, auk));
    }

    storage.setItem('clovyr/keychain', JSON.stringify(next));
  },

  // v2 -> v3
  (storage: WebStorage) => {
    const keychainJSON = storage.getItem('clovyr/keychain');
    if (!keychainJSON) {
      return;
    }
    const prev = v.parse(KeychainV2Schema, JSON.parse(keychainJSON));

    const next: KeychainV3 = {
      version: 3,
      authType: 'password',
      secretKey: prev.secretKey,
      passwordKey: prev.passwordKey,
      salt: prev.salt,
      email: prev.email,
    };

    storage.setItem('clovyr/keychain', JSON.stringify(next));
  },

  // v3 -> v4
  (storage: WebStorage) => {
    const keychainJSON = storage.getItem('clovyr/keychain');
    if (!keychainJSON) {
      return;
    }
    const prev = v.parse(KeychainV3Schema, JSON.parse(keychainJSON));

    // Log the user out if they happened to get the missing email bug.
    //
    // `keychain.email` was mistakenly added without bumping the keychain
    // version, so some sessions that were already migrated to V1 while that
    // mistaken commit was deployed will not have the email set.
    if (!prev.email) {
      storage.removeItem('clovyr/keychain');
      return;
    }

    let next: KeychainV4;
    if (prev.passwordKey && prev.secretKey) {
      // TODO: store salt and create statePasswordLocal
      next = {
        version: 4,
        state: 'passwordOpen',
        secretKey: prev.secretKey,
        passwordKey: prev.passwordKey,
        email: prev.email,
      };
    } else if (prev.passwordKey) {
      next = {
        version: 4,
        state: 'passwordNoSecretKey',
        email: prev.email,
        passwordKey: prev.passwordKey,
      };
    } else if (prev.secretKey) {
      next = {
        version: 4,
        state: 'passwordLocked',
        secretKey: prev.secretKey,
        email: prev.email,
      };
    } else {
      throw new Error('unreachable: v3 -> v4 migration failed');
    }

    storage.setItem('clovyr/keychain', JSON.stringify(next));
  },
];

export function migrateLocalStorage(storage: WebStorage) {
  const keychainJSON = storage.getItem('clovyr/keychain');
  if (!keychainJSON) {
    return;
  }

  const obj = JSON.parse(keychainJSON);
  const version = obj.version ?? 0;
  if (version > LATEST_KEYCHAIN_VERSION) {
    throw new Error(`unknown keychain version: ${version}`);
  }

  for (let i = version; i < LATEST_KEYCHAIN_VERSION; i++) {
    migrations[i](storage);
  }
}

if (migrations.length !== LATEST_KEYCHAIN_VERSION) {
  throw new Error(
    `wrong number of vault migrations: expected ${LATEST_KEYCHAIN_VERSION}, got ${migrations.length}`
  );
}

function encryptState(state: any, key: Uint8Array): string {
  const stateJSON = new TextEncoder().encode(JSON.stringify(state));
  const nonce = randomBytes(24);
  const data = secretbox(stateJSON, nonce, key);
  return JSON.stringify({
    id: 'secretbox',
    nonce: bytesToBase64(nonce),
    data: bytesToBase64(data),
  });
}

function decryptOldState(encryptedState: string, auk: Uint8Array): any {
  const { id, nonce, data } = JSON.parse(encryptedState);
  if (id !== 'secretbox') {
    throw new Error(`unsupported state encryption algorithm: ${id}`);
  }

  const stateJSON = secretbox.open(base64ToBytes(data), base64ToBytes(nonce), auk);
  if (!stateJSON) {
    throw new Error('error decrypting state');
  }

  return JSON.parse(new TextDecoder().decode(stateJSON));
}

const KeychainV0Schema = v.object({
  auk: v.optional(v.string()),
  salt: v.string(),
  secretKey: v.string(),
});

type KeychainV0 = v.InferOutput<typeof KeychainV0Schema>;

const KeychainV1Schema = v.object({
  version: v.literal(1),
  salt: v.string(),
  passwordKey: v.optional(v.string()),
  secretKey: v.optional(v.string()),
  email: v.optional(v.string()),
});

type KeychainV1 = v.InferOutput<typeof KeychainV1Schema>;

const KeychainV2Schema = v.object({
  version: v.literal(2),
  salt: v.string(),
  passwordKey: v.optional(v.string()),
  secretKey: v.optional(v.string()),
  email: v.optional(v.string()),
});

type KeychainV2 = v.InferOutput<typeof KeychainV2Schema>;

const KeychainV3Schema = v.object({
  version: v.literal(3),
  authType: v.literal('password'),
  salt: v.string(),
  passwordKey: v.optional(v.string()),
  secretKey: v.optional(v.string()),
  email: v.optional(v.string()),
});

type KeychainV3 = v.InferOutput<typeof KeychainV3Schema>;

const OpenPasswordKeychainV4Schema = v.object({
  state: v.literal('passwordOpen'),
  passwordKey: v.string(),
  secretKey: v.string(),
  email: v.string(),
});

type OpenPasswordKeychainV4 = v.InferOutput<typeof OpenPasswordKeychainV4Schema>;

const NoSecretKeyPasswordKeychainV4Schema = v.object({
  state: v.literal('passwordNoSecretKey'),
  passwordKey: v.string(),
  email: v.string(),
});

type NoSecretKeyPasswordKeychainV4 = v.InferOutput<typeof NoSecretKeyPasswordKeychainV4Schema>;

const NostrKeychainV4Schema = v.object({
  state: v.literal('nostr'),
  auk: v.string(),
  pubkey: v.string(),
  email: v.string(),
});

type NostrKeychainV4 = v.InferOutput<typeof NostrKeychainV4Schema>;

const LockedKeychainV4Schema = v.object({
  state: v.literal('passwordLocked'),
  secretKey: v.string(),
  email: v.string(),
});

type LockedKeychainV4 = v.InferOutput<typeof LockedKeychainV4Schema>;

const KeychainV4Schema = v.intersect([
  v.object({ version: v.literal(4) }),
  v.variant('state', [
    OpenPasswordKeychainV4Schema,
    NoSecretKeyPasswordKeychainV4Schema,
    LockedKeychainV4Schema,
    NostrKeychainV4Schema,
  ]),
]);

type KeychainV4 = v.InferOutput<typeof KeychainV4Schema>;

export type KeychainJSON = KeychainV4;
export const KeychainJSONSchema = KeychainV4Schema;

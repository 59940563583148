<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Carousel, Pagination, Slide } from 'vue3-carousel';

import { Button, ImageMedia } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import type { Metadata } from '@clovyr/pollen/manifest';
import type { Bundle } from '@clovyr/pollen/types';

import { useMediaQueryStore } from '@/stores/media_query_store';

import AppBlock from '../blocks/AppBlock.vue';
import ArrowForwardsIcon from '../icons/ArrowForwardsIcon.vue';

import 'vue3-carousel/dist/carousel.css';

const props = defineProps<{ bundle: Bundle; appData: Metadata[] }>();
const appDataWithHeader = [{ id: 'collection' } as Metadata].concat(props.appData);
const appCarousel = ref<typeof Carousel>();

const handleButtonClick = (direction) => {
  if (direction === 'next') {
    appCarousel?.value?.next();
  } else {
    appCarousel?.value?.prev();
  }
};

const collectionLink = computed(() => {
  return `/collection/${props.bundle.id}`;
});

const { isTablet, isDesktop } = storeToRefs(useMediaQueryStore());

const breakpoints = {
  600: {
    itemsToShow: Math.min(2, props.appData.length),
  },
  1200: {
    itemsToShow: Math.min(3.2, props.appData.length),
  },
};

const wrapAround = computed(() => {
  if (isTablet.value && props.appData.length <= 2) {
    return false;
  }
  if (isDesktop.value && props.appData.length <= 3) {
    return false;
  }
  return true;
});

const carouselSettings = computed(() => {
  return {
    // minimum breakpoint for mobile, start with 1 item, modify via breakpoints below
    itemsToShow: isDesktop.value ? Math.min(3.2, props.appData.length) : 1,
    snapAlign: 'start',
    wrapAround,
    mouseDrag: true,
    touchDrag: true,
  };
});

const classNames = computed(() => {
  return [props.bundle.backgroundImage === 'proddev-otters.svg' && 'app-browser__background-otter'];
});
const classNames2 = computed(() => {
  return [props.bundle.id === 'product_and_development' && 'hero-heading__product'];
});

// provide theme variable if available
const colorThemeVar =
  props.bundle.themeColor && `--collection-color-theme: ${props.bundle.themeColor}`;
</script>

<template>
  <div v-if="props.appData.length > 0" class="app-browser" :style="colorThemeVar">
    <div class="app-browser__background" :class="classNames">
      <ImageMedia :filename="bundle.backgroundImage" />
    </div>
    <div class="carousel-container" data-allow-mismatch>
      <div
        class="app-browser__hero-card app-browser__card grid__col-12 grid__col-lg-3"
        :class="classNames"
      >
        <div class="app-browser-hero">
          <div class="app-browser-hero__top">
            <h2 class="hero-heading" :class="classNames2">
              {{ bundle.title }}
            </h2>
            <div class="hero-subheading">
              {{ bundle.tagline }}
            </div>
          </div>
          <router-link v-if="!isTablet" :to="collectionLink">
            <div class="app-browser-hero__bottom">
              <div class="collection-link">
                <span class="collection-link__text">
                  all {{ bundle.name }}

                  <ArrowForwardsIcon />
                </span>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <Carousel
        v-if="props.appData.length > 1"
        :settings="carouselSettings"
        :breakpoints="breakpoints"
        ref="appCarousel"
        class="app-browser__content grid__col-12 grid__col-lg-9"
        data-allow-mismatch
      >
        <template #slides>
          <Slide v-for="appMetadata in props.appData" :key="appMetadata.id">
            <div class="app-browser__card">
              <AppBlock :appDatum="appMetadata" :bundle="bundle" />
            </div>
          </Slide>
        </template>

        <template #addons>
          <Pagination v-if="appDataWithHeader.length > 1 && isTablet" />

          <div v-if="appDataWithHeader.length > 3 && !isTablet" class="app-browser-buttons">
            <div :class="`app-browser-prev app-browser-prev--${bundle.id}`">
              <Button :shape="ButtonShape.Circle" @click="handleButtonClick('prev')">
                <ArrowForwardsIcon />
              </Button>
            </div>
            <div :class="`app-browser-next app-browser-next--${bundle.id}`">
              <Button :shape="ButtonShape.Circle" @click="handleButtonClick('next')">
                <ArrowForwardsIcon />
              </Button>
            </div>
          </div>
        </template>
      </Carousel>

      <div v-else class="app-browser__content app-browser-flex grid__col-12 grid__col-lg-9">
        <div class="app-browser__card" v-for="appMetadata in props.appData" :key="appMetadata.id">
          <AppBlock :appDatum="appMetadata" :bundle="bundle" />
        </div>
      </div>

      <div v-if="appDataWithHeader.length <= 4 && !isTablet" class="app-browser-spacing">
        &nbsp;
      </div>
      <div v-if="appDataWithHeader.length <= 4 && !isTablet" class="app-browser-spacing">
        &nbsp;
      </div>
    </div>

    <Button v-if="isTablet" class="button-view-all" :link="collectionLink">
      <span class="collection-link__text">all {{ bundle.name }}</span>
      <ArrowForwardsIcon />
    </Button>
  </div>
</template>

<style scoped lang="scss">
.app-browser {
  $self: &;

  // carousel styles

  --vc-pgn-active-color: #ada6b7;
  --vc-pgn-background-color: #453960;
  --vc-pgn-width: 6px;
  --vc-pgn-height: 6px;
  --vc-pgn-border-radius: 50%;

  position: relative;
  padding: space(6) 0;
  display: flex;
  flex-direction: column;

  .app-browser__content {
    position: relative;
    margin-top: 2rem;

    &.app-browser-flex {
      display: flex;
      column-gap: 1.25rem;
      padding-left: 0.5rem;

      .app-browser__card {
        @include media-breakpoint-up(xl) {
          @include make-col(4);

          max-width: 250px; // carousel card width
        }
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    .app-browser__card {
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
    }
  }

  &__apps {
    display: flex;
  }

  &__background {
    position: absolute;
    top: 0;
    left: space(13);
    z-index: -1;
  }

  &__background-otter {
    padding-top: space(5);
  }

  &__hero-card {
    @include transition(padding, slide);

    display: flex;
    padding-right: space(1);

    @include media-breakpoint-up(md) {
      margin-top: 3.5rem;
    }

    .app-browser-hero {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        height: space(25);
        margin-top: auto;
      }

      .hero-heading {
        @include make-col(12);
        @include font-size(26);

        @include media-breakpoint-up(md) {
          @include font-size(30);
        }

        @include media-breakpoint-up(xl) {
          @include font-size(36);
        }

        color: rgb(var(--collection-color-theme));
      }

      .hero-subheading {
        @include make-col(12);
        @include font-size(16);

        margin-top: space(1);
        color: color(grey, tertiary);
      }
    }

    .app-browser-hero__bottom {
      margin-top: 1rem;
    }
    .app-browser-hero__top {
      margin-left: 1.5rem;

      @include media-breakpoint-up(sm) {
        margin-left: 0;
      }
    }
  }

  &__top {
    margin-bottom: space(4);

    @include show-on(tablet-sm-and-below);
  }

  &__bottom {
    margin-top: space(2.5);

    @include show-on(tablet-sm-and-below);
  }

  :deep(.carousel) {
    .carousel__viewport {
      padding: space(2) space(0) space(0) space(0);
      margin-right: -2rem;

      @include media-breakpoint-up(sm) {
        margin-right: 0;
      }

      @include media-breakpoint-up(xl) {
        width: 1750px;
      }
    }

    .carousel__slide {
      padding: space(3) space(1) space(1);

      .app-browser__card {
        width: 100%;
      }
    }
  }

  .carousel-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      padding-left: var(--col-gutter-outer-left);
    }
  }

  .collection-link {
    @include text--mono;

    text-align: left;
    cursor: pointer;
    color: rgb(var(--collection-color-theme));

    &__text {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
    }

    svg {
      stroke: currentColor;
      margin-left: space(1);
    }
  }

  :deep(.button) {
    background-color: rgba(var(--collection-color-theme), 0.1);
    color: rgb(var(--collection-color-theme));
    margin-top: space(2.5);
    margin-right: auto;
    margin-left: auto;
    stroke: rgb(var(--collection-color-theme));

    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }

    &:hover {
      background-color: rgba(var(--collection-color-theme), 0.3);
    }

    @include media-breakpoint-up(md) {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    svg {
      @include size(rem(16), rem(16));
    }

    &.button-view-all {
      svg {
        margin-left: space(1);
      }
    }
  }

  .app-browser-buttons {
    display: flex;
    flex: 0 0 100%;
    justify-content: flex-end;
    max-width: 100%;
    padding-right: space(1.8);
    margin: 0.75rem 0 0;

    :deep(.button) {
      background-color: rgba(var(--collection-color-theme), 0.1);
      color: rgb(var(--collection-color-theme));
      stroke: rgb(var(--collection-color-theme));

      &:hover {
        background-color: rgba(var(--collection-color-theme), 0.3);
      }
    }
  }

  .app-browser-next,
  .app-browser-prev {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  .app-browser-spacing {
    height: space(8);
    width: 100%;
  }

  .app-browser-next {
    .button {
      background-color: color(background, primary);
    }
  }

  .app-browser-prev {
    margin: 0 20px 0 0;

    .button {
      background-color: color(background, primary);
      transform: rotate(180deg);
    }
  }

  .swiper-container {
    overflow-x: visible;
    overflow-y: visible;
  }
}
</style>

/* eslint-disable */
/* Do not change, this code is generated from Golang structs */


export class PublisherAppFile {
    id: string;
    publisher_app_id: string;
    name: string;
    content: string;
    permissions: string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.publisher_app_id = source["publisher_app_id"];
        this.name = source["name"];
        this.content = source["content"];
        this.permissions = source["permissions"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
        this.deleted_at = source["deleted_at"] ? new Date(source["deleted_at"]) : source["deleted_at"];
    }
}
export class PublisherAppVersion {
    id: string;
    publisher_app_id: string;
    version: string;
    image_versions?: string;
    release_notes?: string;
    rank: string;
    status: string;
    description?: string;
    logo_url?: string;
    base_image?: string;
    example_repo?: string;
    build_script?: string;
    init_script?: string;
    manifest?: string;
    created_at: Date;
    updated_at: Date;
    files: PublisherAppFile[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.publisher_app_id = source["publisher_app_id"];
        this.version = source["version"];
        this.image_versions = source["image_versions"];
        this.release_notes = source["release_notes"];
        this.rank = source["rank"];
        this.status = source["status"];
        this.description = source["description"];
        this.logo_url = source["logo_url"];
        this.base_image = source["base_image"];
        this.example_repo = source["example_repo"];
        this.build_script = source["build_script"];
        this.init_script = source["init_script"];
        this.manifest = source["manifest"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
        this.files = this.convertValues(source["files"], PublisherAppFile);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class PublisherApp {
    id: string;
    publisher_id: string;
    name: string;
    slug: string;
    app_type: 'clovyr_code' | 'custom';
    approved: boolean;
    approved_by?: string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
    version: PublisherAppVersion;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.publisher_id = source["publisher_id"];
        this.name = source["name"];
        this.slug = source["slug"];
        this.app_type = source["app_type"];
        this.approved = source["approved"];
        this.approved_by = source["approved_by"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
        this.deleted_at = source["deleted_at"] ? new Date(source["deleted_at"]) : source["deleted_at"];
        this.version = this.convertValues(source["version"], PublisherAppVersion);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
<script setup lang="ts">
import { Button } from '@clovyr/bed';

const props = defineProps({
  infoText: {
    type: String,
    default: '',
  },
  buttons: {
    type: Array,
    default: () => [],
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
  alignBottom: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: 'row',
  },
});

const CSSClasses = computed(() => {
  return [
    props.highlighted && 'setting-option--highlighted',
    props.alignBottom && 'setting-option--align-bottom',
    props.direction && `setting-option--${props.direction}`,
  ];
});
</script>

<template>
  <div :class="CSSClasses" class="setting-option">
    <div v-if="highlighted" class="setting-option__background background-gradient--3" />

    <div class="setting-option__inner row">
      <div :class="buttons?.length && 'setting-option__left grid__col-md-6'" class="grid__col">
        <slot name="left">
          <div v-if="infoText" class="setting-info">
            {{ infoText }}
          </div>
        </slot>
      </div>

      <div v-if="buttons?.length" class="setting-option__right grid__col-md-6">
        <template v-if="buttons">
          <div class="setting-option__buttons">
            <Button
              v-for="(button, idx) in buttons as (typeof Button)[]"
              :key="idx"
              :label="button.label"
              :reversed="button.reversed"
              :shape="button.shape"
              @click="$emit(button.eventName)"
              :disabled="button.disabled"
              class="option-button"
            >
              <component v-if="button.icon" :is="button.icon"></component>
            </Button>
          </div>

          <div class="setting-option__buttons-content" v-if="$slots['button-content']">
            <slot name="button-content" />
          </div>
        </template>

        <template v-else>
          <slot name="right" />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$padding: space(3.6);

.setting-option {
  $self: #{&};

  position: relative;
  margin-bottom: space(1.5);
  color: color(white);

  &--highlighted {
    padding-right: $padding;
    padding-left: $padding;

    &:first-child {
      margin-top: space(2.5);
    }
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0.5;

    @include borders-rounded;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: space(2.5) 0;

    @at-root #{$self}--highlighted #{&} {
      padding: $padding 0;
    }

    @at-root #{$self}--align-bottom #{&} {
      align-items: flex-end;
    }

    &.row {
      [class*='grid__col'] {
        &:not(:first-child) {
          margin-top: space(3);

          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;

    .option-button:not(.button--circle) {
      flex-grow: 1;
    }
  }

  &__buttons-content {
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
  }
}
</style>

<script setup lang="ts">
import type { Component } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';

import LoginForm from './AuthForms/LoginForm.vue';
import NostrEmailForm from './AuthForms/NostrEmailForm.vue';
import ResendVerification from './AuthForms/ResendVerification.vue';
import SignupForm from './AuthForms/SignupForm.vue';
import Unlock from './AuthForms/Unlock.vue';
import UnlockSecret from './AuthForms/UnlockSecret.vue';
import VerificationComplete from './AuthForms/VerificationComplete.vue';
import RecoveryKitDownload from './RecoveryKitDownload.vue';
import { ShowAuthForm } from './types';

const AuthForms: Record<ShowAuthForm, Component> = {
  [ShowAuthForm.Login]: LoginForm,
  [ShowAuthForm.Signup]: SignupForm,
  [ShowAuthForm.NostrEmail]: NostrEmailForm,
  [ShowAuthForm.RecoveryKitDownload]: RecoveryKitDownload,
  [ShowAuthForm.SecretKeyForm]: UnlockSecret,
  [ShowAuthForm.Locked]: Unlock,
  [ShowAuthForm.ResendVerification]: ResendVerification,
  [ShowAuthForm.VerificationComplete]: VerificationComplete,

  // not currently used anywhere
  // showResetPassword: ResetPasswordForm,
  // showLockedAccount: LockedAccountForm,
  // showLoginNewDevice: LoginNewDevice,
};

const props = withDefaults(
  defineProps<{
    activeOption?: ShowAuthForm | undefined;
    // allows for passing additional props to the auth form
    opts?: unknown | undefined;
  }>(),
  {
    activeOption: ShowAuthForm.Login,
  },
);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateTheme', theme?: string): void;
}>();

const activeOption = toRef(props, 'activeOption');
const changeForm: Ref<ShowAuthForm | undefined> = ref();
const showForm = computed(() => changeForm.value || activeOption.value);
const formOpts = computed(() => props.opts || {});

const handleThemeUpdate = (theme) => {
  emit('updateTheme', theme || 'narrow');
};

const handleFormChange = (form: ShowAuthForm) => {
  changeForm.value = form;
};

const handleClose = () => {
  emit('close');
};

const validationSchema = toTypedSchema(
  z.object({
    email: z.string().email({ message: 'Enter a valid email address.' }),
    password: z.string().min(8, { message: 'Password must be at least 8 characters long.' }),
  }),
);

provide('validationSchema', validationSchema);

const cssClasses = computed(() => {
  return [
    showForm.value === ShowAuthForm.Locked && 'show-locked',
    showForm.value === ShowAuthForm.SecretKeyForm && 'show-secret',
  ];
});
</script>

<template>
  <component
    @auth-form="handleFormChange"
    @close="handleClose"
    @update-theme="handleThemeUpdate"
    :is="AuthForms[showForm]"
    v-bind="formOpts"
    :class="cssClasses"
  />
</template>
<style scoped lang="scss">
.show-locked,
.show-secret {
  margin: auto;
}
.no-key-message {
  background: unset;
}
</style>
